import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';

import StoreFilterOptions from '../FilteStore/StoreFilterOptions.jsx';
import IconButton from '../../../common/Button/IconButton.jsx';
import Checkbox from '../../../common/Checkbox/Checkbox.jsx';
import CheckList from '../../CheckList/CheckList.jsx';
import MapBuilderHelper from '../../../common/MapBuilderHelper/mapBuilderHelper.js';
import { getIDBFilterRows } from '../../../indexedDb.js';

import { API_URL, getNdJsonFetch } from '../../../utility/fetchUtility.js';
import { getSubmitValues } from '../../../utility/getResult.js';
import { AuthContext } from '../../../AuthenticationContext.js';
import LoadingCircle from '../../Loading/LoadingCircle.jsx';
import ToggleSwitch from '../../ToggleSwitch/ToggleSwitch.jsx';
import SelectBox from '../../SelectBox/SelectBox.jsx';
import HeatMap from '../../HeatMap/HeatMap.jsx';
import Slider from '../../Slider/Slider.jsx';
import FilterInput from '../../FilterInput/FilterInput.jsx';
const useStyles = makeStyles({
  root: {
    position: 'relative',
    color: 'rgba(52, 73, 94,1.0)',
    flex: 1,
    overflowY: 'auto',
    '& .MuiTypography-root': {
      color: 'rgba(52, 73, 94,1.0)',
    },
    '& $listTitle': {
      fontWeight: 'bold',
      marginLeft: 24,
      color: 'rgba(52, 73, 94, .75)',
      transition: 'color 0.2s linear',
    },
    '&:hover $listTitle': {
      color: '#3f51b5 !important',
    },
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      //transform: 'scale(1) translate(80%, -50%)',
      border: 'solid 2px #f1f3f4',
    },
  },
  stickyTitle: {
    backgroundColor: '#f1f3f4',
    position: 'sticky',
    zIndex: 2,
    top: 0,
    paddingLeft: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignIems: 'center',
    gap: 12,
    gridGap: 12,
    alignItems: 'center',
    minHeight: 48,
  },
  listTitle: {
    fontWeight: 'bold',
    marginLeft: 12,
    color: 'rgba(52, 73, 94, .75) !important',
  },
  searchFrom: {
    border: 'solid 1px rgba(149, 165, 166,0)',
    flex: 1,
    display: 'flex',
    padding: '4px 12px',
    borderRadius: 4,
    transition: 'background .2s linear, border .2s linear',
    '& $input': {
      flex: 1,
      color: '#34495e',
    },
    '&:focus-within, &:hover': {
      backgroundColor: 'rgba(255,255,255,.75)',
      border: 'solid 1px rgba(149, 165, 166,1.0)',
    },
  },
  input: {
    transition: 'width .2s linear',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  fixBottom: {
    position: 'sticky',
    bottom: 0,
    zIndex: 2,
  },
  stickySliderBox: {
    position: 'sticky',
    backgroundColor: '#f1f3f4',
    top: 48,
    zIndex: 2,
  },
  radiusSlider: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    'span': {
      fontSize: '14px',
      color: 'rgba(52, 73, 94, .75)',
      }
  },
});

export default function FilterMenu(props) {
  const {
    //json : menus =[],
    //title=null,
    type = null,
    isFilterItem = false,
    filterId = 'unknown',
    updateFilterSelected,
    masterDataKey,
    listScrollRef,
    currentFormValues,
    filterMenuScrollTop,
    checkSubmitDisabled,
    isSalesAnalysisSummaryTarget,
    isAssortmentSummaryTarget,
    isMarketAreaSummaryTarget,
    isMarketAreaShareTarget,
    isMarketAreaPeopleFlowTarget,
    isMarketAreaHeatMap,
    filterOptions,
    setSelectedStores,
    selectedStores,
    major,
    minor,
    showRangeSlider,
    changeConditionUpdate,
    mapCells,
    setMapCells,
  } = props;
  const classes = useStyles();
  const [menus, setMenus] = React.useState([]);
  //const setMenus = () => {};
  const [isAllChecked, setIsAllChecked] = React.useState(false);
  const [isIndeterminate, setIsIndeterminate] = React.useState(false);
  const [isGroup, setIsGroup] = React.useState(false);

  const [searchText, setSearchText] = React.useState(
    currentFormValues.current.searchText
  );
  const [filterSearchText, setFilterSearchText] = React.useState(
    currentFormValues.current.filterSearchText?.[masterDataKey] || null
  );

  const [title, setTitle] = React.useState(null);
  const [storeSelectMap, setStoreSelectMap] = React.useState(null);
  const [isDifferentOccupation, setIsDifferentOccupation] = React.useState(
    currentFormValues?.current?.isDifferentOccupation || false
  );

  const inputRef = React.useRef(null);
  const listTitleRef = React.useRef(null);
  const checkedRef = React.useRef('');
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const [storeMeshIds, setStoreMeshIds] = React.useState(
    currentFormValues.current.mapMeshIds
  );
  
  const isCompetingStores = masterDataKey === 'competingStores';
  const titleDictionary = {
    store: '対象店舗',
    segment: 'セグメント',
    categories: 'カテゴリー',
    competingStores: '競合店',
    target: '対象データ',
    aggregationTarget: '対象データ',
  };
  const isGroupCheckEnabled = ![].includes(masterDataKey);
  const isComparisonStoreType = masterDataKey === 'competingStores' &&
    `${major}/${minor}` === 'marketArea/share';
  const isStoreAreaMap =  ['marketArea/profile', 'marketArea/peopleFlow'].includes(
      `${major}/${minor}`
    ) && masterDataKey === 'store';
  const isRadioButton = ['marketArea/heatMap'].includes(`${major}/${minor}`) && masterDataKey === "target";

  const { token } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const masterDb2JSONTimer = React.useRef(null);

  const createGroupMenu = (object) => {
    return Object.keys(object).map((key) => {
      const menu = object[key];
      if (menu.child) {
        return {
          groupName: key,
          visible: true,
          checked: false,
          groupPath: menu?.groupPath,
          groupKey: menu.key,
          child: createGroupMenu(menu.child),
          value: menu.value,
        };
      }
      return {
        label: key,
        value: menu.value,
        visible: true,
        checked: false,
        groupPath: menu?.groupPath,
      };
    });
  };
  
  const changeCheckMenus = (value, uniqueKey) => {
    currentFormValues.current[masterDataKey] = [];
    const json = menus.map((o) => {
      return {
        ...o,
        checked:  value === o.label ?  !o.checked : isRadioButton  ? false :  o.checked,
      };
    });

    currentFormValues.current[masterDataKey] = [...json];

    checkSubmitDisabled();
    const checkedLists = json.filter((d) => d.checked);
    setMenus([...json]);
    if (masterDataKey === 'store') {
      setSelectedStores(json.filter((d) => d.checked));
    }
    if (masterDataKey === 'competingStores') {
      const checkedItems = json
        .filter((data) => data.checked)
        .map((data) => {
          const { competitor_store_chain_id, competitor_store_id } = data.value;
          return `${competitor_store_chain_id}:${competitor_store_id}`;
        });
      console.log({chainCount: Object.keys(checkedItems).length })
      window.localStorage.setItem(
        'competingStoresCheckIds',
        JSON.stringify(checkedItems)
      );
    }
    changeConditionUpdate();
  
  };
  const checkAllItems = (group, checked) => {
    return group.map((item) => {
      const { child = null, visible=false } = item;

      return {
        ...item,
        checked: visible ? checked : item?.checked ,
        child: child ? checkAllItems(child, checked) : null,
      };
    });
  };
  const saveSelectedCompetingStoresToLocal = (json) =>{
    const checkedItems = json.reduce((o, n) => { return [...o, ...n?.child] }, [])
    .filter((data) => data.checked)
    .map((data) => {
      const { competitor_store_chain_id, competitor_store_id } = data;
      return `${competitor_store_chain_id}:${competitor_store_id}`;
    });
  window.localStorage.setItem(
    'competingStoresCheckIds',
    JSON.stringify(checkedItems)
  );
  }
  const toggleAllChecked = (event) => {
    const checked = event.target.checked;
    const json = checkAllItems(menus, checked);
    currentFormValues.current[masterDataKey] = [...json];
    setMenus(json);
    checkSubmitDisabled();
    if (isCompetingStores) saveSelectedCompetingStoresToLocal(json);
    if (masterDataKey === 'store') {
      setSelectedStores(json.filter((d) => d.checked));
    }
  };
  const changeSelectMenus = (selectMenus, selected) => {
    if (!selectMenus) return;
    const checkValues = selectMenus.map((s) => {
      const vals = s.split(' (');
      return vals[0];
    });

    const json = menus.map((o) => {
      const isMatch = checkValues.includes(o.value);
      const checked = isMatch || o.checked;

      return {
        ...o,
        checked: checked,
      };
    });
    //updateMasterData(json);
    currentFormValues.current[masterDataKey] = [...json];
    checkSubmitDisabled();
    setMenus(json);
  };
  const changeGroupExpand = (groupNames, groupKey, menuJson = menus) => {
    const checkGroupName = groupNames.shift();
    return menuJson.map((group) => {
      const { value, open = false, child = null } = group;

      const isMatch = value === checkGroupName;
      const openFlag = isMatch && !groupNames.length ? !open : open;

      return {
        ...group,
        child:
          isMatch && groupNames.length
            ? changeGroupExpand(groupNames, groupKey, child)
            : child,
        open: openFlag,
      };
    });
  };
  const changeExpand = (_groupName, groupKey) => {
    const json = changeGroupExpand(_groupName, groupKey, menus);
    currentFormValues.current[masterDataKey] = [...json];
    checkSubmitDisabled();
    setMenus(json);
  };
  const toggleGroupSelectMenus = (
    groupNames,
    triggerGroupKey,
    isSingle,
    prevChecked = undefined,
    _menus = menus
  ) => {

      if(isCompetingStores){
        // // tempCompetingStores
        // let _menus = currentFormValues.current['tempCompetingStores'].map(c=> {return {...c,open:false}});
        if(isSingle){
          let chainWithStores = _menus.find(chain =>  chain?.value == groupNames[0])
          let stores = chainWithStores?.child
          let selectedStores = stores.find(store => store?.value == triggerGroupKey)
          selectedStores.checked = !selectedStores.checked 
          let storesLength = stores.length;
          let selectedStoresLenght  = stores.filter(store => store?.checked).length
          chainWithStores.checked = storesLength == selectedStoresLenght;
        }else{
          let chainWithStores = _menus.find(chain =>  chain?.value == triggerGroupKey);
          let groupSelected = !chainWithStores?.checked;
          chainWithStores.child = chainWithStores?.child.map(child => {return {...child, checked:groupSelected}})
          chainWithStores.checked = groupSelected;
        }
        return _menus

      }
    const _groupName = groupNames?.shift() || null;
    return _menus.map((g) => {
      const { checked, child = null, value } = g;
      const isTriggerGroup =
        value === triggerGroupKey ||
        (!isSingle && triggerGroupKey === _groupName);
      let newChecked =
        prevChecked !== undefined && !isTriggerGroup
          ? prevChecked
          : _groupName === value && isTriggerGroup
          ? !checked
          : checked;
      if (isSingle) {
        newChecked = isTriggerGroup ? !checked : checked;
      }
      const newGroup = {
        ...g,
        checked: newChecked,
      };
      if (child) {
        newGroup.child = toggleGroupSelectMenus(
          [...groupNames],
          triggerGroupKey,
          isSingle,
          newChecked,
          child
        );
      }
      return newGroup;
    });
  };

  const changeGroupSelectMenus = (
    groupNames,
    triggerGroupKey,
    isSingle = false
  ) => {
    const json = toggleGroupSelectMenus(groupNames, triggerGroupKey, isSingle);
    if (isCompetingStores)  saveSelectedCompetingStoresToLocal(json);
    
    currentFormValues.current[masterDataKey] = [...json];

    setMenus([...json]);
    checkSubmitDisabled();
  };
  const filterText = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const searchText = inputRef?.current?.value || undefined;
    const json = searchText
      ? menus.map((o) => {
          const { label, sub = '' } = o;
          return {
            ...o,
            visible: Boolean(`${label}${sub}`.search(searchText) > -1),
          };
        })
      : menus.map((o) => {
          return {
            ...o,
            visible: true,
          };
        });
    setSearchText(searchText);
    //updateMasterData(json);
    currentFormValues.current.searchText = searchText;
    currentFormValues.current[masterDataKey] = [...json];
    checkSubmitDisabled();
    setMenus(json);
  };

  const getCheckedArray = () => {
    const list = getCheckedLists();
    return {
      checkedLists: list.filter((l) => l),
      lists: list,
    };
  };
  const getCheckedLists = () => {
    const listArray = getListsArray();
    return listArray.flat();
  };
  const getListsArray = (nodes = menus) => {
    if (nodes.length === 0) return [];
    return nodes
      .filter((node) => node.visible)
      .map((node) => {
        const { child, checked } = node;
        if (child) {
          return getListsArray(child).flat();
        }
        return child ? child.map((c) => c.checked) : checked;
      })
      .flat();
  };
  const getCheckedObject = (nodes = menus) => {
    if (nodes.length === 0) return [];
    return nodes.map((node) => {
      const { child, checked } = node;
      if (child) {
        return getCheckedObject(child);
      }
      return checked;
    });
  };
  const getFlatList = (lists) => {
    return lists
      .filter((list) => list.visible)
      .map((list) => {
        return list.child ? getFlatList(list.child) : list;
      })
      .flat();
  };
  const getBadgeCount = () => {
    //const { checkedLists } = getCheckedArray();

    const checkedLists = getFlatList(menus).filter((menu) => menu.checked);
  

    //const badgeCount = checkedLists.length || 0;
    const badgeCount = checkedLists.length || 0;
    return {
      badgeCount,
      hasError: title === '競合店' && badgeCount > 10 ? true : false,
    };
  };
  const toggleListTitle = (isDisplay) => () => {
    if (listTitleRef.current) {
      listTitleRef.current.style.display = isDisplay ? 'block' : 'none';
    }
  };

 
  //  get all the competting stores  for the selected target stores from the server, we are dynamicaly fetchig the competing stores.
  const getCompetingStores = async () => {
    if (selectedStores.length === 0) return [];
    const storeById = {};
    selectedStores.forEach((d) => {
      const {
        chain_id,
        // Will be unified to `store_id` in the near future
        pasha_store_id: store_id,
      } = d.value;
      if (storeById[chain_id] === undefined) {
        storeById[chain_id] = [];
      }
      storeById[chain_id].push(Number(store_id));
    });
    const dataBody = {
      store_info: Object.keys(storeById).map((key) => {
        return {
          chain_id: Number(key),
          store_id: storeById[key],
        };
      }),
    };
    const competingStores = await getNdJsonFetch(
      token,
      history,
      `https://${API_URL}/competitor-list`,
      dataBody
    );
    return competingStores;
  };

  // filter all the competing store with in the selected range.
  const filterCompetitorStore = (json = currentFormValues.current['tempCompetingStores']) => {
    const checkedItems = JSON.parse(window.localStorage.getItem('competingStoresCheckIds') || '[]')
    if (isDifferentOccupation) return;
    const distanceKm = currentFormValues.current.range * 1000;
    
    const filteredChains = json?.reduce((o,n,i)=>{
      let old = [...o];
      let stores = n?.child || [];
      let filteredStores = stores.filter(store=>  Boolean(store.competitor_store_distance <= distanceKm));
      if(filteredStores.length > 0){
          let childStores = filteredStores.map(c=> {return {...c,checked:c?.checked ? c?.checked : checkedItems.includes(`${c?.competitor_store_chain_id}:${c?.competitor_store_id}`)}} )
          return [...old,{ ...n,checked:childStores?.filter(c=>c.checked).length == filteredStores.length ,child:childStores } ]
      }
      return [...old];
    },[])
  
    setMenus(filteredChains);
    checkSubmitDisabled();
  };

  // fethces the filter's data from indexDB, if filterKey is competingStores  then  call getCompetingStores() to fetch the competing stores for the target stores.
  const getItems = async () => {
    if (isCompetingStores) {
      if (isDifferentOccupation) {
        const items = await getIDBFilterRows('differentOccupation');
        return items;
      }
      return await getCompetingStores();
    }
    return await getIDBFilterRows(masterDataKey);
  };
  // 1
  const masterDB2JSON = async () => {
    const hasMasterDB = Boolean(window.localStorage.getItem('hasMasterDB'));
    let items = null;
    // 1 . if filter's data is in indexDB as masterDB, then get the data.
    if (hasMasterDB) {
      items = await getItems();
    }
    // 2. if no data found wait for some to load the data
    if (items === null) {
      clearTimeout(masterDb2JSONTimer.current);
      masterDb2JSONTimer.current = setTimeout(() => {
        masterDB2JSON();
      }, 750);
      return;
    }
    // 3. convert the raw data of filter's  to the required format for the UI,
    const convertItems = convertMasterData({
      [masterDataKey]: items,
    });
  
    // 4. update the currentFormValues(declared in Top.jsx file) with the selected masterDataKey/filter-name, on updating currentFormValue on of the useEffect function will be triggred
    currentFormValues.current[masterDataKey] = [
      ...convertItems[masterDataKey].json,
    ];

    // 5. on loading each filter run the checkSubmitDisabled() to make sure the "get results" button is enbaled if all the filters are selected.
    // checkSubmitDisabled();

    // 6. set the title of the current filter 
    setTitle(titleDictionary[masterDataKey]);

    if(isCompetingStores){
      currentFormValues.current.tempCompetingStores = convertItems[masterDataKey].json;
    }

    // 7. set the menus, which updates the component based on the menus data.
    setMenus(convertItems[masterDataKey].json);
    // 8. disable loading indicator by setting the "false" to isDataLoading, the allows to display the filter's data on the screen
    setIsDataLoading(false);
  };
  // 2. convert the raw data of filter's  to the required format for the UI,
  const convertMasterData = (params) => {
    const masterData = {};
    Object.keys(params).map((key) => {
      const rows = params[key];

      if (key === 'store') {
        masterData[key] = {
          json: rows.map((row) => {
            return {
              label: row.store_full_name,
              subLabel: row.chain_name,
              value: { ...row },
              key: 'store_full_name',
              checked: false,
              visible: true,
            };
          }),
          title: '対象店舗',
          searchInput: {
            placeholder: '店舗名検索',
          },
          isStoreFilter: true,
          filterId: 'store_name',
        };
      }
      if (key === 'segment') {
        masterData[key] = {
          json: rows.map((row) => {
            return {
              label: row.segment_name,
              value: row.segment_id,
              key: 'segment_name',
              checked: false,
              visible: true,
            };
          }),
          title: 'セグメント',
          filterId: 'segments',
        };
      }
      if (key === 'categories') {
        const categories = {};
        rows.map((row) => {
          const {
            category_lv1_cd,
            category_lv1_name,
            category_lv2_cd,
            category_lv2_name,
            category_lv3_cd,
            category_lv3_name,
            category_lv4_cd,
            category_lv4_name,
            group_id,
          } = row;
          if (categories[category_lv1_name] === undefined) {
            categories[category_lv1_name] = {
              value: `0${category_lv1_cd}`,
              key: 'category_lv1_cd',
              groupPath: [`0${category_lv1_cd}`],
              child: {},
            };
          }
          if (
            categories[category_lv1_name].child[category_lv2_name] === undefined
          ) {
            categories[category_lv1_name].child[category_lv2_name] = {
              value: `00${category_lv2_cd}`,
              key: 'category_lv2_cd',
              groupPath: [`0${category_lv1_cd}`, `00${category_lv2_cd}`],
              child: {},
            };
          }
          if (
            categories[category_lv1_name].child[category_lv2_name].child[
              category_lv3_name
            ] === undefined
          ) {
            categories[category_lv1_name].child[category_lv2_name].child[
              category_lv3_name
            ] = {
              value: `000${category_lv3_cd}`,
              key: 'category_lv3_cd',
              groupPath: [
                `0${category_lv1_cd}`,
                `00${category_lv2_cd}`,
                `000${category_lv3_cd}`,
              ],
              child: {},
            };
          }
          if (
            categories[category_lv1_name].child[category_lv2_name].child[
              category_lv3_name
            ].child[category_lv4_name] === undefined
          ) {
            categories[category_lv1_name].child[category_lv2_name].child[
              category_lv3_name
            ].child[category_lv4_name] = {
              value: `0000${category_lv4_cd}`,
              key: 'category_lv4_cd',
              groupPath: [
                `0${category_lv1_cd}`,
                `00${category_lv2_cd}`,
                `000${category_lv3_cd}`,
                `0000${category_lv4_cd}`,
              ],
            };
          }
        });

        const categoriesJson = createGroupMenu(categories);
        console.log({categoriesJson})
        masterData[key] = {
          json: categoriesJson,
          type: 'group',
          title: 'カテゴリー',
          filterId: 'categories',
        };
      }
      if (key === 'competingStores') {
        const checkedItems = JSON.parse(window.localStorage.getItem('competingStoresCheckIds') || '[]');

        const competingStores = rows.reduce((o, n, i) => {
          let old = [...o]
          if (isDifferentOccupation) {
            const { label, value } = n;
            const checked = currentFormValues?.current?.competingStores?.find(
              (o) => o.value === value
            )?.checked || false;

            old.push({
              label,
              subLabel: null,
              value,
              key: 'store_different_occupation',
              checked: checked,
              visible: true,
            })
            return old;
          }
          let storeChain = old.find(ele => ele?.value == n?.competitor_store_chain_id && ele?.label == n?.competitor_store_company_name);
          const checkKey = `${n?.competitor_store_chain_id}:${n?.competitor_store_id}`;
          const checked = checkedItems.includes(checkKey);
          if (storeChain) {
            storeChain.child.push(
              {
                "label": n?.competitor_store_name,
                "value": n?.competitor_store_id,
                "visible": true,
                "checked": checked ? checked : (n?.checked == undefined ? false : n?.checked),
                "groupPath": [
                  `${storeChain.value}`,
                  `${n?.competitor_store_id}`,
                ],
                "child": null,
                ...n,
              }
            )
            return old
          }
          old.push({
            "child": [
              {
                "label": n?.competitor_store_name,
                "value": n?.competitor_store_id,
                "visible": true,
                "checked": checked ? checked : (n?.checked == undefined ? false : n?.checked),
                "groupPath": [
                  `${n?.competitor_store_chain_id}`,
                  `${n?.competitor_store_id}`,
                ],
                "child": null,
                ...n
              },
            ],
            "groupName": n?.competitor_store_company_name,
            "visible": true,
            "checked": false,
            "label": n?.competitor_store_company_name,
            "groupPath": [`${n?.competitor_store_chain_id}`],
            "groupKey": "competitor_chain",
            "value": `${n?.competitor_store_chain_id}`,

          })
          return old
        }, [])
        let finalCompetingStores =  competingStores.map(chain => {
          let child = chain?.child || [];
          let selectedStoresLength = child.filter(c => c.checked).length;
          return { ...chain, checked: child.length == selectedStoresLength }
        })
        masterData[key] = {
          json:finalCompetingStores,
          title: '競合店',
          searchInput: {
            placeholder: '店舗名検索',
          },
          isStoreFilter: true,
          type: 'group',
          filterId: 'competingStoresName',
        };
      }
      if (key === 'target') {
        masterData[key] = {
          json: rows.map((row) => {
            const { label, value } = row;
            return {
              label,
              value,
              checked: false,
              visible: true,
              key: key,
            };
          }),
          title: '対象データ',
          filterId: 'metrics',
        };
      }
      if (key === 'aggregationTarget') {
        const aggregationTarget = [];
        let tempTargets = [
          {
        
            checked: false,
            visible: true,
            key: key,
              "label": "既存顧客",
              "value": "existing_customer"
          },
          {
              groupName:  "潜在顧客",
                value: "潜在顧客",
                checked: false,
                visible: true,
              "child": [
                  {
                    checked: false,
                    visible: true,
                    key: key,
                      "label": "商圏住民",
                      "value": "potential_customer_resident",            
                  },
                  {
                    checked: false,
                    visible: true,
                    key: key,
                      "label": "通行者",
                      "value": "potential_customer_passerby"
                  }
              ]
          }
      ]
      // const createGroupMenu = (object) => {
      //   return Object.keys(object).map((key) => {
      //     const menu = object[key];
      //     if (menu.child) {
      //       return {
      //         groupName: key,
      //         visible: true,
      //         checked: false,
      //         groupPath: menu?.groupPath,
      //         groupKey: menu.key,
      //         child: createGroupMenu(menu.child),
      //         value: menu.value,
      //       };
      //     }
      //     return {
      //       label: key,
      //       value: menu.value,
      //       visible: true,
      //       checked: false,
      //       groupPath: menu?.groupPath,
      //     };
      //   });
      // };
      
      // rows.forEach((row) => {
      //     const { groupLabel = null, label, value } = row;
      //     const params = {
      //       label,
      //       value,
      //       checked: false,
      //       visible: true,
      //       key: key,
      //     };
      //     let addPoint = aggregationTarget;
      //     if (groupLabel) {
      //       addPoint =
      //         aggregationTarget.find((a) => a.groupName === groupLabel)
      //           ?.child || null;
      //       if (!addPoint) {
      //         aggregationTarget.push({
      //           groupName: groupLabel,
      //           value: groupLabel,
      //           checked: false,
      //           visible: true,
      //           child: [],
      //         });
      //         addPoint = aggregationTarget.find(
      //           (a) => a.groupName === groupLabel
      //         ).child;
      //       }
      //     }
      //     addPoint.push(params);
      //   });
        masterData[key] = {
          json: tempTargets,
          title: '対象データ',
          filterId: 'aggregationTarget',
        };
      }
      //masterData[key] = params[key];
    });
    return masterData;
  };

  // this is loading animation, it will be used when loading the data
  const BlankLists = () => {
    const blankLines = new Array(10).fill(null);
    return (
      <div
        style={{
          flex: 1,
          paddingLeft: 24,
          paddingRight: 24,
        }}
      >
        {blankLines.map((line, i) => (
          <div
            key={`loading-skelton-box-${title}-${i}`}
            style={{
              display: 'flex',
              margin: '12px 0',
              opacity: 1 - (i / 10 || 0),
            }}
          >
            <FilterLoadingSkelton
              variant='rect'
              height={18}
              width={18}
              style={{ marginRight: 24 }}
            />
            <FilterLoadingSkelton
              variant='rect'
              height={18}
              style={{ flex: 1 }}
            />
          </div>
        ))}
      </div>
    );
  };

  const BlankCompetingStores = () => {
    return (
      <div
        style={{
          height: 'calc(100vh - 332px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#ecf0f1',
          borderRadius: '2px 2px 0 0',
        }}
      >
        <Typography style={{ color: '#95a5a6' }}>
          対象店舗が存在しません
        </Typography>
      </div>
    );
  };
  const FilterLoadingSkelton = (props) => {
    return <Skeleton {...props} />;
  };

  const getFilterProperty = () => {
    if (masterDataKey === 'store') {
      return {
        searchInput: {
          placeholder: '店舗名検索',
        },
        // Disabled once because there is no prefecture information in the filter parameters.
        //isStoreFilter : true,
      };
    }
    return {
      searchInput: null,
      isStoreFilter: false,
    };
  };
 
  //  on distice slider chages, the function will be called to set the distance in the currentFormValues(in Top.jsx)
  // then filterCompetitorStore() function will be called to filter the competing stores in the selected range.
  const changeRangeUpdate = (value) => {
    currentFormValues.current.range = value;
    filterCompetitorStore();
  };

  //  default targets list to be displayed on the screen will be returned based on the screen
  const targetDefaultVisible = (value) => {
    const showSalesAnalysisSummaryTargetValues = [
      'sales',
      'sales_composition',
      'customer_unit_price',
      'total_customers',
      'total_no_of_customers',
      'freq_of_purchases',
    ];
    const showAssortmentSummaryTargetValues = [
      'customer_unit_price',
      'total_no_of_product_per_customer',
    ];
    const showMarketAreaSummaryTargetValues = [
      "market_area_population",
      "store_visit_rate",
      "customer_coverage"
    ];
    const showMarketAreaShareTargetValues = [
      'total_customers',
      'total_no_of_customers',
      'sales',
    ];
    const showMarketAreaPeopleFlowTargetValues = [
      'people_flow',
      'own_store_user',
      'competitor_store_user',
    ];
    const showMarketAreaHeatMapTargetValues = [
      "customer_coverage",
      "store_visit_rate"
    ]
    if (isAssortmentSummaryTarget) {
      return showAssortmentSummaryTargetValues.includes(value);
    }
    if (isSalesAnalysisSummaryTarget) {
      return showSalesAnalysisSummaryTargetValues.includes(value);
    }
    if (isMarketAreaSummaryTarget) {
      return showMarketAreaSummaryTargetValues.includes(value);
    }
    if (isMarketAreaShareTarget) {
      return showMarketAreaShareTargetValues.includes(value);
    }

    if (isMarketAreaPeopleFlowTarget) {
      return showMarketAreaPeopleFlowTargetValues.includes(value);
    }
 
    if(isMarketAreaHeatMap){
      return showMarketAreaHeatMapTargetValues.includes(value);
    }
  
    return 'total_no_of_product_per_customer' !== value;
  };

  // this function will be called if user has selected a target-store from the  list , 
  // and then function will update the mesh_ids in currentFormValues with newly selected mesh_ids, also updates the menus and map as well
  const changeTarget = (props) => {
    const { pasha_store_id, mesh_cd } = props;
    const json = menus.map((menu) => {
      const selected = menu.value.pasha_store_id === pasha_store_id;
      return {
        ...menu,
        selected: selected,
      }
    });
    currentFormValues.current[masterDataKey] = [...json];
    currentFormValues.current.mapMeshIds = [];
    setMenus(json);
    const selectedCells = MapBuilderHelper.getKRingIndices(
      mesh_cd,
      currentFormValues.current.meshRadius
    );
    setStoreMeshIds(selectedCells);
  };

  //  this function is used to update the mesh_ids for selected range of the own_stores
  const changeRadius = (radius, menuStores) => {
    const mesh_cd = menuStores.find((menu) => menu.selected).value.mesh_cd;
    const selectedCells = MapBuilderHelper.getKRingIndices(mesh_cd, radius);
    setStoreMeshIds(selectedCells);
    currentFormValues.current.meshRadius = radius ;
  };

  const getHeatMapArea = async () => {
    const selected = menus.find((menu) => menu.selected);
    const {
      latitude = null,
      longitude = null,
      mesh_cd = null,
    } = selected?.value || {};
    const json =
      selected && latitude && longitude
        ? {
            latitude: Number(latitude),
            longitude: Number(longitude),
            zoom: 14,
          }
        : null;
    if (mapCells && Object.keys(mapCells).length) {
      setStoreMeshIds(Object.keys(mapCells));
    } else if (mesh_cd && !storeMeshIds.length) {
      const selectedCells = MapBuilderHelper.getKRingIndices(mesh_cd, currentFormValues.current.meshRadius);
      setStoreMeshIds(selectedCells);
    }
    setSelectedStores(json);
    setStoreSelectMap(json);
    return;
    // Call the API when it is working
    const dataBody = {};
    await getNdJsonFetch(
      token,
      history,
      `https://${API_URL}/customer-per-area`,
      dataBody
    );
  };

  //  this function will be used to filter stores based on search text
  const getFilteredMenus = (menuItems) => {
    let filteredMenus = [...menuItems];
    let isLimit = false;
    if (filterSearchText) {
      const searchRegExp = new RegExp(filterSearchText);
      filteredMenus = filteredMenus.reduce((o,n,i)=>{
        let old = [...o];
        let chainLabel = n?.label || "";
        let searchTextInChainLabel = chainLabel ? chainLabel.search(searchRegExp) > -1 : false;
        if(!n?.child) {
          return searchTextInChainLabel ? [...old,n] : [...old];
        };
        
        if(searchTextInChainLabel){
          return [...old,n]
        }
        if(n?.child){
          let filteredChild = n.child.filter((c) => {
              const { label = '' } = c;
              return label ? label.search(searchRegExp) > -1 : false;
            });
            if(filteredChild.length > 0){
              return [...old,{...n, child:filteredChild}]
            }
            return [...old];
        }
        return [...old];
      },[])
    }
    let filteredMenusLength =  filteredMenus.reduce((o,n)=> {return o+ (n?.child?.length || 1)},0)  ;
    if (title == '競合店'){
      if (filteredMenusLength > 100 || filterSearchText) {
        isLimit = true;
      }
    }
    return {
      filteredMenus,
      isLimit,
      filteredMenusLength
    };
  };

// 1 
// 1st entry point of filterMenu
//  on loading the filterMenu this is the first function to run 
//  this function will call the masterDB2JSON() to fetch the filter's data from indexDB
  React.useEffect(() => {
    if (!currentFormValues.current[masterDataKey]) {
      (async () => {
       masterDB2JSON();
      })();
    }
    return () => {
      masterDb2JSONTimer.current && clearTimeout(masterDb2JSONTimer.current);
    };
  }, []);

  React.useEffect(() => {
    return ()=>{checkSubmitDisabled()} 
  },[storeMeshIds])
  // 2
  // if changes of the filter's data has been detected then  this funciton will be trigrred to mark isCheckedAll = true/false and also to mark isIndeterminate = true/false  
  // this update of the isCheckedAll and isIndeterminate will select-all checkbox of filter to either selected or unselcted or indeterminate in the UI.
  React.useEffect(() => {
    const { checkedLists, lists } = getCheckedArray();
    const isChecked = Boolean(checkedLists.length);
    const _isAllChecked = checkedLists.length === lists.length;
    setIsIndeterminate(Boolean(isChecked && !_isAllChecked));
    setIsAllChecked(_isAllChecked);
    isStoreAreaMap &&  getHeatMapArea();
  }, [menus]);

  // 3
  // this function will be triggered, if currentFormValues for the given  masterDataKey(filter-name) have been changed.
  React.useEffect(() => {
    if (currentFormValues.current[masterDataKey]) {
      setTitle(titleDictionary[masterDataKey]);
      const originalMenus = [...currentFormValues.current[masterDataKey]];
      const json = masterDataKey === 'target' ? originalMenus.map((menu) => { 
        return { ...menu,  visible: targetDefaultVisible(menu.value), };
        }) : originalMenus;
      if (masterDataKey === 'target') {
        const currentTargetMenuText = JSON.stringify(
          currentFormValues.current[masterDataKey]
        );
        const newTargetMenuText = JSON.stringify(json);
        if (currentTargetMenuText !== newTargetMenuText) {
          currentFormValues.current[masterDataKey] = json;
        }
      }

      if (isCompetingStores) {
        filterCompetitorStore(json);
        return;
      }
      setMenus(json);
    }
    if (location.pathname.indexOf('/marketArea/share') === -1) {
      currentFormValues.current.isDifferentOccupation = false;
      setIsDifferentOccupation(false);
    }
   
  }, [currentFormValues.current[masterDataKey], masterDataKey]);

  // 4
  // if selection of target stores is chagned then update the competing stores.
  React.useEffect(() => {
    if (isCompetingStores) {
      setIsDataLoading(true);
      // Dynamically retrieve store data only for competing stores
      masterDB2JSON();
    }
  }, [selectedStores, isDifferentOccupation]);
 
// 5
// scroll observer
// console.log({isCompetingStores , showRangeSlider})
  React.useEffect(() => {
    // Scroll position restoration
    const coverBoxScrollTop =
      filterMenuScrollTop?.current?.[masterDataKey]?.scrollTop || 0;
    if (coverBoxScrollTop) {
      document.querySelector(`#filter-list-box-${title}`).scrollTop =
        coverBoxScrollTop;
    }
  });

  const { searchInput, isStoreFilter } = getFilterProperty();

  const badgeInfo = getBadgeCount();
  const hasMenu = Boolean(menus.length);
  const { filteredMenus = [], isLimit = false, filteredMenusLength=0 } = getFilteredMenus(menus);
  return isStoreAreaMap ? (
    <div
      style={{
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <SelectBox
          // Exclude stores without mesh_cd flag
          options={menus.filter((menu) => menu.value.mesh_cd)}
          onChange={changeTarget}
          rootStyle={{
            flex: 1,
          }}
          enableFilter={true}
        />
        {storeSelectMap && (
          <div className={classes.radiusSlider}>
            <span>対象範囲:</span>
            <Slider
              max={10} // 100
              step={0.5} //5
              marks
              style={{
                width: '95%',
              }}
              unit='km'
              formPropertyName='meshRadius'
              currentFormValues={currentFormValues}
              onChange={(radius) => changeRadius(radius*10, menus)}
            />
          </div>
        )}
      </div>

      <div style={{ flex: 10, backgroundColor: 'rgba(0,0,0,0.05)' }}>
        {storeSelectMap ? (
          <HeatMap
            selectedMeshIds={storeMeshIds}
            setSelectedMeshIds={setStoreMeshIds}
            storePosition={[storeSelectMap]}
            currentFormValues={currentFormValues}
            filterOptions={filterOptions}
            mapCells={mapCells}
            enableCellClick
          />
        ) : (
          <div
            style={{
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.05)',
              borderRadius: 4,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography style={{ opacity: 0.5 }}>
              店舗を選択してください
            </Typography>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <div className={classes.stickyTitle}>
        <div className={classes.title} ref={listTitleRef}>
          {title ? (
            <>
              {(isGroupCheckEnabled && !isRadioButton) && (
                <Checkbox
                  id={title}
                  name={title}
                  value={title}
                  edge='start'
                  //checked={checked}
                  tabIndex={1}
                  disableRipple
                  checked={isAllChecked}
                  //inputProps={{ 'aria-labelledby': id }}
                  color='primary'
                  onChange={toggleAllChecked}
                  indeterminate={isIndeterminate}
                  className={classes.checkbox}
                />
              )}

              <Badge
                badgeContent={badgeInfo.badgeCount}
                overlap='rectangular'
                color='primary'
                style={{ border: 'solid 2px #f0f3f4' }}
              >
                <Typography
                  className={classes.listTitle}
                  style={{
                    marginLeft: isGroupCheckEnabled ? null : 0,
                  }}
                >
                  {title}
                </Typography>
              </Badge>
            </>
          ) : (
            <FilterLoadingSkelton
              variant='rect'
              height={18}
              style={{ marginBottom: 24 }}
            />
          )}
        </div>
        {searchInput && (
          <form className={classes.searchFrom} onSubmit={filterText}>
            <InputBase
              className={classes.input}
              placeholder={searchInput?.placeholder}
              inputProps={{ 'aria-label': searchInput?.placeholder }}
              inputRef={inputRef}
              defaultValue={searchText}
              type='search'
              //onInput={filterText}
              onFocus={toggleListTitle(false)}
              onBlur={toggleListTitle(true)}
            />
            <IconButton
              size='small'
              type='submit'
              className={classes.iconButton}
              aria-label='search'
            >
              <SearchIcon />
            </IconButton>
          </form>
        )}

        {isStoreFilter && <StoreFilterOptions onChange={changeSelectMenus} />}
        {isComparisonStoreType && (
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: 12,
            }}
          >
            <ToggleSwitch
              labels={['異業種', '同業種']}
              checked={!isDifferentOccupation}
              onChange={(checked) => {
                const newChecked = !checked;
                currentFormValues.current.isDifferentOccupation = newChecked;
                setIsDifferentOccupation(newChecked);
              }}
            />
          </div>
        )}
      </div>
      {isCompetingStores && showRangeSlider && (
        <div className={classes.stickySliderBox}>
          <Slider
            currentFormValues={currentFormValues}
            label='対象範囲'
            title='対象店舗の所在地からの距離'
            unit='km'
            onChange={changeRangeUpdate}
          />
        </div>
      )}
      {isLimit && (
        <FilterInput
          placeholder='店舗名検索'
          masterDataKey={masterDataKey}
          total={menus.reduce((o,n)=> {return o+ (n?.child?.length || 1)},0) }
          length={filteredMenusLength}
          addTopPosition={isCompetingStores && showRangeSlider ? 38 : 0}
          onChange={setFilterSearchText}
          currentFormValues={currentFormValues}
        />
      )}
      {isDataLoading ? (
        <LoadingCircle
          style={{
            height: 'calc(100vh - 332px)',
          }}
        />
      ) : hasMenu ? (
        <CheckList
          menus={filteredMenus}
          //menus={json}
          filterId={masterDataKey}
          onChange={changeCheckMenus}
          changeGroupSelectMenus={changeGroupSelectMenus}
          onExpand={changeExpand}
          isGroup={isGroup}
          isGroupCheckEnabled={isGroupCheckEnabled}
          isRadio={isRadioButton}
        />
      ) : isCompetingStores ? (
        <BlankCompetingStores />
      ) : (
        <BlankLists />
      )}
    </div>
  );
}
