import moment from 'moment';
import * as dfd from "danfojs";
import { 
    API_URL, 
    validateResponse, 
    // getKeyInCookie,
    getNdJsonFetch,
    API_ENV
} from './fetchUtility.js';

import MapBuilderHelper from '../common/MapBuilderHelper/mapBuilderHelper.js';
import { NewReleasesTwoTone, PortableWifiOffSharp } from '@material-ui/icons';
import { max } from 'lodash';
const profilePeriod2Filter = {
    'monthly' : [{
        'day_of_week' : 'all',
    }],
    'monthly_split' : [{
        'day_of_week' : 'weekday',
    },{
        'day_of_week' : 'weekend',
    }],
    'weekly' : [{
        'day_of_week' : 'all',
    }],
    'weekly_split' : [{
        'day_of_week' : 'weekday',
    },{
        'day_of_week' : 'weekend',
    }],
    'daily' : [{
        'day_of_week' : 'all',
    }
    // ,{
    //     'day_of_week' : 'weekend',
    // }
    ],
    'hourly' : [{
        'day_of_week' : 'all',
    }],
}
const period2resultPerPeriod = {
    'monthly' : 'monthly',
    'monthly_split' : 'monthly',
    'weekly' : 'weekly',
    'weekly_split' : 'weekly',
    'daily' : 'daily',
    'hourly' : 'hourly',
    'weekday' : 'weekday',
}
const isUseRangePath   = [
    'assortment/competitor',
    'assortment/singleItem',
    'assortment/basket',
    'pricing/summary',
    'pricing/competitor',
    'pricing/singleItem',
    'pricing/boxPlot',
    'marketArea/share',
    'marketArea/heatMap'
]
const PRIMARY_STORE_NODATA_TEXT = '対象ストアのデータがありません。検索条件を変えて実行してください。'
const NOT_A_NUMBER = '-';

const getLastYearDates = (startDate, endDate, periodType)=>{

    if(periodType === "weekly"  || periodType === "weekly_split" ){
        let sWeek = moment(startDate).isoWeek();
        let eWeek = moment(endDate).isoWeek();
        
        let lastYearStartDateWithWeek = moment().isoWeekYear(moment(startDate).add(-1, 'years').format('YYYY')).isoWeek(sWeek).startOf("isoWeek").format('YYYY-MM-DD');
        let lastYearEndDateWithWeek   = moment().isoWeekYear(moment(endDate).add(-1, 'years').format('YYYY')).isoWeek(eWeek).endOf("isoWeek").format('YYYY-MM-DD');
        return{
            startDate : lastYearStartDateWithWeek,
            endDate   : lastYearEndDateWithWeek
        }
    }
    return{
        startDate : moment(startDate).add(-1, 'year').format('YYYY-MM-DD'),
        endDate   : moment(endDate).add(-1, 'year').format('YYYY-MM-DD')
    }

}

const selectedCategoryLength  = (categories,categoryLevel) =>{
    const customCats =  getFlatCategoriesListForBoxPlot(categories);
    const cl = {
        "level1": [],
        "level2": [],
        "level3": [],
        "level4": []} 
    customCats.forEach((c)=>{
        if(c.checked === true){
            (c.groupKey === "category_lv1_cd" && [2,3,4].includes(categoryLevel)) && (cl["level1"].push(parseInt(c.value)));
            (c.groupKey === "category_lv2_cd" && [2,3,4].includes(categoryLevel)) && (cl["level2"].push(parseInt(c.value)));
            (c.groupKey === "category_lv3_cd" && [3,4].includes(categoryLevel)) && (cl["level3"].push(parseInt(c.value)));
            (c.groupKey === "category_lv4_cd" && [4].includes(categoryLevel)) && (cl["level4"].push(parseInt(c.value)));
        }
    })
    if(cl["level2"].length > 0) cl["level1"] = [];
    if(cl["level3"].length > 0) cl["level2"] = [];
    if(cl["level4"].length > 0) cl["level3"] = [];

    return {lenghtOfCategories: Object.values(cl).flat().length, data:cl, customCats:customCats}
}
function getNextYearDate(periodType, date){
    if(periodType === "weekly"  || periodType === "weekly_split" ){
        let sWeek = moment(date).isoWeek();
        let nextYearStartDateWithWeek = moment().isoWeekYear(moment(date).add(1, 'years').format('YYYY')).isoWeek(sWeek).startOf("isoWeek").format('YYYY-MM-DD');
        return nextYearStartDateWithWeek
    }
        return moment(date).add(1, 'year').format('YYYY-MM-DD')
}
// getXXX series has room for optimization.
// It is currently written in a verbose manner as it is unclear what API calls exist
// getSalesResults, 
const getSalesResults = async (props) => {
    try {
        const {
            token, 
            history, 
            currentFormValues,
            filterOptions,
            selectedTargets,
        } = props;
        const {
            datePeriodType = [],
            segment        = [],
            start_date     = moment().add(-1, 'month'),
            end_date       = moment(),
        } = currentFormValues?.current || {};
        const periodType   = datePeriodType.find(option => option.selected).value;
        const dateFormat   = ['monthly', 'monthly_split'].includes(periodType) ? 'YYYY-MM' : 'YYYY-MM-DD';
        const segmentNames = segment || [];
        const startDate    = moment(start_date).format('YYYY-MM-DD');
        const endDate      = moment(end_date).format('YYYY-MM-DD');
        const lastYearDates = getLastYearDates(startDate, endDate,periodType)
        const dataBody     = getSubmitValues({
            filterOptions, 
            currentFormValues,
        });
        const salesParams  = [{
            // 売上
            metric     : ['sum'],
            unit       : ['price'],
            start_date : startDate,
            end_date   : endDate,
        },{
            // 昨年度売上 -1 year
            metric     : ['sum'],
            unit       : ['price'],
            start_date : lastYearDates.startDate,
            end_date   : lastYearDates.endDate,
        },{
            // 顧客数
            metric     : ['count'],
            unit       : ['customer'],
            start_date : startDate,
            end_date   : endDate,
        },{
            // 顧客数 - 1 year
            metric     : ['count'],
            unit       : ['customer'],
            start_date : lastYearDates.startDate,
            end_date   : lastYearDates.endDate,
        },{
            // 顧客数（のべ）
            metric     : ['count'],
            unit       : ['receipt'],
            start_date : startDate,
            end_date   : endDate,
        },{
            // 顧客数（のべ） - 1 year
            metric     : ['count'],
            unit       : ['receipt'],
            start_date : lastYearDates.startDate,
            end_date   : lastYearDates.endDate,
        },
        {
            //  records count
            api : "purchases",
            body : {
                start_date : startDate,
                end_date   : endDate,
                store_info : [...dataBody.store_info],
                source     : "pos",
                metric     : ["count"],
                unit       : ["receipt"],
            },
            name:"recordCountBody"
        }
    ]
    
        delete dataBody.mesh_id
        delete dataBody?.categories
        delete dataBody?.filter_category_code_list;
        const requests = salesParams.map(params => {
            return getNdJsonFetch(token, history, `https://${API_URL}/purchases`, params?.name == "recordCountBody" ? {...params?.body} : {...dataBody, ...params}, 0);
        });
        const results = await Promise.all(requests)
            .then(res => res);

        const salesPrice = results[0];
        const lastYearSalesPrice = results[1];
        const numberOfCustomers = results[2];
        const lastYearNumberOfCustomers = results[3];
        const totalNumberOfCustomers = results[4];
        const lastYearTotalNumberOfCustomers = results[5];
        const recordsCount = [...(results?.[6] || [])]?.reduce((o,rc)=>{return o + (parseInt(rc?.number_of_total_customers) ?? 0)},0) ?? 0;
        // Setting next year dates for each of the last year result
    
        lastYearSalesPrice.map(lysp => {
            lysp.next_year_date = getNextYearDate(periodType,lysp.agg_target_day)
        })
    
        lastYearNumberOfCustomers.map(lynoc => {
            lynoc.next_year_date = getNextYearDate(periodType,lynoc.agg_target_day)
        })
        
        lastYearTotalNumberOfCustomers.map(lytnoc => {
            lytnoc.next_year_date = getNextYearDate(periodType,lytnoc.agg_target_day)
        })
      
        const salesYoY  = salesPrice.map(sp => {
            const newCells = {...sp};
            const {
                total_sales,
                customer_segment_id,
                agg_target_day,
                week_split
        } = newCells;
            const lastYearTotalSales = lastYearSalesPrice
                .find(lysp => lysp.customer_segment_id === customer_segment_id && lysp.next_year_date === agg_target_day && lysp.week_split === week_split)
                ?.total_sales || 0;
            newCells.sales_yoy_ratio =((total_sales/lastYearTotalSales)-1)*100;
            return newCells;
        });
        const segmentPerRange = {};
        salesPrice.forEach(p => {
            const {
                agg_target_day,
                customer_segment_id,
                total_sales,
                week_split
        } = p;
            let key = agg_target_day
        if(week_split != undefined){
            key = key + week_split
        }
        if(segmentPerRange[key] === undefined) segmentPerRange[key] = 0;
            segmentPerRange[key] += total_sales;
        });
        //sales_composition_ratio
        const salesCompositionRatio = salesPrice.map(sp => {
            const newCells = {...sp};
            const {
                total_sales,
                customer_segment_id,
                agg_target_day,
                week_split
        } = newCells;
            let key = agg_target_day
        if(week_split != undefined){
            key = key + week_split
        }
        const segmentTotalSales = segmentPerRange?.[key] || 0;
            newCells.sales_composition_ratio = ((total_sales / segmentTotalSales) * 100)
            return newCells;
        });
    
        // start: sales_composition_ratio_yoy
    
        const lastYearSegmentPerRange = {};
        lastYearSalesPrice.forEach(p => {
            const {
                agg_target_day,
                total_sales,
                week_split
        } = p;
            let key = agg_target_day
        if(week_split != undefined){
            key = key + week_split
        }
        if(lastYearSegmentPerRange[key] === undefined) lastYearSegmentPerRange[key] = 0;
            lastYearSegmentPerRange[key] += total_sales;
        });
    
        const salesCompositionRatioPreviousYear = lastYearSalesPrice.map(sp => {
            const newCells = {...sp};
            const {
                total_sales,
                customer_segment_id,
                agg_target_day,
            week_split
            } = newCells;
            let key = agg_target_day
        if(week_split != undefined){
            key = key + week_split
        }
        const lastYearSegmentTotalSales = lastYearSegmentPerRange?.[key] || 0;
            newCells.sales_composition_ratio = (total_sales / lastYearSegmentTotalSales * 100) ;
            newCells.customer_segment_id = customer_segment_id
            return newCells;
        });
    
        const salesCompositionRatioYoY = salesCompositionRatio.map(scr => {
            const newCells = {...scr};
            const {
                sales_composition_ratio,
                customer_segment_id,
                agg_target_day,
                week_split
        } = newCells;
            const lastYearSalesCompositionRatio = salesCompositionRatioPreviousYear
                .find(scrpy => scrpy.customer_segment_id === customer_segment_id && scrpy.next_year_date === agg_target_day && scrpy.week_split === week_split)
                ?.sales_composition_ratio || 0;
            newCells.sales_composition_ratio_yoy = sales_composition_ratio-lastYearSalesCompositionRatio;
            return newCells
        })
        
        // end: sales_composition_ratio_yoy
    
        const costPerCustomer = numberOfCustomers.map(noc => {
            const newCells = {...noc};
            const {
                number_of_unique_customers,
                customer_segment_id,
                agg_target_day,
                week_split
        } = newCells;
            const spTotalSales = salesPrice
                .find(sp => sp.customer_segment_id === customer_segment_id && sp.agg_target_day === agg_target_day && sp.week_split === week_split)
                ?.total_sales || 1;
            newCells.cost_per_customer =  (spTotalSales / number_of_unique_customers);
            newCells.date_month = moment(agg_target_day).format('MM-DD');
            return newCells;
        });
    
        const costPerCustomerPreviousYear = lastYearNumberOfCustomers.map(lynoc => {
            const newCells = {...lynoc};
            const {
                number_of_unique_customers,
                customer_segment_id,
                agg_target_day,
            week_split
            } = newCells;
            const spTotalSales = lastYearSalesPrice
                .find(lysp => lysp.customer_segment_id === customer_segment_id && lysp.agg_target_day === agg_target_day && lysp.week_split === week_split)
                ?.total_sales || 1;
            newCells.cost_per_customer = ( spTotalSales / number_of_unique_customers);
            newCells.date_month = moment(agg_target_day).format('MM-DD');
            return newCells;
        });
    
        const costPerCustomerYoY = costPerCustomer.map(cop => {
            const newCells = {...cop};
            const {
                customer_segment_id,
                agg_target_day,
                cost_per_customer,
            week_split
            } = newCells;
            const lastYearCostPerCustomer = costPerCustomerPreviousYear
                .find(cpcpy => cpcpy.next_year_date === agg_target_day && cpcpy.customer_segment_id === customer_segment_id && cpcpy.week_split == week_split)
                ?.cost_per_customer || 0;
            newCells.cost_per_customer_yoy = ((cost_per_customer/lastYearCostPerCustomer)-1)*100;
            return newCells
        })
    
        const totalNumberOfCustomersYoY = totalNumberOfCustomers.map(tnoc => {
            const newCells = { ...tnoc };
            const {
                customer_segment_id,
                agg_target_day,
                number_of_total_customers,
            week_split
            } = newCells
            const previousYearTotalNumberOfCustomers = lastYearTotalNumberOfCustomers
                .find(lytnoc => lytnoc.next_year_date === agg_target_day && lytnoc.customer_segment_id === customer_segment_id && lytnoc.week_split === week_split)
                ?.number_of_total_customers || 0
            newCells.number_of_total_customers_yoy = ((number_of_total_customers/previousYearTotalNumberOfCustomers)-1) * 100;
            return newCells
        })
    
        const numberOfCustomersYoY = numberOfCustomers.map(noc => {
            const newCells = { ...noc };
            const {
                customer_segment_id,
                agg_target_day,
                number_of_unique_customers,
            week_split
            } = newCells
            const previousYearNumberOfUniqueCustomers = lastYearNumberOfCustomers
                .find(lynoc => lynoc.next_year_date === agg_target_day && lynoc.customer_segment_id === customer_segment_id && lynoc.week_split === week_split)
                ?.number_of_unique_customers || 0
            newCells.number_of_unique_customers_yoy =  ((number_of_unique_customers/previousYearNumberOfUniqueCustomers)-1) * 100 ;
            return newCells
        })
    
        const frequencyOfPurchase = totalNumberOfCustomers.map(tnoc => {
            const newCells = {...tnoc};
            const {
                number_of_total_customers,
                customer_segment_id,
                agg_target_day,
                week_split
        } = newCells;
            const number_of_unique_customers = numberOfCustomers
                .find(noc => noc.customer_segment_id === customer_segment_id && noc.agg_target_day === agg_target_day && noc.week_split === week_split)
                ?.number_of_unique_customers || 0;
            newCells.frequency_of_purchase = (number_of_total_customers / number_of_unique_customers) ;
            return newCells;
        });
    
        const frequencyOfPurchasePreviousYear = lastYearTotalNumberOfCustomers.map(lytnoc => {
            const newCells = {...lytnoc};
            const {
                number_of_total_customers,
                customer_segment_id,
                agg_target_day,
            week_split
            } = newCells;
            const number_of_unique_customers = lastYearNumberOfCustomers
                .find(lynoc => lynoc.customer_segment_id === customer_segment_id && lynoc.agg_target_day === agg_target_day && lynoc.week_split === week_split)
                ?.number_of_unique_customers || 1;
            newCells.frequency_of_purchase = (number_of_total_customers / number_of_unique_customers);
            return newCells;
        });
    
        const frequencyOfPurchaseYoY = frequencyOfPurchase.map(fop => {
            const newCells = {...fop};
            const {
                agg_target_day,
                customer_segment_id,
                frequency_of_purchase,
            week_split
            } = newCells;
            const lastYearFrequencyOfPurchase = frequencyOfPurchasePreviousYear
                .find(foppy => foppy.customer_segment_id === customer_segment_id && foppy.next_year_date === agg_target_day && foppy.week_split === week_split)
                ?.frequency_of_purchase || 0;
            newCells.frequency_of_purchase_yoy = ((frequency_of_purchase/lastYearFrequencyOfPurchase)-1) * 100
            return newCells
        })
    
        let salesRowSetting = [];
        let margeArray      = [];
    
        if(selectedTargets.includes('sales')){
            salesRowSetting = salesRowSetting.concat([{
                indicatorName : '売上',
                valueKey      : 'total_sales',
            },{
                indicatorName : '売上YoY',
                valueKey      : 'sales_yoy_ratio',
                showPercentage : true,
                showPlusSign : true,
            }]);
            margeArray = margeArray.concat([
                salesPrice,
                salesYoY,
            ])
        }
        if(selectedTargets.includes('sales_composition')){
            salesRowSetting = salesRowSetting.concat([{
                indicatorName : '売上構成比',
                valueKey      : 'sales_composition_ratio',
                showPercentage : true,

            }]);
            salesRowSetting = salesRowSetting.concat([{
                indicatorName : '売上構成比YOY',
                valueKey      : 'sales_composition_ratio_yoy',
                showPlusSign : true,

            }]);
            margeArray = margeArray.concat([
                salesCompositionRatio,
                salesCompositionRatioYoY
            ])
        }
        if(selectedTargets.includes('customer_unit_price')){
            salesRowSetting = salesRowSetting.concat([{
                indicatorName : '顧客単価',
                valueKey      : 'cost_per_customer',
            }]);
            salesRowSetting = salesRowSetting.concat([{
                indicatorName : '顧客単価YOY',
                valueKey      : 'cost_per_customer_yoy',
                showPercentage : true,
                showPlusSign : true,

            }]);
            margeArray = margeArray.concat([
                costPerCustomer,
                costPerCustomerYoY
            ])
        }
        if(selectedTargets.includes('total_customers')){
            salesRowSetting = salesRowSetting.concat([{
                indicatorName : '顧客数',
                valueKey      : 'number_of_unique_customers',
            }]);
            salesRowSetting = salesRowSetting.concat([{
                indicatorName : '顧客数YoY',
                valueKey      : 'number_of_unique_customers_yoy',
                showPercentage : true,
                showPlusSign : true,
            }]);
            margeArray = margeArray.concat([
                numberOfCustomers,
                numberOfCustomersYoY
            ])
        }
        if(selectedTargets.includes('total_no_of_customers')){
            salesRowSetting = salesRowSetting.concat([{
                indicatorName : '顧客数（のべ）',
                valueKey      : 'number_of_total_customers',
            }]);
            salesRowSetting = salesRowSetting.concat([{
                indicatorName : '顧客数（のべ）YoY',
                valueKey      : 'number_of_total_customers_yoy',
                showPercentage : true,
                showPlusSign : true,

            }]);
            
            margeArray = margeArray.concat([
                totalNumberOfCustomers,
                totalNumberOfCustomersYoY
            ])
        }
        if(selectedTargets.includes('freq_of_purchases')){
            salesRowSetting = salesRowSetting.concat([{
                indicatorName : '購買頻度',
                valueKey      : 'frequency_of_purchase',
            }]);
            salesRowSetting = salesRowSetting.concat([{
                indicatorName : '購買頻度YoY',
                valueKey      : 'frequency_of_purchase_yoy',
                showPercentage : true,
                showPlusSign : true,

            }]);
            margeArray = margeArray.concat([
                frequencyOfPurchase,
                frequencyOfPurchaseYoY
            ])
        }
        const {
            margeData,
            columnKeys,
        } = margeRows({
            margeArray,
            rowSettings : salesRowSetting,
            segmentNames, 
            dateFormat, 
            periodType
    
        });
        const percentageColumns = ['売上YoY', '売上構成比', '顧客単価YOY', '顧客数YoY', '顧客数（のべ）YoY', '購買頻度YoY']
        const prefixPlusSignColumns = ['売上YoY', '売上構成比YOY', '顧客単価YOY', '顧客数YoY', '顧客数（のべ）YoY', '購買頻度YoY']
        const tabOptions = {
            'セグメント' : [...new Set(margeData.map(d => d[1]))].map((val, i) => {
                return {
                    name : getFormattedSegmentName({
                        segmentNames, 
                        val,
                    }),
                    selected : i === 0,
                }
            }),
            '指標' : [...new Set(margeData.map(d => d[0]))].map((val, i) => {
                const percentageRequired = percentageColumns.includes(val);
                return {
                    name : val,
                    selected : i === 0,
                    setPercentage: percentageRequired,
                    setPlusSign : prefixPlusSignColumns.includes(val) || false,
                }
            }),
        }
        ////console.log({tabOptions}, {margeData}, {columnKeys});
        columnKeys.push('showPercentage');
        columnKeys.push('showPlusSign');
        const originalDataFrame = new dfd.DataFrame(margeData, {columns: columnKeys});
        console.log("originalDataFrame : ",originalDataFrame)
        return {
            tabOptions,
            originalDataFrame,
            recordsCount: recordsCount ?? 0
        }
    }
    catch {
        return {
            error: true,
            message: '処理が失敗しました。管理者を連絡してください。'
        }
    }
    
}

const getAssortmentSummary = async (props) => {
    try {
        const {
            token, 
            history, 
            currentFormValues,
            filterOptions,
            selectedTargets,
        } = props;
    
        const {
            datePeriodType = [],
            segment        = [],
            start_date     = moment().add(-1, 'month'),
            end_date       = moment(),
        } = currentFormValues?.current || {};
        const periodType   = datePeriodType.find(option => option.selected).value;
        const dateFormat   = ['monthly', 'monthly_split'].includes(periodType) ? 'YYYY-MM' : 'YYYY-MM-DD';
        const segmentNames = segment || [];
        const startDate    = moment(start_date).format('YYYY-MM-DD');
        const endDate      = moment(end_date).format('YYYY-MM-DD');
        const dataBody     = getSubmitValues({
            filterOptions, 
            currentFormValues,
        });
    
        const lastYearDates = getLastYearDates(startDate, endDate, periodType)
    
        const lastYearStartDate = lastYearDates.startDate;
        const lastYearEndDate   =lastYearDates.endDate;
    
        const salesParams  = [{
            // 売上
            metric     : ['sum'],
            unit       : ['price'],
            start_date : startDate,
            end_date   : endDate,
        },{
            // 昨年度売上 -1 year
            metric     : ['sum'],
            unit       : ['price'],
            start_date : lastYearStartDate,
            end_date   : lastYearEndDate,
        },{
            // 顧客数
            metric     : ['count'],
            unit       : ['customer'],
            start_date : startDate,
            end_date   : endDate,
        },{
            // 顧客数 -1 year
            metric     : ['count'],
            unit       : ['customer'],
            start_date : lastYearStartDate,
            end_date   : lastYearEndDate,
        },{
            // 品数
            metric     : ['count'],
            unit       : ['product_customer'],
            start_date : startDate,
            end_date   : endDate,
        },{
            // 品数 -1 year
            metric     : ['count'],
            unit       : ['product_customer'],
            start_date : lastYearStartDate,
            end_date   : lastYearEndDate,
        },

        {
            //  records count
            api : "purchases",
            body : {
                start_date : startDate,
                end_date   : endDate,
                store_info : [...dataBody.store_info],
                source     : "pos",
                metric     : ["count"],
                unit       : ["receipt"],
            },
            name:"recordCountBody"
        }
    ]
        delete dataBody.mesh_id
        delete dataBody?.categories
        delete dataBody?.filter_category_code_list;
        const requests = salesParams.map(params => {
            return getNdJsonFetch(token, history, `https://${API_URL}/purchases`, params?.name == "recordCountBody" ? {...params?.body} : {...dataBody, ...params}, 0);
        });
        const results = await Promise.all(requests)
            .then(res => res);
    
        const salesPrice                  = results[0];
        const lastYearSalesPrice          = results[1];
        const numberOfCustomers           = results[2];
        const latestYearNumberOfCustomers = results[3];
        const numberOfProducts            = results[4];
        const lastYearNumberOfProducts    = results[5];
    
    
    
        //一人当たり買上品目数
        const purchasedPerCustomer = numberOfCustomers.map(noc => {
            const newCells = {...noc};
            const {
                number_of_unique_customers,
                customer_segment_id,
                agg_target_day,
                week_split
            } = newCells;
            const productCount = numberOfProducts
                .find(nop =>(periodType === "weekly" || periodType==="weekly_split" ? 
                ((week_split === nop.week_split) && 
                nop.customer_segment_id === customer_segment_id && 
                  moment(nop.agg_target_day).week() === moment(agg_target_day).week() ):
               (week_split === nop.week_split) && 
               nop.customer_segment_id === customer_segment_id &&
                moment(nop.agg_target_day).format("MM-DD") === moment(agg_target_day).format("MM-DD") ))
                ?.unique_customer_items || 1;
            newCells.purchased_per_customer = productCount / number_of_unique_customers;
            return newCells;
        });
        
        //一人当たり買上品目数 -1 year
        const lastYearPurchasedPerCustomer = latestYearNumberOfCustomers.map(noc => {
            const newCells = {...noc};
            const {
                number_of_unique_customers,
                customer_segment_id,
                agg_target_day,
                week_split
            } = newCells;
            const productCount = lastYearNumberOfProducts
                .find(nop => (periodType === "weekly" || periodType==="weekly_split" ? 
                ((week_split === nop.week_split) && 
                nop.customer_segment_id === customer_segment_id && 
                  moment(nop.agg_target_day).week() === moment(agg_target_day).week() ):
               (week_split === nop.week_split) && 
               nop.customer_segment_id === customer_segment_id &&
                moment(nop.agg_target_day).format("MM-DD") === moment(agg_target_day).format("MM-DD") ))
                ?.unique_customer_items || 1;
            newCells.purchased_per_customer = productCount / number_of_unique_customers;
            return newCells;
        });
    
        //一人当たり買上品目数YoY
        const purchasedPerCustomerYoY = purchasedPerCustomer.map(ppc => {
            const newCells = {...ppc};
            const {
                purchased_per_customer,
                customer_segment_id,
                agg_target_day,
                week_split
            } = newCells;
            const last_year_purchased_per_customer = lastYearPurchasedPerCustomer
                .find(lyppc => (periodType === "weekly" || periodType==="weekly_split" ? 
                ((week_split === lyppc.week_split) && 
                lyppc.customer_segment_id === customer_segment_id && 
                  moment(lyppc.agg_target_day).week() === moment(agg_target_day).week() ):
               (week_split === lyppc.week_split) && 
               lyppc.customer_segment_id === customer_segment_id &&
                moment(lyppc.agg_target_day).format("MM-DD") === moment(agg_target_day).format("MM-DD") ) )?.purchased_per_customer || 0;
            newCells.purchased_per_customer_yoy = ((purchased_per_customer / last_year_purchased_per_customer) - 1) * 100;
            return newCells;
        });
    
    
        // 顧客単価
        const costPerCustomer = numberOfCustomers.map(noc => {
            const newCells = {...noc};
            const {
                number_of_unique_customers,
                customer_segment_id,
                agg_target_day,
                week_split
            } = newCells;
            const spTotalSales = salesPrice
                .find(sp => (periodType === "weekly" || periodType==="weekly_split" ? 
                ( (week_split === sp.week_split) && 
                  sp.customer_segment_id === customer_segment_id && 
                  moment(sp.agg_target_day).week() === moment(agg_target_day).week() ):
               (week_split === sp.week_split) && 
                sp.customer_segment_id === customer_segment_id &&
                moment(sp.agg_target_day).format("MM-DD") === moment(agg_target_day).format("MM-DD") ))
                ?.total_sales || 1;
            newCells.cost_per_customer = spTotalSales / number_of_unique_customers;
            return newCells;
        });
    
        // 顧客単価 -1 year
        const lastYearCostPerCustomer = latestYearNumberOfCustomers.map(lycpc => {
            const newCells = {...lycpc};
            const {
                number_of_unique_customers,
                customer_segment_id,
                agg_target_day,
                week_split
            } = newCells;
            const last_year_sales_price = lastYearSalesPrice
                .find(lysp => (periodType === "weekly" || periodType==="weekly_split" ? 
                 ( (week_split === lysp.week_split) && 
                   lysp.customer_segment_id === customer_segment_id && 
                   moment(lysp.agg_target_day).week() === moment(agg_target_day).week() ):
                (week_split === lysp.week_split) && 
                 lysp.customer_segment_id === customer_segment_id &&
                 moment(lysp.agg_target_day).format("MM-DD") === moment(agg_target_day).format("MM-DD") )
                )?.total_sales || 1;
            newCells.cost_per_customer = last_year_sales_price / number_of_unique_customers;
            return newCells;
        });
    
        //顧客単価YoY
        const costPerCustomerYoY = costPerCustomer.map(cpc => {
            const newCells = {...cpc};
            const {
                cost_per_customer,
                customer_segment_id,
                agg_target_day,
                week_split
            } = newCells;
            const last_year_cost_per_customer = lastYearCostPerCustomer
                .find(lycpc =>  
                    (periodType === "weekly" || periodType==="weekly_split" ? 
                    ( (week_split === lycpc.week_split) && 
                    lycpc.customer_segment_id === customer_segment_id && 
                      moment(lycpc.agg_target_day).week() === moment(agg_target_day).week() ):
                   (week_split === lycpc.week_split) && 
                   lycpc.customer_segment_id === customer_segment_id &&
                    moment(lycpc.agg_target_day).format("MM-DD") === moment(agg_target_day).format("MM-DD") )
                    )
                ?.cost_per_customer || 0;
            newCells.cost_per_customer_yoy = ((cost_per_customer /last_year_cost_per_customer) -1) * 100;
            return newCells;
        });
    
        let assortmentSummaryRowSetting = [];
        let margeArray = [];
        if(selectedTargets.includes('customer_unit_price')){
            assortmentSummaryRowSetting = assortmentSummaryRowSetting.concat([{
                indicatorName : '顧客単価',
                valueKey      : 'cost_per_customer',
            },{
                indicatorName : '顧客単価YoY',
                valueKey      : 'cost_per_customer_yoy',
                showPercentage : true,
                showPlusSign:true
            }]);
            margeArray = margeArray.concat([
                costPerCustomer,
                costPerCustomerYoY,
            ]);
        }
        if(selectedTargets.includes('total_no_of_product_per_customer')){
            assortmentSummaryRowSetting = assortmentSummaryRowSetting.concat([{
                indicatorName : '一人当たり買上品目数',
                valueKey      : 'purchased_per_customer',
            },{
                indicatorName : '一人当たり買上品目数YoY',
                valueKey      : 'purchased_per_customer_yoy',
                showPercentage : true,
                showPlusSign:true
            }]);
            margeArray = margeArray.concat([
                purchasedPerCustomer,
                purchasedPerCustomerYoY,
            ]);
        }
        const {
            margeData,
            columnKeys,
        } = margeRows({
            margeArray,
            rowSettings : assortmentSummaryRowSetting,
            segmentNames, 
            dateFormat, 
            periodType
        });
        const tabOptions = {
            'セグメント' : [...new Set(margeData.map(d => d[1]))].map((val, i) => {
                return {
                    name : getFormattedSegmentName({
                        segmentNames, 
                        val,
                    }),
                    selected : i === 0,
                }
            }),
            '指標' : [...new Set(margeData.map(d => d[0]))].map((val, i) => {
                return {
                    name : val,
                    selected : i === 0,
                }
            }),
        }
        columnKeys.push('showPercentage')
        columnKeys.push('showPlusSign');
        const originalDataFrame = new dfd.DataFrame(margeData, {columns: columnKeys});

        const recordsCount = [...(results?.[6] || [])].reduce((o,rc)=>{return o + (parseInt(rc?.number_of_total_customers) ?? 0)},0) ?? 0;

        return {
            tabOptions,
            originalDataFrame,
            recordsCount:recordsCount ?? 0
        }
    }
    catch {
        return {
            error: true,
            message: '処理が失敗しました。管理者を連絡してください。'
        }
    } 
}

const getAssortmentBasketRowParams = (row) => {
    const [
        view_category_code=null,
        lift='',
        categoryName='',
    ] = row;
    return {
        view_category_code,
        lift,
        categoryName,
    }
}
const getCategoryValue = (props) => {
    const {
        chainName,
        categorySum=null,
        categoryLevel=null,
        category_code=null,
        uniqueKey='number_of_unique_products',
        type='share',
    } = props;

    if(categorySum === null || categoryLevel === null || category_code === null) return 0;
    const targetParams = categorySum?.[chainName]?.find(sum => sum[`category_level${categoryLevel}`] === category_code) || {};
    const uniqueValue = targetParams?.[uniqueKey] || null;
    if(type === 'share'){
        //const total = categorySum?.[chainName]?.map(sum => sum.number_of_unique_products).reduce((sum, current) => sum += current, 0) || null;
        const total = Object.keys(categorySum)
            .map(chain_name => categorySum[chain_name].find(sum => sum[`category_level${categoryLevel}`] === category_code))
            .map(data => data?.[uniqueKey] || 0)
            .reduce((sum, current) => sum += current, 0) || 0;

        const value = targetParams?.[uniqueKey] || null;
        if(value === null || total === null) return 0;
        return Number((value / total * 100).toFixed(2));
    }
    const {
        number_of_unique_products=null,
    } = targetParams; 
    return number_of_unique_products;
}

const getAssortmentSingleItemComparison = async (props) => {
    try {
        const {
            token, 
            history, 
            currentFormValues,
            filterOptions,
            selectedTargets,
        } = props;
        const {
            //datePeriodType = [],
            //segment        = [],
            start_date     = moment().add(-1, 'month'),
            end_date       = moment(),
            store          = [],
            competingStores= [],
            categories     = [],
            categoryLevel  = 4,
        } = currentFormValues?.current || {};
        ////console.log([...new Set(store.map(s => `${s.value.chain_id}:"${s.value.chain_name}"`))]);
        //const periodType   = datePeriodType.find(option => option.selected).value;
        //const dateFormat   = ['monthly', 'monthly_split'].includes(periodType) ? 'YYYY-MM' : 'YYYY-MM-DD';
        //const segmentNames = segment || [];
        const startDate    = moment(start_date).format('YYYY-MM-DD');
        const endDate      = moment(end_date).format('YYYY-MM-DD');
        const allStoreNames= getAllStoreNames(store, competingStores);
        const flatCategories = getFlatCategoriesList(categories);
        const dataBody     = getSubmitValues({
            filterOptions, 
            currentFormValues,
        });
        const category_level_key = `category_level${categoryLevel}`;
        const categoryList = {
            level1: [],
            level2: [],
            level3: [],
            level4: [],
        }
        categoryList[`level${categoryLevel}`] = dataBody.filter_category_code_list;
        let finaleCategoriesData= selectedCategoryLength(categories,categoryLevel)
        if( finaleCategoriesData?.lenghtOfCategories === 0){
            return{
                error:true,
                message:"選択した条件ではデータが見つかりません！"
            }
        }
        const assortmentSingleItemParams = [{
            api : "purchases",
            body : {
                start_date : startDate,
                end_date   : endDate,
                store_info : [...dataBody.store_info, ...dataBody.competitor_store],
                results_per: [
                    "chain_id",
                    "product",
                    category_level_key,
                ],
                source     : "pasha",
                metric     : ["count", "sum"],
                unit       : ["items", "price"],
                categories : finaleCategoriesData.data,
            },
        },{
             // records Count
            api : "purchases",
            body : {
                start_date : startDate,
                end_date   : endDate,
                store_info : [...dataBody.store_info, ...dataBody.competitor_store],
                source     : "pasha",
                unit     : ["receipt"],
                metric     : ['count'],
                categories : finaleCategoriesData.data,
            },
            name:"recordCountBody"
        }];
    
        delete dataBody.categories;
        delete dataBody.competingStores;
        delete dataBody.competitor_store;
        delete dataBody.competitor_store_mesh_id;
        delete dataBody.filter_category_code;
        delete dataBody.filter_category_code_list;
        delete dataBody.mesh_id;
        delete dataBody.view_category_code;
    
        const requests = assortmentSingleItemParams.map(params => {
            const {
                api,
                body,
                name=false,
            } = params;
            return getNdJsonFetch(token, history, `https://${API_URL}/${api}`, name=="recordCountBody" ? {...body}: {...dataBody, ...body}, 0);
        });
        const results = await Promise.all(requests)
            .then(res => res);

        const rows = results[0].sort((a, b) => a.number_of_items_purchased < b.number_of_items_purchased ? 1 : -1);
        const recordsCount = [...(results?.[1] || [])]?.reduce((o,rc)=>{return o + (parseInt(rc?.number_of_total_customers) ?? 0)},0) ?? 0;
    
        const maxNumOfProductsPerCategory = 30;
        const byStores = {};
        rows.forEach(row => {
            const {
                chain_id,
                [category_level_key] : view_category_code,
                product,
                number_of_items_purchased,
                total_sales,
            } = row;
            const categoryName = getCategoryName({
                category_level_key:category_level_key,
                categories : flatCategories.map(r=> {return {...r,value:parseInt(r.value)}}), 
                val        : view_category_code,
            });
    
            const chainName = getChainName({
                allStoreNames,
                chain_id,
            });
    
            if(byStores[chainName] === undefined){
                byStores[chainName] = {};
            }
            if(byStores[chainName][categoryName] === undefined){
                byStores[chainName][categoryName] = [];
            }
    
            const num = byStores[chainName][categoryName].length;
            if(num >= maxNumOfProductsPerCategory){
                return;
            }
    
            byStores[chainName][categoryName].push([
                //view_category_code,
                //categoryName,
                num+1,
                product,
                number_of_items_purchased,
                total_sales/number_of_items_purchased,
                false,
            ]);
        });
    
        const primaryKey = getPrimaryChainName(dataBody, allStoreNames);
        const secondaryKeys = Object.keys(byStores).filter(key => key !== primaryKey);
    
        secondaryKeys.forEach(chainName => {
            Object.keys(byStores[chainName]).forEach(categoryName => {
                byStores[chainName][categoryName].forEach(obj => {
                    const product = obj[1];
                    obj[4] = (!byStores[primaryKey] || !byStores[primaryKey][categoryName]?.find(c => c[1] === product)) ? true : false;
                });
            })
        });
        ////console.log({byStores})
    
        let categoryKeys = [];
        Object.keys(byStores).map(key => {
            categoryKeys = categoryKeys.concat(Object.keys(byStores[key]));
        });
        categoryKeys = [...new Set(categoryKeys)].sort();
    
        ////console.log({primaryKey})
        ////console.log({secondaryKeys})
        ////console.log({categoryKeys})
    
        let tabByRows = new Array([...secondaryKeys, primaryKey].length).fill(new Array());
        categoryKeys.map(categoryKey => {
            const maxArrayNum = (Object.keys(byStores).map(key => byStores[key][categoryKey]?.length || 0)).reduce((a, b) => Math.max(a, b));
            ([primaryKey].concat(secondaryKeys)).map((storeKey, storeIdx) => {
                const blankArray = new Array(maxArrayNum)
                                    .fill([categoryKey, null, null, null, null, false], 0, 1)
                                    .fill(["temp_category_name", null, null, null, null, false], 1);


                if(byStores[storeKey] && byStores[storeKey][categoryKey] !== undefined){
                    byStores[storeKey][categoryKey].map((row, i) => {
                        const [
                            index,
                            product,
                            number_of_items_purchased,
                            unit_price,
                            no_item_flag,
                        ] = row;
                        const viewCategoryName = (i === 0) ? categoryKey : "temp_category_name";
                        //const noItemFlag = (storeKey !== primaryKey && (!byStores[storeKey] || !byStores[primaryKey][categoryKey]?.find(c => c[1] === product))) ? true : false;
                        blankArray[i] = [viewCategoryName, index, product, number_of_items_purchased, unit_price ,no_item_flag];
                    });
                }
                tabByRows[storeIdx] = tabByRows[storeIdx].concat(blankArray);
            });
        });
    
        const tableRows = [];
        tabByRows[0].forEach((_, j) => {
            let tmp = [];
            tabByRows.forEach((t, i) => {
                tmp = tmp.concat(t[j]);
            });
            tableRows.push(tmp);
        })
        ////console.log({tableRows})
    
        //delete blank rows
        tabByRows = tabByRows.map(tab => {
            return tab.filter(r => r[1]);
        });
        ////console.log({tabByRows})
    
        const columnKeys = ([primaryKey].concat(secondaryKeys)).map((key, i) => {
            return [`${key} : カテゴリ`, `${key} : #`, `${key} : 商品名`, `${key} : 売上件数`, `${key} : 商品単価`, `${key} : hidden__自店にない商品`];
        }).flat();
        ////console.log({byStores});
        ////console.log({tableRows}, {columnKeys});
        //const originalDataFrame = new dfd.DataFrame(tableRows, {columns: columnKeys});
        const tabByTable = {
            tabName : [primaryKey, ...secondaryKeys],
            single : {
                rows    : tableRows,
                columns : columnKeys,
            },
            
            tab : tabByRows.map((rows, i) => {
                // console.log({rows})
                return {
                    rows,
                    columns : [`カテゴリ`, `#`, `商品名`, `売上件数`, `商品単価`, `hidden__自店にない商品`],
                }
            }),
        }
        return {
            tabOptions : null,
            originalDataFrame : tabByTable,
            recordsCount:recordsCount ?? 0,
        }
    }
    catch {
        return {
            error: true,
            message: '処理が失敗しました。管理者を連絡してください。'
        }
    }
}

const getAssortmentBasket = async (props) => {
    try{
        const {
            token, 
            history, 
            currentFormValues,
            filterOptions,
            selectedTargets,
        } = props;
        const {
            datePeriodType = [],
            segment        = [],
            start_date     = moment().add(-1, 'month'),
            end_date       = moment(),
            store          = [],
            competingStores= [],
            categories     = [],
        } = currentFormValues?.current || {};
        const periodType   = datePeriodType.find(option => option.selected).value;
        const dateFormat   = ['monthly', 'monthly_split'].includes(periodType) ? 'YYYY-MM' : 'YYYY-MM-DD';
        const segmentNames = segment || [];
        const startDate    = moment(start_date).format('YYYY-MM-DD');
        const endDate      = moment(end_date).format('YYYY-MM-DD');
        const allStoreNames= getAllStoreNames(store.filter(s => s.value.chain_name !== ' ' && s.value.chain_name !== 'プレッセ'), competingStores);
        const flatCategories = getFlatCategoriesList(categories);
        const dataBody     = getSubmitValues({
            filterOptions, 
            currentFormValues,
        });
    
        const categoryLevel = currentFormValues.current.categoryLevel || 4;

        let finaleCategoriesData= selectedCategoryLength(categories,categoryLevel)
        if( finaleCategoriesData?.lenghtOfCategories === 0){
            return{
                error:true,
                message:"選択した条件ではデータが見つかりません！"
            }
        }

        const purchasesBody = {
            "start_date" : dataBody.start_date,
            "end_date"   : dataBody.end_date,
            "store_info" : [...dataBody.store_info, ...dataBody.competitor_store],
            "results_per": [
                //`category_level${currentFormValues.current.categoryLevel+1}`,
                'chain_id',
                `category_level${categoryLevel}`,
            ],
            "segment_ids": [...dataBody.segment_ids],
            "source": "pasha",
            "metric": [
                "sum",
                "count",
            ],
            "unit": [
                "price",
                "product"
            ],
            //"categories" : dataBody.categories,
            "categories": finaleCategoriesData.data,
            "range" : dataBody.range,
        }
        const recordCountBody = {
            api : "purchases",
            body : {
                start_date : startDate,
                end_date   : endDate,
                store_info : [...dataBody.store_info, ...dataBody.competitor_store],
                source     : "pasha",
                unit       : ["receipt"],
                metric     : ['count'],
                categories : finaleCategoriesData.data,
            },
        }
    
        const storeArray = (storeInfo2Array(dataBody.store_info)).concat(storeInfo2Array(dataBody.competitor_store))
        dataBody.area_store_id_and_area_store_company_id_list = storeArray;
        dataBody.customer_segment_id_list = [...dataBody.segment_ids];
        const primaryKey = getPrimaryChainName(dataBody, allStoreNames);
    
        delete dataBody.categories;
        delete dataBody.competingStores;
        delete dataBody.competitor_store;
        delete dataBody.competitor_store_mesh_id;
        delete dataBody.mesh_id;
        delete dataBody.metric;
        delete dataBody.results_per;
        delete dataBody.segment_ids;
        delete dataBody.source;
        delete dataBody.store_info;
        delete dataBody.unit;
        dataBody.filter_category_code_list = Object.values(finaleCategoriesData?.data).flat();
    
        const assortmentBasketParams = [{
            api  : 'purchase-lift',
            body : dataBody,
        },{
            api  : 'purchases',
            body : purchasesBody,
        }];

        const byStores = {};
        let view_category_code_from_purchase_list = [];
        const categorySum = {};

        let requestPuchaseLift = await getNdJsonFetch(token, history, `https://${API_URL}/${assortmentBasketParams[0]?.api}`, assortmentBasketParams[0]?.body, 0);

        requestPuchaseLift?.forEach(row => {
            const {
                store_chainname,
                lift,
                view_category_code,
            } = row;
            const categoryName = getCategoryName({
                category_level_key:`category_level${categoryLevel}`,
                categories : flatCategories.map(r=> {return {...r,value:parseInt(r.value)}}), 
                val        : view_category_code,
            });
            if(byStores[store_chainname] === undefined){
                byStores[store_chainname] = [];
            }
            view_category_code_from_purchase_list.push(view_category_code);
            byStores[store_chainname].push([view_category_code, lift, categoryName]);
        })

        let purchaseBody = {...assortmentBasketParams[1]?.body,
            categories:{...assortmentBasketParams[1]?.body?.categories, [`level${categoryLevel}`] :view_category_code_from_purchase_list }};

        let requestPuchases = await getNdJsonFetch(token, history, `https://${API_URL}/${assortmentBasketParams[1]?.api}`, {...purchaseBody, }, 0);

        requestPuchases?.forEach(r => {
            const {
                chain_id,
            } = r;
            const chain_label = getChainName({
                allStoreNames,
                chain_id,
            });
            if(chain_label && categorySum[chain_label] === undefined){
                categorySum[chain_label] = [];
            }
            categorySum[chain_label] && categorySum[chain_label].push(r);
        });

         // records Count
        const recordsCountResults = await 
            getNdJsonFetch(
            token, 
            history, 
            `https://${API_URL}/purchases`, 
            {...recordCountBody.body}, 0);
        const recordsCount = [... (recordsCountResults || [])]?.reduce((o,rc)=>{return o + (parseInt(rc?.number_of_total_customers) ?? 0)},0) ?? 0;
        console.log({recordsCountResults});

        if(byStores[primaryKey] === undefined){
            return {
                error: true,
                message: "選択した条件ではデータが見つかりません !"
            }
        }

        Object.keys(byStores).map(store=>{
            byStores[store] = byStores[store].sort((a, b) => a[1] < b[1] ? 1 : -1);
        })
        
        const secondaryKeys = Object.keys(byStores).filter(key => key !== primaryKey);
    
        const tabByRows = new Array([...secondaryKeys, primaryKey].length)//.fill(new Array()); // created tabs for each stores
    
        // get max rows of any store and fill it with null and later we will add the data to it.
        const blankRows = new Array((Object.keys(byStores).map(key => byStores[key].length)).reduce((a, b) => Math.max(a, b))).fill(null);
        const tableRows = blankRows.map((_, i) => {
            const row = byStores[primaryKey]?.[i] || [];
            const {
                view_category_code,
                lift,
                categoryName,
            } = getAssortmentBasketRowParams(row);
            const rowIndex    = (i + 1);
            const walletShare = view_category_code ? getCategoryValue({
                chainName : primaryKey,
                categorySum,
                categoryLevel : categoryLevel,
                category_code : view_category_code,
                uniqueKey : "total_sales",
            })  : NOT_A_NUMBER;
            const count = view_category_code ? getCategoryValue({
                chainName : primaryKey,
                categorySum,
                categoryLevel : categoryLevel,
                category_code : view_category_code,
                type : 'count',
            })  : NOT_A_NUMBER;
            const primaryRow  = [view_category_code ? rowIndex : '', categoryName, walletShare, lift, count];
    
            if(tabByRows[0] === undefined){
                tabByRows[0] = [];
            }
            tabByRows[0].push([...primaryRow]);
    
            const secondaryRows = secondaryKeys.map(key => {
                const secondaryRow = byStores[key]?.[i] || [];
                const {
                    view_category_code,
                    lift,
                    categoryName,
                } = getAssortmentBasketRowParams(secondaryRow);
                const primaryIndex = byStores[primaryKey].findIndex(row => row[0] === view_category_code);
                const rankDifference = view_category_code ? ( (primaryIndex === -1) ? "-" : i - primaryIndex ): '';
                const walletShare = view_category_code ? getCategoryValue({
                    chainName : key,
                    categorySum,
                    categoryLevel : categoryLevel,
                    category_code : view_category_code,
                    uniqueKey : "total_sales",
                })  : NOT_A_NUMBER;
                const count = view_category_code ? getCategoryValue({
                    chainName : key,
                    categorySum,
                    categoryLevel : categoryLevel,
                    category_code : view_category_code,
                    type : 'count',
                })  : NOT_A_NUMBER;
                if (!categoryName) return new Array(6).fill(NOT_A_NUMBER)
                return [view_category_code ? rowIndex : '', rankDifference, categoryName, walletShare, lift, count];
            });
            secondaryRows.forEach((r, j) => {
                const index = j + 1;
                if(tabByRows[index] === undefined){
                    tabByRows[index] = [];
                }
                tabByRows[index].push([...r]);
            });
    
            return primaryRow.concat(secondaryRows.flat());
        });

        const columnKeys = ([primaryKey].concat(secondaryKeys)).map((key, i) => {
            if(i === 0){
                return [`${key} : #`, `カテゴリ`, `${key} : ウォレットシェア`, `${key} : リフト`, `${key} : hidden__カウント`]
            }
            return [`${key} : #`, `${key} : 自店との順位差`, `${key} : カテゴリ`, `${key} : ウォレットシェア`, `${key} : リフト`, `${key} : hidden__カウント`];
        }).flat();

        const tabByTable = {
            tabName : [primaryKey, ...secondaryKeys],
            single : {
                rows    : tableRows,
                columns : columnKeys,
            },
            tab : tabByRows.map((rows, i) => {
                return {
                    rows,
                    columns : i === 0 ? [`#`, `カテゴリ`, `ウォレットシェア`, `リフト`, `hidden__カウント`] : [`#`, `自店との順位差`, `カテゴリ`, `ウォレットシェア`, `リフト`, `hidden__カウント`],
                }
            }),
        }
        return {
            tabOptions : null,
            originalDataFrame : tabByTable,
            recordsCount:recordsCount ?? 0
        }
    }
    catch {
        return {
            error: true,
            message: '処理が失敗しました。管理者を連絡してください。'
        }
    }
}
const getAssortmentCompetitor = async (props) => {
    try {
        const {
            token, 
            history, 
            currentFormValues,
            filterOptions,
        } = props;
        const {
            start_date     = moment().add(-1, 'month'),
            end_date       = moment(),
            store          = [],
            competingStores= [],
            categoryLevel  = 4,
        } = currentFormValues?.current || {};
        const startDate    = moment(start_date).format('YYYY-MM-DD');
        const endDate      = moment(end_date).format('YYYY-MM-DD');

        const allStoreNames= getAllStoreNames(store, competingStores);
        const dataBody     = getSubmitValues({
            filterOptions, 
            currentFormValues,
        });
    
        const categoryList = {
            level1: [],
            level2: [],
            level3: [],
            level4: [],
        }
        categoryList[`level${categoryLevel}`] = dataBody.filter_category_code_list;
    
        const assortmentCompetitorParams = [{
            api : "purchases",
            body : {
                start_date : startDate,
                end_date   : endDate,
                store_info : [...dataBody.store_info, ...dataBody.competitor_store],
                results_per: ["chain_id"],
                source     : "pasha",
                metric     : ["count"],
                unit       : ["items", "product_customer", "customer"],
                categories : categoryList,
            },
        },
        // records count
        {
            api : "purchases",
            body : {
                start_date : startDate,
                end_date   : endDate,
                store_info : [...dataBody.store_info, ...dataBody.competitor_store],
                source     : "pasha",
                metric     : ["count"],
                unit     : ["receipt"],
                categories : categoryList,
            },
            name:"recordCountBody"
        }
    ];
    
    
        // //console.log({dataBody});
        // //console.log(assortmentCompetitorParams[0].body);
    
        delete dataBody.competingStores;
        delete dataBody.competitor_store;
        delete dataBody.competitor_store_mesh_id;
        delete dataBody.filter_category_code;
        delete dataBody.filter_category_code_list;
        delete dataBody.mesh_id;
        delete dataBody.view_category_code;
    
        const requests = assortmentCompetitorParams.map(params => {
            const {
                api,
                body,
                name=false,
            } = params;
            return getNdJsonFetch(token, history, `https://${API_URL}/${api}`, name=="recordCountBody" ? {...body} : {...dataBody, ...body}, 0);
        });
        const results = await Promise.all(requests)
            .then(res => res);
    
        // //console.log({results});
    
        const rows = results[0];
        const recordsCount = [...(results?.[1] || [])]?.reduce((o,rc)=>{return o + (parseInt(rc?.number_of_total_customers) ?? 0)},0);
        const tableColumns = ['ストアチェーン名', '一人あたり買上点数', '一人あたり買上数量'];
        const tableRows = [];
        rows.forEach((row) => {
            const {
                chain_id,
                unique_customer_items,
                number_of_items_purchased,
                number_of_unique_customers,
            } = row;
    
            const chainName = getChainName({
                allStoreNames,
                chain_id,
            });
    
            tableRows.push([
                chainName,
                unique_customer_items/number_of_unique_customers,
                number_of_items_purchased/number_of_unique_customers
            ]);
        });
    
        const originalDataFrame = new dfd.DataFrame(tableRows, {columns: tableColumns});
        return {
            tabOptions : null,
            originalDataFrame,
            recordsCount:recordsCount ?? 0
        }
    }
    catch {
        return {
            error: true,
            message: '処理が失敗しました。管理者を連絡してください。'
        }
    }
    
}
const getCategorySum = (results, allStoreNames) => {
    const categorySum = {};
    results.forEach(r => {
        const {
            chain_id,
        } = r;
        const chain_label = getChainName({
            allStoreNames,
            chain_id,
        });
        if(chain_label && categorySum[chain_label] === undefined){
            categorySum[chain_label] = [];
        }
        categorySum[chain_label] && categorySum[chain_label].push(r);
    });
    return categorySum;
}
const getPricingCompetitorColumns = (columns, primaryKey, defaultWidth=200, valueFormatter=(params)=>{
    const {
        value=null,
    } = params;
    if(!value || value === NOT_A_NUMBER){
        return value;
    }
    return (value).toLocaleString();
},title="") => {
    const percentCellFormatter = (params) => {
        const {
            value,
        } = params;
        if(!value || value === NOT_A_NUMBER){
            return '0 %';
        }
        return `${value>0 && title.includes("YOY") ? "+":""}${value} %`;

    };
   
    return columns.filter(name => name !== 'id').map((name, i) => {
        const isSplitCell   = name.indexOf(primaryKey) > -1 && i > 0;
        const isPercentCell = name.indexOf('ウォレットシェア') > -1;
        const type          = ['カテゴリ'].includes(name) ? "string" : "number";
        return {
            field       : name,
            headerName  : name,
            width       : name === 'カテゴリ' ? 400 : defaultWidth,
            isSplitCell : isSplitCell,
            type        : type,
            valueFormatter : type === 'number' ? isPercentCell ? percentCellFormatter : valueFormatter : null,
        }
    });
}
const getPricingCompetitorRows = (rows, columns) => {
    return rows.map(row => {
        const params = {};
        row.forEach((val, i) => params[columns[i]] = val);
        return params;
    });
}
const getPricingCompetitorObject = (props) => {
    const {
        results=[],
        categorySum={},
        flatCategories=[],
        categoryLevel=4,
        allStoreNames=[],
        primaryKey,
        type='',
        filterCategoryKeys=null,
    } = props;
    const colByStore = {
        '顧客単価' : {}, //Customer unit price
        'ウォレットシェア' : {}, // Wallet share
    }
    const categoryKeys = [];
    const chainKeys    = [primaryKey];
    results.forEach(row => {
        const {
            chain_id,
            number_of_unique_customers=null,
            total_sales=null,
        } = row;
        const chain_label = getChainName({
            allStoreNames,
            chain_id,
        });
        const view_category_code = row[`category_level${categoryLevel}`];
        const categoryName = getCategoryName({
            category_level_key:`category_level${categoryLevel}`,
            categories : flatCategories.map(r=> {return {...r,value:parseInt(r.value)}}), 
            val        : view_category_code,
        });
        if(filterCategoryKeys === null || filterCategoryKeys.includes(categoryName)){
            const walletShare = view_category_code ? getCategoryValue({
                chainName : chain_label,
                categorySum,
                categoryLevel : categoryLevel,
                category_code : view_category_code,
                uniqueKey : 'total_sales',
            })  : NOT_A_NUMBER;
            const averageCustomerSpend = total_sales !== null && number_of_unique_customers !== null ? Number((total_sales / number_of_unique_customers).toFixed(2)) : NOT_A_NUMBER;

            if(colByStore['顧客単価'][chain_label] === undefined){ 
                         // Customer unit price
                colByStore['顧客単価'][chain_label] = {}; // Customer unit price
                colByStore['ウォレットシェア'][chain_label] = {}; // Wallet share
            }
            colByStore['顧客単価'][chain_label][categoryName] = averageCustomerSpend; // Customer unit price
            colByStore['ウォレットシェア'][chain_label][categoryName] = walletShare; // Wallet share

            if(!categoryKeys.includes(categoryName)){
                categoryKeys.push(categoryName);
            }
            if(!chainKeys.includes(chain_label)){
                chainKeys.push(chain_label);
            }
        }
    });
    return {
        colByStore,
        categoryKeys,
        chainKeys,
    }
}
const getPricingCompetitorPriceRangeObject = (props) => {
    const {
        results=[],
        flatCategories=[],
        categoryLevel=4,
        valueGetter=(params)=>{
            return params?.total_sales || null;
        },
        filterCategoryKeys,
    } = props;
    const colByStore = {
        '下位価格帯' : {},
        '中位価格帯' : {},
        '上位価格帯' : {},
    }
    const categoryKeys = [];
    const priceRangeNames = {
        '0-33.3%tile'    : '下位価格帯',
        '33.3-66.6%tile' : '中位価格帯',
        '66.6-100%tile'  : '上位価格帯',
    }
    const subjectOfComparisonNames = {
        'own' : '自社',
        'competitors' : '他社',
    }
    results.forEach(row => {
        const {
            own_vs_competitors='own',
            price_range_bucket,
        } = row;
        const value              = valueGetter(row);
        const rangeName          = priceRangeNames?.[price_range_bucket] || null;
        const comparisonName     = subjectOfComparisonNames?.[own_vs_competitors] || null;
        const view_category_code = row[`category_level${categoryLevel}`];
        const categoryName       = getCategoryName({
            category_level_key:`category_level${categoryLevel}`,
            categories : flatCategories.map(r=> {return {...r,value:parseInt(r.value)}}), 
            val        : view_category_code,
        });
        if(filterCategoryKeys === null || filterCategoryKeys.includes(categoryName)){
            if(colByStore[rangeName][comparisonName] === undefined){
                colByStore[rangeName][comparisonName] = {};
            }
            colByStore[rangeName][comparisonName][categoryName] = value;
    
            if(!categoryKeys.includes(categoryName)){
                categoryKeys.push(categoryName);
            }
        }

    });
    return {
        colByStore,
        categoryKeys,
    }
}
const getPricingCompetitorAverage = (params, storeLength) => {
    const averageParams = {};
    ['顧客単価', 'ウォレットシェア'].forEach(key => {
        if(averageParams[key] === undefined){
            averageParams[key] = {};
        }
        Object.keys(params[key]).forEach(storeName => {

            Object.keys(params[key][storeName]).forEach(itemName => {
                if(averageParams[key][itemName] === undefined){
                    averageParams[key][itemName] = [];
                }
                averageParams[key][itemName].push(params[key][storeName][itemName]);
            });
        });
    });
    Object.keys(averageParams).forEach(key => {
        Object.keys(averageParams[key]).forEach(itemName => {
            averageParams[key][itemName] = averageParams[key][itemName].reduce((sum, current) => sum += current, 0) / storeLength;
        });
    })

    return averageParams;
}
const getPricingCompetitorFilterCategories = (params, filterCondition, averageParams) => {
    if(filterCondition['顧客単価'] === null && filterCondition['ウォレットシェア'] === null){
        return null;
    }
    const filterCategory = {};
    let checkCount = 0;
    Object.keys(filterCondition).forEach(key => {
        if(filterCondition[key]){
            checkCount += 1;
            const {
                storeName,
                type,
                value,
                comparison,
            } = filterCondition[key];
            const target = params?.[key]?.[storeName] || {};
            const matchCategories = Object.keys(target).filter(itemName => {
                const val = target[itemName];
                if(type === 'custom'){
                    if(comparison === 'below'){
                        return Boolean(val < value);
                    }
                    return Boolean(val > value);
                }
                if(type === 'below'){
                    return Boolean(val < averageParams[itemName]);
                }
                return Boolean(val > averageParams[itemName]);
            });
            matchCategories.forEach(itemName => {
                if(filterCategory[itemName] === undefined){
                    filterCategory[itemName] = 0;
                }
                filterCategory[itemName] += 1;
            })
            
        }
    });
    
    return Object.keys(filterCategory).filter(itemName => filterCategory[itemName] === checkCount);
}
const getPricingCompetitor = async (props) => {
    try {
        console.clear()
        const {
            token,
            history,
            currentFormValues,
            filterOptions,
        } = props;
        const {
            start_date = moment().add(-1, 'month'),
                end_date = moment(),
                store = [],
                competingStores = [],
                categories = [],
                categoryLevel = 4,
                conditions,
        } = currentFormValues?.current || {};
        const startDate = moment(start_date).format('YYYY-MM-DD');
        const endDate = moment(end_date).format('YYYY-MM-DD');
        const allStoreNames = getAllStoreNames(store, competingStores);
        const flatCategories = getFlatCategoriesList(categories);
        const lastYearDates = getLastYearDates(startDate, endDate, "daily")

        const lastYearStartDate = lastYearDates.startDate;
        const lastYearEndDate = lastYearDates.endDate;
        const dataBody = getSubmitValues({
            filterOptions,
            currentFormValues,
        });

        const selectStores = [...new Set(
            [...store, ...competingStores]
            .filter(data => data.checked)
            .map(data => {
                const {
                    chain_name = null,
                        competitor_store_company_name = null,
                } = data?.value || {};
                return chain_name || competitor_store_company_name || null;
            }).filter(name => name !== null && name !== ' ')
        )];

        const categoryList = {
            level1: [],
            level2: [],
            level3: [],
            level4: [],
        }
        categoryList[`level${categoryLevel}`] = dataBody.filter_category_code_list;

        let finaleCategoriesData = selectedCategoryLength(categories, categoryLevel)
        if (finaleCategoriesData?.lenghtOfCategories === 0) {
            return {
                error: true,
                message: "選択した条件ではデータが見つかりません！"
            }
        }

        const pricingCompetitorParams = [{
            api: "purchases",
            body: {
                start_date: startDate,
                end_date: endDate,
                store_info: [...dataBody.store_info, ...dataBody.competitor_store],
                results_per: ["chain_id", `category_level${categoryLevel}`],
                source: "pasha",
                metric: ["count", "sum"],
                unit: ["customer", "price"],
                categories: finaleCategoriesData.data,
            },
        }, {
            api: "purchases",
            body: {
                start_date: lastYearStartDate,
                end_date: lastYearEndDate,
                store_info: [...dataBody.store_info, ...dataBody.competitor_store],
                results_per: ["chain_id", `category_level${categoryLevel}`],
                source: "pasha",
                metric: ["count", "sum"],
                unit: ["customer", "price"],
                categories: finaleCategoriesData.data,
            },
        }, {
            api: "purchases",
            body: {
                start_date: lastYearStartDate,
                end_date: lastYearEndDate,
                store_info: [...dataBody.store_info, ...dataBody.competitor_store],
                results_per: ["own_vs_competitors", `category_level${categoryLevel}`, "price_range"],
                source: "pasha",
                metric: ["count", "sum"],
                unit: ["product", "price"],
                categories: finaleCategoriesData.data,
            },
        }, {
            api: "purchases",
            body: {
                start_date: lastYearStartDate,
                end_date: lastYearEndDate,
                store_info: [...dataBody.store_info, ...dataBody.competitor_store],
                results_per: [`category_level${categoryLevel}`, "price_range"],
                source: "pasha",
                metric: ["count"],
                unit: ["product"],
                categories: finaleCategoriesData.data,
            },
        },
        
        // records count
        {
            api: "purchases",
            body: {
                start_date: startDate,
                end_date: endDate,
                store_info: [...dataBody.store_info, ...dataBody.competitor_store],
                source: "pasha",
                metric: ["count"],
                unit     : ["receipt"],
                categories: finaleCategoriesData.data,
            },
            name:"recordCountBody"
        }
    
    ];
        

        delete dataBody.competingStores;
        delete dataBody.competitor_store;
        delete dataBody.competitor_store_mesh_id;
        delete dataBody.filter_category_code;
        delete dataBody.filter_category_code_list;
        delete dataBody.mesh_id;
        delete dataBody.view_category_code;

        const requests = pricingCompetitorParams.map(params => {
            const {
                api,
                body,
                name=false,
            } = params;
            return getNdJsonFetch(token, history, `https://${API_URL}/${api}`, name=="recordCountBody" ? {...body} : {
                ...dataBody,
                ...body
            }, 0, null);
        });
        const results = await Promise.all(requests)
            .then(res => res);

        const hasError = checkResponseError(results);
        if (hasError) {
            return hasError;
        }
        //const categorySum = getCategorySum(results[0], allStoreNames);

        const primaryKey = API_ENV == 'demo' ? '企業1' : dataBody.store_info[0].chain_id == 1157 ? '西友' : '東急ストア' // Tokyu store
        const {
            colByStore: avgCols,
        } = getPricingCompetitorObject({
            results: results[0],
            categorySum: getCategorySum(results[0], allStoreNames),
            flatCategories,
            categoryLevel,
            allStoreNames,
            primaryKey,
        });
        const averageParams = getPricingCompetitorAverage(avgCols, selectStores.length);

        const filterCondition = {
            '顧客単価': null, //Customer unit price
            'ウォレットシェア': null, // Wallet share
        }
        if (conditions.averageCustomerSpend.selectedStore) {
            filterCondition['顧客単価'] = { //Customer unit price
                storeName: conditions.averageCustomerSpend.selectedStore,
                type: conditions.averageCustomerSpend.type,
                value: conditions.averageCustomerSpend.value,
                comparison: conditions.averageCustomerSpend.comparison,
            }
        }
        if (conditions.walletShare.selectedStore) {
            filterCondition['ウォレットシェア'] = { //Wallet share
                storeName: conditions.walletShare.selectedStore,
                type: conditions.walletShare.type,
                value: conditions.walletShare.value,
                comparison: conditions.walletShare.comparison,
            }
        }
        const filterCategoryKeys = getPricingCompetitorFilterCategories(avgCols, filterCondition, averageParams);

        const {
            colByStore,
            categoryKeys,
            chainKeys,
        } = getPricingCompetitorObject({
            results: results[0],
            categorySum: getCategorySum(results[0], allStoreNames),
            flatCategories,
            categoryLevel,
            allStoreNames,
            primaryKey,
            filterCategoryKeys,
        });

        const {
            colByStore: prevYearColByStore,
            categoryKeys: prevYearCategoryKeys,
            chainKeys: prevYearChainKeys,
        } = getPricingCompetitorObject({
            results: results[1],
            categorySum: getCategorySum(results[1], allStoreNames),
            flatCategories,
            categoryLevel,
            allStoreNames,
            primaryKey,
            filterCategoryKeys,
        });

        const {
            colByStore: priceRangeColByStore,
            categoryKeys: priceRangeCategoryKeys,
        } = getPricingCompetitorPriceRangeObject({
            results: results[2],
            //categorySum : getCategorySum(results[2], allStoreNames),
            flatCategories,
            categoryLevel,
            valueGetter: (params) => {
                const {
                    number_of_unique_products = null,
                        total_sales = null,
                } = params;
                return number_of_unique_products && total_sales ? Math.round(total_sales / number_of_unique_products) : null;
            },
            filterCategoryKeys,
        });

        const {
            colByStore: priceRangeTotalColByStore,
            categoryKeys: priceRangeTotalCategoryKeys,
        } = getPricingCompetitorPriceRangeObject({
            results: results[2],
            //        categorySum : getCategorySum(results[2], allStoreNames),
            flatCategories,
            categoryLevel,
            filterCategoryKeys,
        });

        const {
            colByStore: priceRangeCountColByStore,
            categoryKeys: priceRangeCountCategoryKeys,
        } = getPricingCompetitorPriceRangeObject({
            results: results[3],
            flatCategories,
            categoryLevel,
            valueGetter: (params) => {
                const {
                    number_of_unique_products = null,
                } = params;
                return number_of_unique_products || null;
            },
            filterCategoryKeys,
        });
        const recordsCount = [...(results?.[4] || [])]?.reduce((o,rc)=>{return o + (parseInt(rc?.number_of_total_customers) ?? 0)},0) ?? 0;

        if (!chainKeys.includes(primaryKey)) {
            return {
                tabOptions: null,
                originalDataFrame: null,
                error: {
                    severity: 'warning',
                    message: PRIMARY_STORE_NODATA_TEXT
                }
            }
        }


        const competitiveComparisonColumns = ['id', 'カテゴリ']; //category
        const competitiveComparisonTable = [];
        categoryKeys.forEach((categoryName, i) => {
            const cells = [i, categoryName];
            Object.keys(colByStore).forEach(colName => {
                const cols = colByStore[colName];
                chainKeys.forEach(storeName => {
                    if (i === 0) competitiveComparisonColumns.push(`${storeName} : ${colName}`);
                    const val = cols[storeName][categoryName] || null;
                    cells.push(val);
                });
            });
            competitiveComparisonTable.push(cells);
        })

        const yoyCompetitiveComparisonColumns = ['id', 'カテゴリ']; //category
        const yoyCompetitiveComparisonTable = [];
        const yoyCategoryKeys = [...new Set(categoryKeys.concat(prevYearCategoryKeys))];
        yoyCategoryKeys.forEach((categoryName, i) => {
            const cells = [i, categoryName];

            Object.keys(colByStore).forEach(colName => {
                const cols     = colByStore?.[colName] || {};
                const prevCols = prevYearColByStore?.[colName] || {};
                
                chainKeys.forEach(storeName => {
                    if(i === 0) yoyCompetitiveComparisonColumns.push(`${storeName} : ${colName}`);
                    const val     = cols[storeName]?.[categoryName] || null;
                    const prevVal = prevCols[storeName]?.[categoryName] || null;
                    const yoyVal  =  (val && val == 0 || !val ) ? -100 : (!prevVal || prevVal == 0 ) ? NOT_A_NUMBER : val !== null && prevVal !== null ? Number((((val / prevVal) -1) * 100).toFixed(2)) : 0;
                    cells.push(isNaN(yoyVal) ? NOT_A_NUMBER : yoyVal);
                });
            });
            yoyCompetitiveComparisonTable.push(cells);
        })

        const priceRangeCompetitiveComparisonColumns = ['id', 'カテゴリ']; //category
        const priceRangeCompetitiveComparisonTable = [];
        const priceRangeSalesCompetitiveComparisonColumns = ['id', 'カテゴリ']; //category
        const priceRangeSalesCompetitiveComparisonTable = [];
        priceRangeCategoryKeys.forEach((categoryName, i) => {
            const cells = [i, categoryName];
            const salesCells = [i, categoryName];
            Object.keys(priceRangeColByStore).forEach(colName => {
                const cols = priceRangeColByStore[colName];
                ['自社', '他社'].forEach(comparisonName => {
                    // [Company, other company]
                    if (i === 0) {
                        const columnText = comparisonName === '自社' ? '自社価格' : '競合差';
                        // company ? Company price : Conflict difference
                        priceRangeCompetitiveComparisonColumns.push(`${colName} : ${columnText}`);
                    }
                    const val = cols?.[comparisonName]?.[categoryName] || null;
                    if (val !== null && comparisonName === '他社') { // other company
                        const ownVal = cols['自社']?.[categoryName] || null;
                        // Company
                        cells.push(ownVal === null ? 0 : val - ownVal);
                    } else {
                        cells.push(val);
                    }
                });
            });
            Object.keys(priceRangeTotalColByStore).forEach(colName => {
                const cols = priceRangeTotalColByStore[colName];
                ['自社'].forEach(comparisonName => {
                    // Company
                    if (i === 0) {
                        priceRangeSalesCompetitiveComparisonColumns.push(colName);
                    }
                    const val = cols?.[comparisonName]?.[categoryName] || null;
                    salesCells.push(val);
                });
            });
            priceRangeCompetitiveComparisonTable.push(cells);
            priceRangeSalesCompetitiveComparisonTable.push(salesCells);
        })

        const priceRangeSalesCountCompetitiveComparisonColumns = ['id', 'カテゴリ']; //category
        const priceRangeSalesCountCompetitiveComparisonTable = [];
        priceRangeCountCategoryKeys.forEach((categoryName, i) => {
            const cells = [i, categoryName];
            Object.keys(priceRangeCountColByStore).forEach(colName => {
                const cols = priceRangeCountColByStore[colName];
                ['自社'].forEach(comparisonName => {
                    // Company
                    if (i === 0) {
                        priceRangeSalesCountCompetitiveComparisonColumns.push(colName);
                    }
                    const val = cols ?.[comparisonName]?.[categoryName] || null;
                    cells.push(val);
                });
            });
            priceRangeSalesCountCompetitiveComparisonTable.push(cells);
        })

        return {
            tabOptions: null,
            originalDataFrame: [{
                title: '競合比較', // Competitive comparison
                tableColumns: getPricingCompetitorColumns(competitiveComparisonColumns, primaryKey, 200, (params) => {
                    const {
                        value = null,
                    } = params;
                    if (!value || value === NOT_A_NUMBER) {
                        return 0;
                    }
                    return (value).toLocaleString();
                }),
                tableRows: getPricingCompetitorRows(competitiveComparisonTable, competitiveComparisonColumns),
            }, {
                title: '競合比較 YOY', // Competitive comparison YOY
                tableColumns: getPricingCompetitorColumns(yoyCompetitiveComparisonColumns, primaryKey, 200, (params) => {
                    const {
                        value,
                    } = params;
                    if (!value || value === NOT_A_NUMBER) {
                        return NOT_A_NUMBER;
                    }
                    return `${value<=0? "":"+"}${value} %`;

                },
                "競合比較 YOY"
                ),
                tableRows: getPricingCompetitorRows(yoyCompetitiveComparisonTable, yoyCompetitiveComparisonColumns),
            }, {
                title: '価格帯 競合比較', // Price range competition comparison
                tableColumns: getPricingCompetitorColumns(priceRangeCompetitiveComparisonColumns, primaryKey, 140),
                tableRows: getPricingCompetitorRows(priceRangeCompetitiveComparisonTable, priceRangeCompetitiveComparisonColumns),
            }, {
                title: '価格帯別売上合計', // Total sales by price range
                tableColumns: getPricingCompetitorColumns(priceRangeSalesCompetitiveComparisonColumns, primaryKey),
                tableRows: getPricingCompetitorRows(priceRangeSalesCompetitiveComparisonTable, priceRangeSalesCompetitiveComparisonColumns),
            }, {
                title: '価格帯別販売台数', // Price range sales volume
                tableColumns: getPricingCompetitorColumns(priceRangeSalesCountCompetitiveComparisonColumns, primaryKey),
                tableRows: getPricingCompetitorRows(priceRangeSalesCountCompetitiveComparisonTable, priceRangeSalesCountCompetitiveComparisonColumns),
            }],
            recordsCount : recordsCount ?? 0
        }
    } catch {
        return {
            error: true,
            message: '処理が失敗しました。管理者を連絡してください。'
        }
    }

}

const getPricingSummary = async (props) => {
    try {
        const {
            token, 
            history, 
            currentFormValues,
            filterOptions,
        } = props;
        const {
            start_date     = moment().add(-1, 'month'),
            end_date       = moment(),
            store          = [],
            competingStores= [],
            categoryLevel  = 4,
        } = currentFormValues?.current || {};
        const startDate    = moment(start_date).format('YYYY-MM-DD');
        const endDate      = moment(end_date).format('YYYY-MM-DD');
        const allStoreNames= getAllStoreNames(store, competingStores);
        const dataBody     = getSubmitValues({
            filterOptions, 
            currentFormValues,
        });
    
        const categoryList = {
            level1: [],
            level2: [],
            level3: [],
            level4: [],
        }
        categoryList[`level${categoryLevel}`] = dataBody.filter_category_code_list;
    
        const pricingSummayParams = [{
            api : "purchases",
            body : {
                start_date : startDate,
                end_date   : endDate,
                store_info : [...dataBody.store_info, ...dataBody.competitor_store],
                results_per: ["chain_id"],
                source     : "pasha",
                metric     : ["count", "sum"],
                unit       : ["customer", "price"],
                categories : categoryList,
            },
        },
        {
            //  records count
            api : "purchases",
            body : {
                start_date : startDate,
                end_date   : endDate,
                store_info : [...dataBody.store_info, ...dataBody.competitor_store],
                source     : "pasha",
                metric     : ["count"],
                unit     : ["receipt"],
                categories : categoryList,
            },
            name:"recordCountBody"
        }
    ];
    
        // //console.log({dataBody});
        // //console.log(assortmentCompetitorParams[0].body);
    
        delete dataBody.competingStores;
        delete dataBody.competitor_store;
        delete dataBody.competitor_store_mesh_id;
        delete dataBody.filter_category_code;
        delete dataBody.filter_category_code_list;
        delete dataBody.mesh_id;
        delete dataBody.view_category_code;
    
        const requests = pricingSummayParams.map(params => {
            const {
                api,
                body,
                name=false
            } = params;
            return getNdJsonFetch(token, history, `https://${API_URL}/${api}`, name == "recordCountBody" ? {...body} : {...dataBody, ...body}, 0);
        });
        const results = await Promise.all(requests)
            .then(res => res);
    
        // //console.log({results});
    
        const rows = results[0];
        const recordsCount = [...(results?.[1] || [])]?.reduce((o,rc)=>{return o + (parseInt(rc?.number_of_total_customers) ?? 0)},0) ?? 0;
        const tableColumns = ['ストアチェーン名', 'ウォレットシェア', '顧客単価', '売上合計'];
        const totalSalesSum = rows.reduce((sum, r) => sum + (parseInt( r?.total_sales || 0) ), 0);
        const tableRows = [];
        rows.forEach((row) => {
            const {
                chain_id,
                total_sales = 0,
                number_of_unique_customers,
            } = row;
    
            const chainName = getChainName({
                allStoreNames,
                chain_id,
            });
    
            tableRows.push([
                chainName,
                String(Number((( parseInt(total_sales) || 0 ) /totalSalesSum * 100).toFixed(2)))+' %',
                Number((( parseInt(total_sales) || 0 )/( Number(number_of_unique_customers || 1) )).toFixed(2)),
                parseInt (total_sales || 0)
            ]);
        });
        const numberOfRows = tableRows.length
        tableRows.push([
            '合計',
            totalSalesSum > 0 ? '100 %' : '0 %',
            tableRows.reduce((sum, r) => sum + r[2], 0)/numberOfRows,
            totalSalesSum
        ]);
    
        const originalDataFrame = new dfd.DataFrame(tableRows, {columns: tableColumns});
        return {
            tabOptions : null,
            originalDataFrame,
            recordsCount : recordsCount ?? 0
        }
    }
    catch {
        return {
            error: true,
            message: '処理が失敗しました。管理者を連絡してください。'
        }
    }
}

const getPricingSingleItemComparison = async (props) => {
    try {
        const {
            token, 
            history, 
            currentFormValues,
            filterOptions,
            selectedTargets,
        } = props;
        const {
            //datePeriodType = [],
            //segment        = [],
            start_date     = moment().add(-1, 'month'),
            end_date       = moment(),
            store          = [],
            competingStores= [],
            categories     = [],
            categoryLevel  = 4,
        } = currentFormValues?.current || {};
        ////console.log([...new Set(store.map(s => `${s.value.chain_id}:"${s.value.chain_name}"`))]);
        //const periodType   = datePeriodType.find(option => option.selected).value;
        //const dateFormat   = ['monthly', 'monthly_split'].includes(periodType) ? 'YYYY-MM' : 'YYYY-MM-DD';
        //const segmentNames = segment || [];
        const startDate    = moment(start_date).format('YYYY-MM-DD');
        const endDate      = moment(end_date).format('YYYY-MM-DD');
        const allStoreNames= getAllStoreNames(store, competingStores);
        const flatCategories = getFlatCategoriesList(categories);
        const dataBody     = getSubmitValues({
            filterOptions, 
            currentFormValues,
        });
    
        const category_level_key = `category_level${categoryLevel}`;
        const categoryList = {
            level1: [],
            level2: [],
            level3: [],
            level4: [],
        }
        categoryList[`level${categoryLevel}`] = dataBody.filter_category_code_list;

        let finaleCategoriesData= selectedCategoryLength(categories,categoryLevel)
        if( finaleCategoriesData?.lenghtOfCategories === 0){
            return{
                error:true,
                message:"選択した条件ではデータが見つかりません！"
            }
        }
    
        const pricingSingleItemParams = [{
            api : "purchases",
            body : {
                start_date : startDate,
                end_date   : endDate,
                store_info : [...dataBody.store_info, ...dataBody.competitor_store],
                results_per: [
                    "chain_id",
                    "product",
                    category_level_key,
                ],
                source     : "pasha",
                metric     : ["count", "sum"],
                unit       : ["items", "price"],
                categories : finaleCategoriesData.data,
            },
        },{
            // records count
            api : "purchases",
            body : {
                start_date : startDate,
                end_date   : endDate,
                store_info : [...dataBody.store_info, ...dataBody.competitor_store],
                source     : "pasha",
                metric     : ["count"],
                unit       : ["receipt"],
                categories : finaleCategoriesData.data
            },
            name:"recordCountBody"
        }];
    
        delete dataBody.categories;
        delete dataBody.competingStores;
        delete dataBody.competitor_store;
        delete dataBody.competitor_store_mesh_id;
        delete dataBody.filter_category_code;
        delete dataBody.filter_category_code_list;
        delete dataBody.mesh_id;
        delete dataBody.results_per;
        delete dataBody.view_category_code;
    
        const requests = pricingSingleItemParams.map(params => {
            const {
                api,
                body,
                name=false
            } = params;
            //console.log("request data fo rpricing/singleItem :", {...dataBody, ...body})
            return getNdJsonFetch(token, history, `https://${API_URL}/${api}`,name=="recordCountBody" ? {...body} : {...dataBody, ...body}, 0);
        });
        const results = await Promise.all(requests)
            .then(res => res);
        //console.log({results})
        console.log("results :", results)
  
        const rows = results[0]?.sort((a, b) => a?.number_of_items_purchased < b?.number_of_items_purchased ? 1 : -1);
        //console.log({rows})
    
        const maxNumOfProductsPerCategory = 30;
        const byStores = {};
        results[0]?.forEach(row => {
            const {
                chain_id,
                [category_level_key] : view_category_code,
                product,
                number_of_unique_products=null,
                number_of_items_purchased,
                total_sales,
            } = row;
            const categoryName = getCategoryName({
                category_level_key:category_level_key,
                categories : flatCategories.map(r => {return {...r,value:parseInt(r?.value) }}), 
                val        : view_category_code,
            });
    
            const chainName = getChainName({
                allStoreNames,
                chain_id,
            });
    
            if(byStores[chainName] === undefined){
                byStores[chainName] = {};
            }
            if(byStores[chainName][categoryName] === undefined){
                byStores[chainName][categoryName] = [];
            }
    
            const num = byStores[chainName][categoryName].length;
            if(num >= maxNumOfProductsPerCategory){
                return;
            }
    
            byStores[chainName][categoryName].push([
                //view_category_code,
                //categoryName,
                byStores[chainName][categoryName].length+1,
                product,
                number_of_items_purchased,
                total_sales/number_of_items_purchased,
                0,
            ]);
        });
    
        const primaryKey = getPrimaryChainName(dataBody, allStoreNames);
        const secondaryKeys = Object.keys(byStores).filter(key => key !== primaryKey);
    
        if(byStores[primaryKey]){
            Object.keys(byStores[primaryKey]).forEach(categoryName => {
                byStores[primaryKey][categoryName].forEach(own => {
                    secondaryKeys.forEach(chainName => {
                        if(byStores[chainName][categoryName]) {
                            const competitor = byStores[chainName][categoryName].find(c => c[1] === own[1]);
                            if(competitor) {
                                const ratio = Number(((own[3] - competitor[3])/own[3] * 100).toFixed(2));
                                competitor[4] = ratio;
                                own[4] = ratio;
                            }
                        }
                    });
                });
            });
        }
        ////console.log({byStores})
    
        let categoryKeys = [];
        Object.keys(byStores).map(key => {
            categoryKeys = categoryKeys.concat(Object.keys(byStores[key]));
        });
        categoryKeys = [...new Set(categoryKeys)].sort();
    
        ////console.log({primaryKey})
        ////console.log({secondaryKeys})
        ////console.log({categoryKeys})
    
        let tabByRows = new Array([...secondaryKeys, primaryKey].length).fill(new Array());
        categoryKeys.map(categoryKey => {
            const maxArrayNum = (Object.keys(byStores).map(key => byStores[key][categoryKey]?.length || 0)).reduce((a, b) => Math.max(a, b));
            ([primaryKey].concat(secondaryKeys)).map((storeKey, storeIdx) => {
                const blankArray = new Array(maxArrayNum)
                                    .fill([categoryKey, null, null, null, null, 0], 0, 1)
                                    .fill(["temp_category_name", null, null, null, null, 0], 1);
                if(byStores[storeKey] && byStores[storeKey][categoryKey] !== undefined){
                    byStores[storeKey][categoryKey]?.map((row, i) => {
                        const [
                            index,
                            product,
                            number_of_items_purchased,
                            unit_price,
                            comparison,
                        ] = row;
                        const viewCategoryName = (i === 0) ? categoryKey : "temp_category_name";
                        blankArray[i] = [viewCategoryName, index, product, number_of_items_purchased, unit_price ,comparison];
                    });
                }
                tabByRows[storeIdx] = tabByRows[storeIdx].concat(blankArray);
            });
        });
        ////console.log({tabByRows})
    
        const tableRows = [];
        tabByRows[0].forEach((_, j) => {
            let tmp = [];
            tabByRows.forEach((t, i) => {
                tmp = tmp.concat(t[j]);
            });
            tableRows.push(tmp);
        })
        ////console.log({tableRows})
    
        //delete blank rows
        tabByRows = tabByRows.map(tab => {
            return tab.filter(r => r[1]);
        });
        ////console.log({tabByRows})
    
        const columnKeys = ([primaryKey].concat(secondaryKeys)).map((key, i) => {
            return [`${key} : カテゴリ`, `${key} : #`, `${key} : 商品名`, `${key} : 売上件数`, `${key} : 商品単価`, `${key} : hidden__自店との比較`];
        }).flat();
        ////console.log({byStores});
        ////console.log({tableRows}, {columnKeys});
        //const originalDataFrame = new dfd.DataFrame(tableRows, {columns: columnKeys});
        const tabByTable = {
            tabName : [primaryKey, ...secondaryKeys],
            single : {
                rows    : tableRows,
                columns : columnKeys,
            },
            tab : tabByRows.map((rows, i) => {
                return {
                    rows,
                    columns : [`カテゴリ`, `#`, `商品名`, `売上件数`, `商品単価`, `hidden__自店との比較`],
                }
            }),
        }

        const recordsCount = [...(results?.[1] || [])]?.reduce((o,rc)=>{return o + (parseInt(rc?.number_of_total_customers) ?? 0)},0) ?? 0;
        return {
            tabOptions : null,
            originalDataFrame : tabByTable,
            recordsCount : recordsCount ?? 0
        }
    }
    catch {
        return {
            error: true,
            message: '処理が失敗しました。管理者を連絡してください。'
        }
    }
}

const getPricingBoxPlot = async (props) => {
    console.clear()
    try {
        const {
            token, 
            history, 
            currentFormValues,
            filterOptions,
            selectedTargets,
        } = props;
        const {
            datePeriodType = [],
            segment        = [],
            start_date     = moment().add(-1, 'month'),
            end_date       = moment(),
            competingStores= [],
            categories     = [],
            store : storeNames = [],
            categoryLevel = 4,
        } = currentFormValues?.current || {};
    
        const defaultCategoryLevel = ['category_level1', 'category_level2', 'category_level3', 'category_level4'].slice(0, categoryLevel);
        
        const resultsPer = ['chain_id'].concat(defaultCategoryLevel);
        const compResultPer = ['own_vs_competitors'].concat(defaultCategoryLevel);
        const flatCompetingStore = getFlatArray(competingStores);
        const periodType   = datePeriodType.find(option => option.selected).value;
        const dateFormat   = ['monthly', 'monthly_split'].includes(periodType) ? 'YYYY-MM' : 'YYYY-MM-DD';
        const segmentNames = segment || [];
        const startDate    = moment(start_date).format('YYYY-MM-DD');
        const endDate      = moment(end_date).format('YYYY-MM-DD');
        const lastYearDates = getLastYearDates(startDate, endDate, periodType)
    
        const lastYearStartDate = lastYearDates.startDate;
        const lastYearEndDate   =lastYearDates.endDate;
    
        const dataBody     = getSubmitValues({
            filterOptions, 
            currentFormValues,
        });
        dataBody.results_per = resultsPer
        dataBody.metric      = ['min', 'max', 'median', '25%tile', '75%tile'];
        dataBody.unit        = ['price'];
        dataBody.source      = 'pasha';

        let finaleCategoriesData= selectedCategoryLength(categories,categoryLevel)
        if( finaleCategoriesData?.lenghtOfCategories === 0){
            return{
                error:true,
                message:"選択した条件ではデータが見つかりません！"
            }
        }

     
        const pricingBoxPlotParams = [{
            start_date : startDate,
            end_date   : endDate,
        },{
            start_date : startDate,
            end_date   : endDate,
            results_per : compResultPer,
            store_info : [...dataBody.competitor_store],
        },{
            start_date : startDate,
            end_date   : endDate,
            results_per : resultsPer,
            metric      : ['sum'],
        },{
            start_date : startDate,
            end_date   : endDate,
            store_info : [...dataBody.competitor_store],
            results_per : compResultPer,
            metric      : ['sum'],
        },
        {
            //  records count
            api : "purchases",
            body : {
                start_date : startDate,
                end_date   : endDate,
                store_info : [...dataBody.store_info, ...dataBody.competitor_store],
                source     : "pasha",
                metric     : ["count"],
                unit       : ["receipt"],
                categories : finaleCategoriesData.data,
            },
            name:"recordCountBody"
        }
    ]


        
        dataBody.categories = finaleCategoriesData.data;
        delete dataBody.competitor_store_mesh_id
        delete dataBody.mesh_id
    
        const requests = pricingBoxPlotParams.map(params => {
            //console.log("resquest for box-plot :", {...dataBody, ...params} );
            return getNdJsonFetch(token, history, `https://${API_URL}/purchases`, params?.name == "recordCountBody" ? {...params.body} : {...dataBody, ...params}, 0);
        });
        const results = await Promise.all(requests)
            .then(res => res);

        const chartOptions = createBoxPlotOptions(results.filter((_, i) => i < 2), finaleCategoriesData.customCats, categoryLevel);
       
        const {
            categoriesByNumber,
            categories : chartCategories,
            series,
        } = chartOptions
        
        const ownSumValues        = results[2];
        const competitorSumValues = results[3];
        const recordsCount = [...(results?.[4] || [])]?.reduce((o,rc)=>{return o + (parseInt(rc?.number_of_total_customers) ?? 0)},0) ?? 0;
        const ownSum         = sumArray(ownSumValues, 'total_sales');
        const competitorSum  = sumArray(competitorSumValues, 'total_sales');
    
        const categoryLevelKey = `category_level${categoryLevel}`;
      
        const walletByCategory = categoriesByNumber.map(levelKey => {
            const own = ownSumValues.find(d => d[categoryLevelKey] === levelKey)?.total_sales || 0;
            const cmp = competitorSumValues.find(d => d[categoryLevelKey] === levelKey)?.total_sales || 0;
            return own / (own + cmp) * 100;
        });
        // //console.log("walletByCategory : ", walletByCategory);
        const ownShare = ownSum / (ownSum + competitorSum) * 100;
        const cmpShare = competitorSum / (ownSum + competitorSum) * 100;
    
        series.push({
            type  : 'line',
            name  : 'カテゴリ毎のウォレット',
            data  : walletByCategory,
            yAxis : 1,
        });
    
        const plotLines = [ownShare, cmpShare].map((value, i) => createPlotLine(value, 100, i === 0 ? '自店のウォレット : ' : '競合店のウォレット : ', i === 0 ? 'Solid' : 'ShortDot'));
        return {
            tabOptions : null,
            originalDataFrame : {
                xAxis : {
                    categories : chartCategories,
                    crosshair : {
                        color: 'rgba(255, 255, 255, 0.75)',
                    }
                },
                yAxis : [{
                    title : {
                        text : '商品単価',
                    },
                },{
                    opposite : true,
                    min   : 0,
                    max   : 100,
                    title : {
                        text : 'ウォレットシェア',
                    },
                    plotLines,
                }],
                series,
            },
            recordsCount: recordsCount ?? 0,
        }
    }
    catch {
        return {
            error: true,
            message: '処理が失敗しました。管理者を連絡してください。'
        }
    }    
}


const fillMissingRows = (data, timePeriodKey, target) => {
    //console.log(data)
    const uniqueSegments = Array.from(new Set(data.map(row => row['customer_segment_id']))).sort(
        (x,y) => parseInt(x) - parseInt(y)
    )
    let uniqueTimePeriods;
    if(timePeriodKey == 'hour'){
        uniqueTimePeriods = Array.from(new Set(data.map(row => row[timePeriodKey]))).sort((x,y) => parseInt(x) - parseInt(y))
    }
    else{
        uniqueTimePeriods = Array.from(new Set(data.map(row => row[timePeriodKey]))).sort()
    }
    let recordNumber = 0
    for (const tp of uniqueTimePeriods){
        if(timePeriodKey == 'split'){
            for (const ws of ['weekday', 'weekend']){
                for (const s of uniqueSegments){
                    if(data[recordNumber] == undefined || data[recordNumber][timePeriodKey] != tp || data[recordNumber]['customer_segment_id'] != s){
                        const insertData = {}
                        insertData['customer_segment_id'] = s;
                        insertData[timePeriodKey] = tp;
                        insertData[target] = 0;
                        insertData['week_split'] = ws
                        data.splice(recordNumber, 0, insertData)
                    }
                    recordNumber++;
                }
            }
        }
        else{
            for (const s of uniqueSegments){
                if(data[recordNumber] == undefined || data[recordNumber][timePeriodKey] != tp || data[recordNumber]['customer_segment_id'] != s){
                    const insertData = {}
                    insertData['customer_segment_id'] = s;
                    insertData[timePeriodKey] = tp;
                    insertData[target] = 0;
                    data.splice(recordNumber, 0, insertData)
                }
                recordNumber++;
            }
        }
    }


    return data;
}
const addTotalRows = (data, paramKey, periodType, isOtherSegmentsChecked, hourColumns=[]) => {
    let totals = [];
    if (periodType === "hourly"){
        totals =  hourColumns.reduce((i,n)=>{
            let k = [...i,{
                customer_segment_id : 88888,
                hour:n,
                [paramKey] : 0,
            }]
            
            if(isOtherSegmentsChecked){
                k.push({
                    customer_segment_id : 99999,
                    hour:n,
                    [paramKey] : 0,
                })
            } 
            return k
        },[])
    }
    data.map(d => {
            if(periodType !== "hourly" && !totals.find(t => t.agg_target_day === d.agg_target_day && t.week_split === d.week_split && t.hour === d.hour)) {
                if (periodType==="monthly_split" || periodType==="weekly_split") {
                            totals.push({
                                agg_target_day : d.agg_target_day,
                                customer_segment_id : 88888,
                                week_split: d.week_split === "weekend" ?  "weekend" :"weekday" ,
                                [paramKey] : 0,
                            });
                            isOtherSegmentsChecked && totals.push({
                                agg_target_day : d.agg_target_day,
                                customer_segment_id : 99999,
                                week_split: d.week_split === "weekend" ?  "weekend" :"weekday" ,
                                [paramKey] : 0,
                            });
                    // })
                }else{
                    totals.push({
                        agg_target_day : d.agg_target_day,
                        customer_segment_id : 88888,
                        [paramKey] : 0,
                    });
                    isOtherSegmentsChecked &&  totals.push({
                        agg_target_day : d.agg_target_day,
                        customer_segment_id : 99999,
                        [paramKey] : 0,
                    });
                }
            }
        const v = d[paramKey] || 0;
        let d_segment_id = d.segment_id || d.customer_segment_id
        if(periodType==="monthly_split" || periodType==="weekly_split") {
            totals.find(t => t.agg_target_day === d.agg_target_day && t.week_split === d.week_split && t.customer_segment_id === 88888)[paramKey] += v;
            if(d_segment_id !== 999) {
                isOtherSegmentsChecked &&   (totals.find(t => t.agg_target_day === d.agg_target_day && t.week_split === d.week_split && t.customer_segment_id === 99999)[paramKey] += v);
            }
        }else{
            if(periodType === "hourly"){
                    totals.find(t => t.hour === d.hour && t.customer_segment_id === 88888 )[paramKey] += v;
                if(d_segment_id !== 999) {
                    isOtherSegmentsChecked &&  (totals.find(t => t.hour === d.hour && t.customer_segment_id === 99999 )[paramKey] += v);
                }
            }else{
                totals.find(t => t.agg_target_day === d.agg_target_day && t.customer_segment_id === 88888 )[paramKey] += v;
                if(d_segment_id !== 999) {
                    isOtherSegmentsChecked &&  (totals.find(t => t.agg_target_day === d.agg_target_day && t.customer_segment_id === 99999)[paramKey] += v);
                }
            }
            
        }
    });

    return data.concat(totals);
}
const calculateYoY = (data, lastYearData, paramKey, periodType) => {
    return data.map(d => {
        const newCells = {...d};
        const test = periodType === "hourly" ? lastYearData.find(p => p.customer_segment_id === newCells.customer_segment_id && p.hour === newCells.hour) :
        lastYearData.find(p => (periodType === "weekly" || periodType==="weekly_split" ? 
                                                ((newCells.week_split === p.week_split) && 
                                                p.customer_segment_id === newCells.customer_segment_id && 
                                                moment(p.agg_target_day).week() === moment(newCells.agg_target_day).week() ):
                                            (newCells.week_split === p.week_split) && 
                                            p.customer_segment_id === newCells.customer_segment_id &&
                                                moment(p.agg_target_day).format("MM-DD") === moment(newCells.agg_target_day).format("MM-DD") )
                                        );
        const numOfCustomers = (test) ? test[paramKey] : null;
        newCells.number_of_customers_yoy_ratio = (numOfCustomers  &&  newCells[paramKey] != undefined ) ? ((newCells[paramKey] / numOfCustomers) -1)*100 : Infinity;
        return newCells;
    });
}
const getMarketAreaProfile = async (props) => {
    try {
        const {
            token, 
            history, 
            currentFormValues,
            filterOptions,
            selectedTargets,
        } = props;
        const {
            datePeriodType = [],
            segment        = [],
            start_date     = moment().add(-1, 'month'),
            end_date       = moment(),
            from_time      = null,
            to_time        = null,
        } = currentFormValues?.current || {};
        const periodType   = datePeriodType.find(option => option.selected).value;
        const dateFormat   = ['monthly', 'monthly_split'].includes(periodType) ? 'YYYY-MM' : 'YYYY-MM-DD';
        const segmentNames = segment || [];
        const startDate    = moment(start_date).format('YYYY-MM-DD');
        const endDate      = moment(end_date).format('YYYY-MM-DD');
        const dataBody     = getSubmitValues({
            filterOptions, 
            currentFormValues,
            getSelectedStoreFromHeatMap:true,
        });
        const storeInfo = dataBody.store_info[0];
        // Why not numbers? 🥺
        dataBody.chain_id = String(storeInfo.chain_id);
        dataBody.store_id = String(storeInfo.store_ids[0]);
    
        dataBody.target   = [...dataBody.aggregationTarget];
    
        const period = currentFormValues.current.datePeriodType.find(d => d.selected).value;
    
        dataBody.filter = {
            day_of_week : period,
        }
    
        // The process of deliberately converting to a string has been reverted
        // API returns 503
        dataBody.segment = [...dataBody.segment_ids];
    
        delete dataBody.metric;
        delete dataBody.source;
        delete dataBody.unit;
        delete dataBody.store_info;
        delete dataBody.aggregationTarget;
        delete dataBody.results_per;
        delete dataBody.segment_ids;
        delete dataBody.view_category_code;
    
        
        let marketAreaSummaryParams = dataBody.target.map(target => {
            return {
                target : [target],
            }
        });

        if(dataBody.target?.includes("potential_customer_resident") && dataBody.target?.includes("potential_customer_passerby")){
            marketAreaSummaryParams = [{
                target : ["potential_customer"],
            },...marketAreaSummaryParams];
        }
        /*
        const marketAreaSummaryParams = [{
            // 特になし
        }]
        */
        ////console.log({dataBody}, {currentFormValues});
        const requests = marketAreaSummaryParams.map(params => {
            return getNdJsonFetch(token, history, `https://${API_URL}/profile-analysis`, {...dataBody, ...params, results_per_city: false, results_per_city:false}, 0);
        });
        const downloadRequests = marketAreaSummaryParams.map(params => {
            return getNdJsonFetch(token, history, `https://${API_URL}/profile-analysis`, {...dataBody, ...params, results_per_city: true}, 0);
        });
        const results = await Promise.all(requests)
            .then(res => res);
        ////console.log({dataBody});
        const downloadResults = await Promise.all(downloadRequests)
            .then(res => res);
        const {
            severity=null,
            message=null,
        } = results?.[0] || {};
        const hasError = Boolean(severity);
        //const originalDataFrame = hasError || !results.length ? null : results;
        return {
            tabOptions : null,
            originalDataFrame : hasError ? null : results.filter(r => r.length).map(r => r[0]),
            downloadOriginalDataFrame : hasError ? null : downloadResults,
            error : hasError ? results : null,
        }
    }
    catch {
        return {
            error: true,
            message: '処理が失敗しました。管理者を連絡してください。'
        }
    }
}

const getMarketAreaHeatmap = async (props) => {
    try {
        const { token, history, currentFormValues, filterOptions, selectedTargets } = props;
        const {
            datePeriodType = [],
            segment = [],
            start_date = moment().add(-1, 'month'),
            end_date = moment(),
            from_time = null,
            to_time = null,
            isIndicator = false,
            store = []
        } = currentFormValues?.current || {};
        const startDate = moment(start_date).format('YYYY-MM-DD');
        const endDate = moment(end_date).format('YYYY-MM-DD');
        let selectedDatePeriodType = datePeriodType?.find((e) => e.selected).value;
        const dataBody = getSubmitValues({
            filterOptions,
            currentFormValues
        });
        let ownStoreChainId = store?.[0].value?.chain_id;
        let ownStoreChainName = store?.[0].value?.chain_name;
        let isCustomerCoverageSelected = selectedTargets?.includes('customer_coverage');
        let selectedTargetInJapanese = isCustomerCoverageSelected ? '顧客居住分布' : '来店率';

        let results_per = ()=>{
            let l = ['mesh', 'own_vs_competitor'];
            if(selectedDatePeriodType != "all"){
                l.push('week_split');
            }if(isIndicator){
                l.push('segment');
            }
            return l
        };

        // 1. fetch the data....
        let requestBody = [{
            start_date: startDate,
            end_date: endDate,
            start_hour: from_time || 0,
            end_hour: to_time || 23,
            store_info: [...dataBody?.store_info, ],
            segment_ids: [...dataBody?.segment_ids],
            results_per: results_per(),
            target: ['customer_count', 'population'],
            results_per_city: true,
            source:"pos"
        },{
            start_date: startDate,
            end_date: endDate,
            start_hour: from_time || 0,
            end_hour: to_time || 23,
            store_info: [...dataBody?.competitor_store],
            segment_ids: [...dataBody?.segment_ids],
            results_per: results_per(),
            target: ['customer_count', 'population'],
            results_per_city: true,
            source:"pasha"
        }];
        const requests =  requestBody.map((params)=>
        getNdJsonFetch(
            token,
            history,
            `https://${API_URL}/customer-resident`,
            { ...params },
            0
        )
        ) 
        let results = await Promise.all (requests).then((res) =>
        res.map((res)=>selectedDatePeriodType === 'weekday'
        ? res?.filter((e) => e.week_split === 'weekday')
        : selectedDatePeriodType === 'weekend'
        ? res?.filter((e) => e.week_split === 'weekend')
        : res)
        );
        results = results.flat();
        // ----------- dataSchema of response from server  -------------
        // [{
        //     "week_split": "weekday" / "weekend", if user has selected weekday/weekend or else we will not get this from server
        //     "own_vs_competitor": "own/competitors",
        //     "segment_id": "1", --> if user has selected per segment or else we not get this from server
        //     "mesh_cd": "8a2f5a32d817fff",
        //     "customer_count": "3676",
        //     "population": "1049163"
        //   },...]
        // ----------------------------------------------------------------

        let sumOfCustomerCount =
            isCustomerCoverageSelected &&
                results?.reduce((o, e, i) => {
                      let { own_vs_competitor, customer_count, segment_id } = e;
                      let old = { ...o };
                      old[own_vs_competitor] = isIndicator
                          ? {
                                ...old[own_vs_competitor],
                                [segment_id]:
                                    old?.[own_vs_competitor]?.[segment_id] + parseInt(customer_count) ||
                                    parseInt(customer_count)
                            }
                          : old?.[own_vs_competitor] + parseInt(customer_count) || parseInt(customer_count);
                      return old;
                  }, {});

        //---------------- formulae used for customerCoverage and customerVisitRate-------------
        //1. customer coverage = mesh customer count(for the selected chain)/sum of customer count (for the selected chain)
        //2. customer visit rate = mesh customer count(for the selected chain)/ mesh residents data
        // --------------------------------------------------------------------------------------
        let meshCdCityMapping = {}
        let dataWithTargetData = results?.reduce((o, e, i) => {
            let { own_vs_competitor, segment_id = 'allSegments', mesh_cd, customer_count, population , city_name, pref_name, area_name} = e;
            customer_count = Number(customer_count);
            population =Number(population);
            meshCdCityMapping[mesh_cd] = {'prefecture': pref_name, 'city': city_name, 'area': area_name , population}
            let old = { ...o };
            let calculatedTargetValue = parseFloat(
                Number(
                   ( isCustomerCoverageSelected
                        ? isIndicator
                            ? (customer_count / sumOfCustomerCount[own_vs_competitor][segment_id]) 
                            : (customer_count / sumOfCustomerCount[own_vs_competitor]) 
                        : customer_count / population === Infinity ||
                          customer_count / population === undefined ||
                          isNaN(customer_count / population)
                        ? 0
                        : (customer_count / population) )*100
                ).toFixed(2)
            );
            let dataPerMeshId = { numberOfCustomers :   customer_count, population:population, calculatedTargetValue:calculatedTargetValue}
            if (old?.[segment_id]?.[own_vs_competitor]) {
                old[segment_id][own_vs_competitor]['mapCells'][mesh_cd] = dataPerMeshId;
            } else {
                let d = {
                    isOwnChain: own_vs_competitor,
                    chainId: own_vs_competitor,
                    segmentId: segment_id,
                    segmentName: segment_id,
                    mapCells: { [mesh_cd]: dataPerMeshId },
                };
                old = {
                    ...old,
                    [segment_id]: { ...old?.[segment_id], [own_vs_competitor]: d }
                };
            }
            return { ...old };
        }, {});
        dataWithTargetData = Object.keys(dataWithTargetData || {}).reduce((o, e) => {
            let segmentName =
                e != 'allSegments'
                    ? getFormattedSegmentName({
                          segmentNames: segment,
                          val: e
                      })
                    : e;
            return { ...o, [segmentName]: dataWithTargetData[e] };
        }, {});
        let downloadRows = Object.keys(dataWithTargetData || {}).reduce((o, e, i) => {
            let uniqueMeshIds = [
                ...new Set([
                    ...Object.keys(dataWithTargetData?.[e]?.['own']?.['mapCells'] || {}),
                    ...Object.keys(dataWithTargetData?.[e]?.['competitors']?.['mapCells'] || {})
                ])
            ];
            if (e == 'allSegments') {
                return [
                    ...o,
                    ...uniqueMeshIds.reduce((oldMeshData, meshId) => {
                        let latLong = MapBuilderHelper.getLatLong(meshId)
                        let d = {
                            'メッシュID': meshId,
                            '緯度': latLong[0],
                            '経度': latLong[1],
                            '市区町村': meshCdCityMapping[meshId]['city'],
                            '地域': meshCdCityMapping[meshId]['area'],
                            '都道府県': meshCdCityMapping[meshId]['prefecture'],
                            [`${selectedTargetInJapanese}(${ownStoreChainName})`]: `${
                                dataWithTargetData?.[e]?.['own']?.['mapCells']?.[meshId]?.calculatedTargetValue || 0
                            }%`,
                            [`${selectedTargetInJapanese}(競合)`]: `${
                               dataWithTargetData?.[e]?.['competitors']?.['mapCells']?.[meshId]?.calculatedTargetValue || 0
                            }%`,
                            [`顧客数 (${ownStoreChainName})`]: `${
                                dataWithTargetData?.[e]?.['own']?.['mapCells']?.[meshId]?.numberOfCustomers || 0
                            }`,
                            [`顧客数 (競合)`]: `${
                                dataWithTargetData?.[e]?.['competitors']?.['mapCells']?.[meshId]?.numberOfCustomers || 0
                            }`,
                            "居住人口" : dataWithTargetData?.[e]?.['own']?.['mapCells']?.[meshId]?.population  ||  dataWithTargetData?.[e]?.['competitors']?.['mapCells']?.[meshId]?.population  || 0

                        };
                        return [...oldMeshData, d];
                    }, [])
                ];
            } else {
                return [
                    ...o,
                    ...uniqueMeshIds.reduce((oldMeshData, meshId) => {
                        let latLong = MapBuilderHelper.getLatLong(meshId)
                        let d = {
                            'メッシュID': meshId,
                            '緯度': latLong[0],
                            '経度': latLong[1],
                            セグメント: e,
                            '市区町村': meshCdCityMapping[meshId]['city'],
                            '地域': meshCdCityMapping[meshId]['area'],
                            '都道府県': meshCdCityMapping[meshId]['prefecture'],
                            [`${selectedTargetInJapanese}(${ownStoreChainName})`]: `${
                                dataWithTargetData?.[e]?.['own']?.['mapCells']?.[meshId]?.calculatedTargetValue || 0
                            }%`,
                            [`${selectedTargetInJapanese}(競合)`]: `${
                              dataWithTargetData?.[e]?.['competitors']?.['mapCells']?.[meshId]?.calculatedTargetValue || 0
                            }%`,
                            [`顧客数 (${ownStoreChainName})`]: `${
                                dataWithTargetData?.[e]?.['own']?.['mapCells']?.[meshId]?.numberOfCustomers || 0
                            }`,
                            [`顧客数 (競合)`]: `${
                                dataWithTargetData?.[e]?.['competitors']?.['mapCells']?.[meshId]?.numberOfCustomers || 0
                            }`,
                            "居住人口" : dataWithTargetData?.[e]?.['own']?.['mapCells']?.[meshId]?.population  ||  dataWithTargetData?.[e]?.['competitors']?.['mapCells']?.[meshId]?.population || 0

                        };
                        return [...oldMeshData, d];
                    }, [])
                ];
            }
        }, []);
        let downloadColumns = [
            {
                field: '都道府県'
            },
            {
                field: '市区町村'
            },
            {
                field: '地域'
            },
            {
                field: `${selectedTargetInJapanese}(${ownStoreChainName})`
            },
            {
                field: `${selectedTargetInJapanese}(競合)`
            },   
        ];
        if (isIndicator) {
            if(isCustomerCoverageSelected){
                downloadColumns = [
                    {
                        field: 'セグメント'
                    },
                    ...downloadColumns
                ];
            }else{
                downloadColumns = [
                    {
                        field: 'セグメント'
                    },
                    {
                        field: 'メッシュID'
                    },
                    {
                        field: '緯度'
                    },
                    {
                        field: '経度'
                    },
                    ...downloadColumns,
                    {
                        field: `顧客数 (${ownStoreChainName})`
                    },
                    {
                        field: `顧客数 (競合)`
                    },
                    {
                        field : '居住人口'
                    }
                ]
            }
            
        }else{
            if(!isCustomerCoverageSelected){
                downloadColumns = [
                    {
                        field: 'メッシュID'
                    },
                    {
                        field: '緯度'
                    },
                    {
                        field: '経度'
                    },
                    ...downloadColumns,
                    {
                        field: `顧客数 (${ownStoreChainName})`
                    },
                    {
                        field: `顧客数 (競合)`
                    },
                    {
                        field : '居住人口'
                    }
                ]
            }
           
        }

        const heatMapData = Object.keys(dataWithTargetData || {}).reduce((o, e) => {
            const competitorData = dataWithTargetData?.[e]?.["competitors"]
            const competitorDataMapCells = Object.keys(competitorData?.mapCells || {}).reduce((o, e, i) =>{return {...o, [e]:competitorData?.mapCells?.[e]?.calculatedTargetValue}},{})
            const own = dataWithTargetData?.[e]?.["own"]
            const ownDataMapCells = Object.keys(own?.mapCells || {}).reduce((o, e, i) =>{return {...o, [e]:own?.mapCells?.[e]?.calculatedTargetValue}},{})

            return { ...o, [e]: {
                competitors:{competitorData,mapCells:competitorDataMapCells},
                own : {own, mapCells:ownDataMapCells}
            } };
        }, {});
        return {
            originalDataFrame: heatMapData,
            selectedTarget: selectedTargets[0],
            isIndicator,
            downloadData: { downloadRows, downloadColumns },
            selectedTargetInJapanese,
            ownStoreChainName
        };
    } catch (e)  {
        console.error(e)
        return {
            error: true,
            message: '処理が失敗しました。管理者を連絡してください。'
        };
    }
};
const getPeopleFlowMapData = async (props) => {
    const {
        token, 
        history, 
        currentFormValues,
        filterOptions,
    } = props;
    const {
        datePeriodType = [],
        segment        = [],
        start_date     = moment().add(-1, 'month'),
        end_date       = moment(),
    } = currentFormValues?.current || {};
    const periodType   = datePeriodType.find(option => option.selected).value;
    const startDate    = moment(start_date).format('YYYY-MM-DD');
    const endDate      = moment(end_date).format('YYYY-MM-DD');
    const dataBody     = getSubmitValues({
        filterOptions, 
        currentFormValues,
        getSelectedStoreFromHeatMap:true
    });
    dataBody.chain_id = dataBody?.store_info?.[0]?.chain_id || null;
    dataBody.segment = dataBody.segment_ids;
    delete dataBody.segment_ids;
    delete dataBody.metric;
    delete dataBody.source;
    delete dataBody.unit;
    delete dataBody.store_info;
    delete dataBody.view_category_code;

    const results_per  = ['mesh_cd'];  
    const filters = profilePeriod2Filter?.[periodType] || [];

    const marketAreaSummaryParams = filters.map(filter => {
        return {
            start_date : startDate,
            end_date   : endDate,
            results_per: results_per,
            filter,
        }
    });
    const requests = marketAreaSummaryParams.map(params => {
        return getNdJsonFetch(token, history, `https://${API_URL}/people-flow`, {...dataBody, ...params}, 0);
    });
    const results = await Promise.all(requests)
        .then(res => res);
    const cellValues = results[0].reduce((acc, cur) => {
        const key = cur.results_per.mesh_cd;
        acc[key] = cur.number_of_unique_customers_scaled;
        return acc;
    }, {});
    return cellValues;
}
const getMarketAreaPeopleFlow = async (props) => {
    try{
        console.clear()
        const {
            token, 
            history, 
            currentFormValues,
            filterOptions,
            selectedTargets,
        } = props;
        const mapData = await getPeopleFlowMapData(props);
        const {
            datePeriodType = [],
            segment        = [],
            start_date     = moment().add(-1, 'month'),
            end_date       = moment(),
        } = currentFormValues?.current || {};
        const periodType   = datePeriodType.find(option => option.selected).value;
        const dateFormat   = ['monthly', 'monthly_split'].includes(periodType) ? 'YYYY-MM' : 'YYYY-MM-DD';
        const segmentNames = segment || [];
        const startDate    = moment(start_date).format('YYYY-MM-DD');
        const endDate      = moment(end_date).format('YYYY-MM-DD');
        const dataBody     = getSubmitValues({
            filterOptions, 
            currentFormValues,
            getSelectedStoreFromHeatMap:true
        });
        // Conversion (required due to different parameters for each API 🥲)
        let ownStoreChainId = dataBody?.store_info?.[0]?.chain_id || null
        dataBody.chain_id = ownStoreChainId;
        dataBody.segment = dataBody.segment_ids;
        delete dataBody.segment_ids;
        delete dataBody.metric;
        delete dataBody.source;
        delete dataBody.unit;
        delete dataBody.store_info;
        delete dataBody.view_category_code;

    const results_per  = [period2resultPerPeriod[periodType]];    
    const filters = profilePeriod2Filter?.[periodType] || [];
    const prefixNames  = filters.length === 2 ? ['平日 : ', '休日 : '] : [''];
    const endDateWeekCount = moment(endDate).isoWeek();
    const marketAreaSummaryParams = filters.map(filter => {
      
        return {
            start_date : startDate,
            end_date   : endDate,
            results_per: results_per,
            filter,
        }
    });
    
    let results
    let newColumns = []
    const combinedData = [[],[]]
    let period_type = 'monthly'
    if(dataBody.target.includes("people_flow")){
        let requests = marketAreaSummaryParams.map(params => {
            
            period_type = params.results_per[0]
            return getNdJsonFetch(token, history, `https://${API_URL}/people-flow`, {...dataBody, ...params,  "target" : ["people_flow"]}, 0);
        });
        let results = await Promise.all(requests)
        .then(res => res);
        if(results){
            if(period_type === 'weekly' || period_type === 'weekly_split'){
                results = results.map(d=>{
                   return d.reduce((o,n)=>{
                       let old = [...o]
                       return (parseInt(n["results_per"][period_type]) > endDateWeekCount ) ? old : [...o,n]
                   },[])
                })
            }
            let columns = results[0].map((d)=>d["results_per"][period_type])
            combinedData[0] = [...combinedData[0],...results[0]]
            if(results[1]) {
                columns = columns.concat(results[1].map((d)=>d["results_per"][period_type]))
                combinedData[1] = [...combinedData[1],...results[1]]
            }
            newColumns = newColumns.concat(columns);
        }  
    }

    if(dataBody.target.includes("own_store_user") ||  dataBody.target.includes("competitor_store_user")){
        let params = marketAreaSummaryParams[0];
        let abstractRequestBody = {
            "start_date":params.start_date,
            "end_date":params.end_date,
            "start_time":0,
            "end_time":23,
            "mesh_id": [...dataBody.mesh_id],
            "segment_ids": [...dataBody.segment],
            "source": "pasha",
            "metric":["count"],
            "unit":["customer"],
            "results_per": [periodType,"chain_id"],
        }
        let requestBody = []
        if(dataBody.target.includes("own_store_user")){
            requestBody.push({...abstractRequestBody,source:"pasha"})
        }
        if(dataBody.target.includes("competitor_store_user")){
            requestBody.push({...abstractRequestBody,source:"pos"})
        }
        let requests = requestBody.map((params,i) => {
            console.log({params});
            if(i !== 0){
                return []
            }
            period_type = params.results_per[0]
            
            return getNdJsonFetch(token, history, `https://${API_URL}/purchases`, params, 0);
            });
            let results = await Promise.all(requests)
            .then(res => res);
            results = [results.flat()];


            if(periodType === "monthly_split" || periodType === "weekly_split"){
                results =results[0].reduce((o,n)=>{
                    let r = [...o]
                    if(n.week_split === "weekday"){
                        r[0].push({
                            ...n  
                        })
                    }else{
                        r[1].push({
                            ...n  
                        })
                    }
                    return r
                },[[],[]])
            }
            let temp_data = results.map(r=>{
                return r.reduce((o,d)=>{
                    let old = [...o]
                    let p_type_data = (period_type === 'monthly') ?  parseInt( moment(d.agg_target_day).format("MM")) : 
                    (period_type === 'weekly') ? `${moment(d.agg_target_day).isoWeek()}` :
                    (period_type === 'daily') ? d.agg_target_day : parseInt(d.hour)
                
                    if(d.chain_id == ownStoreChainId){
                        if(dataBody.target.includes("own_store_user")){
                            newColumns.push(p_type_data)
                            let f = old.find(od=> od["results_per"][period_type] === p_type_data && od["results_per"]["target"] === "own_store_user")
                            if(f){
                                f["number_of_unique_customers"] = `${parseInt(f["number_of_unique_customers"]) + d.number_of_unique_customers}`
                                f["number_of_unique_customers_scaled"] =  f["number_of_unique_customers_scaled"] + d.number_of_unique_customers_scaled
                                return [...old]
                            }
                            return [...old,{
                            
                                "results_per": {
                                    [period_type]: p_type_data,
                                    target: "own_store_user"
                                },
                                "number_of_unique_customers": `${d.number_of_unique_customers}`,
                                "number_of_unique_customers_scaled": d.number_of_unique_customers_scaled
                            }]
                        }
                    }else{
                        if(dataBody.target.includes("competitor_store_user")){
                            newColumns.push(p_type_data)
                            let f = old.find(od=> od["results_per"][period_type] === p_type_data && od["results_per"]["target"] === "competitor_store_user")
                            if(f){
                                f["number_of_unique_customers"] = `${parseInt(f["number_of_unique_customers"]) + d.number_of_unique_customers}`
                                f["number_of_unique_customers_scaled"] =  f["number_of_unique_customers_scaled"] + d.number_of_unique_customers_scaled
                                return [...old]
                            }
                            return [...old,{
                                "results_per": {
                                    [period_type]: p_type_data,
                                    target: "competitor_store_user"
                                },
                                "number_of_unique_customers": `${d.number_of_unique_customers}`,
                                "number_of_unique_customers_scaled": d.number_of_unique_customers_scaled
                            }]
                        }
                    }
                    return [...old]
                },[])
            })
        combinedData[0] = [...combinedData[0],...temp_data[0]]
        if(temp_data[1]) {
            combinedData[1] = [...combinedData[1],...temp_data[1]]
        } 
    }
    newColumns = [... new Set(newColumns)]

        newColumns.forEach(c=>{
            if(dataBody.target.includes("people_flow")){
                if(!combinedData[0].find(od=> od["results_per"][period_type] === c && od["results_per"]["target"] === "people_flow")){
                    combinedData[0].push({   
                        "results_per": {
                            [period_type]: c,
                            target: "people_flow"
                        },
                        "number_of_unique_customers": `0`,
                        "number_of_unique_customers_scaled": 0
                    })
                }
                if(periodType === "monthly_split" || periodType === "weekly_split"){
                    if(!combinedData[1].find(od=> od["results_per"][period_type] === c && od["results_per"]["target"] === "people_flow")){
                        combinedData[1].push({   
                            "results_per": {
                                [period_type]: c,
                                target: "people_flow"
                            },
                            "number_of_unique_customers": `0`,
                            "number_of_unique_customers_scaled": 0
                        })
                    }
                }
            }
            if(dataBody.target.includes("own_store_user")) {
                if(!combinedData[0].find(od=> od["results_per"][period_type] === c && od["results_per"]["target"] === "own_store_user")){
                    combinedData[0].push({   
                        "results_per": {
                            [period_type]: c,
                            target: "own_store_user"
                        },
                        "number_of_unique_customers": `0`,
                        "number_of_unique_customers_scaled": 0
                    })
                }
                if(periodType === "monthly_split" || periodType === "weekly_split"){
                    if(!combinedData[1].find(od=> od["results_per"][period_type] === c && od["results_per"]["target"] === "own_store_user")){
                        combinedData[1].push({   
                            "results_per": {
                                [period_type]: c,
                                target: "own_store_user"
                            },
                            "number_of_unique_customers": `0`,
                            "number_of_unique_customers_scaled": 0
                        })
                    }
                }
            }
            if(dataBody.target.includes("competitor_store_user")){
                if(!combinedData[0].find(od=> od["results_per"][period_type] === c && od["results_per"]["target"] === "competitor_store_user")){
                    combinedData[0].push({   
                        "results_per": {
                            [period_type]: c,
                            target: "competitor_store_user"
                        },
                        "number_of_unique_customers": `0`,
                        "number_of_unique_customers_scaled": 0
                    })
                }
                if(periodType === "monthly_split" || periodType === "weekly_split"){
                    if(!combinedData[1].find(od=> od["results_per"][period_type] === c && od["results_per"]["target"] === "competitor_store_user")){
                        combinedData[1].push({   
                            "results_per": {
                                [period_type]: c,
                                target: "competitor_store_user"
                            },
                            "number_of_unique_customers": `0`,
                            "number_of_unique_customers_scaled": 0
                        })
                    }
                }
            }
        })
        results = combinedData
        
        let params = {};
        let columns = [];
        const keyNames = {
            'people_flow' : '人流数',
            'own_store_user' : '自店利用客数',
            'competitor_store_user' : '他店利用客数',
        }
        const periodKey = period2resultPerPeriod[periodType];
    
        const rowsWithMonthKey = {}
        const columnWithEnglihWeeks = [];
        results.map((rows, i) => {
            if(!rows || rows.length <1){
                return
            }
            rows.forEach(row => {
                const {
                    results_per,
                    number_of_unique_customers_scaled : value,
                } = row;
                const {
                    target,
                } = results_per;
                const prefix      = prefixNames[i];
                if(periodType === "monthly_split" || periodType === "weekly_split"){
                    let periodValue = `${(results_per[periodKey] < 10 ? "0" : "")+ results_per[periodKey]}<>${prefix.trim() ==="休日 :"? 'weekend' : "weekday"}`
                    columnWithEnglihWeeks.push(periodValue);
                    (rowsWithMonthKey[target] === undefined) && (rowsWithMonthKey[target] = {});
                    rowsWithMonthKey[target][periodValue] = Number(value);
                }else{
                    let periodValue =  (results_per[periodKey] < 10 && periodType != "hourly" ? "0" : "") + results_per[periodKey];
                    if(params[target] === undefined) (params[target] = {});
                    params[target][periodValue] = Number(value);
                    columns.push(periodValue)
                }
            });
        })
    if(periodType === "monthly_split" || periodType === "weekly_split"){
        [... new Set(columnWithEnglihWeeks)].sort().forEach((c)=>{
            let c_list = c.split("<>") //7<>weekday
            columns.push(`${c_list[1]==="weekend" ? "休日 :" : "平日 :"} ${c_list[0]}`)
        })
        Object.keys(rowsWithMonthKey).forEach((e)=>{
            Object.keys(rowsWithMonthKey[e]).sort().forEach((d)=>{
                (params[e] === undefined) ?  (params[e]= [rowsWithMonthKey[e][d]])
                : (params[e] = [...params[e],rowsWithMonthKey[e][d]])
            })
        })
    }else{
        let newParams = {}
        columns = (periodType === "hourly") ? [... new Set(columns)].sort((a,b) => parseInt(a) - parseInt(b)) : [... new Set(columns)].sort();
        Object.keys(params).forEach((e)=>{
            if(periodType === "hourly"){
                Object.keys(params[e]).sort((a,b) => parseInt(a) - parseInt(b)).forEach((d)=>{
                    (newParams[e] === undefined) ?  (newParams[e]= [params[e][d]])
                    : (newParams[e] = [...newParams[e],params[e][d]])
                })
            }else{
                Object.keys(params[e]).sort().forEach((d)=>{
                    (newParams[e] === undefined) ?  (newParams[e]= [params[e][d]])
                    : (newParams[e] = [...newParams[e],params[e][d]]);
                })
            }
        })
        params = newParams
    }
    
        const tableRows = Object.keys(params).map(key => {
            return [keyNames[key] || key, ...params[key]];
        })

        const tableColumns = ['種別'].concat(columns.map(val => {
            if(periodKey === 'hourly'){
                const hour = val < 10 ? `0${val}` : val;
                return `${hour}:00 - ${hour}:59`;
            }
            let unit = '';
            if(periodKey === 'monthly'){
                unit = '月';
            }
            if(periodKey === 'weekly'){
                unit = '週';
            }
            return `${val}${unit}`;
        }))
        const originalDataFrame = new dfd.DataFrame(tableRows, {columns: tableColumns});
        return {
            mapData,
            tabOptions : null,
            originalDataFrame,
            chartSplitOptions : prefixNames,
        }
    }
    catch {
        return {
            error: true,
            message: '処理が失敗しました。管理者を連絡してください。'
        }
    }
    
}
const getHeatMapJson = async (props) => {
    try {
        const {
            token, 
            history, 
            currentFormValues,
            filterOptions,
            selectedTargets,
        } = props;
        const dataBody = getSubmitValues({
            filterOptions, 
            currentFormValues,
        });
        dataBody.store_info = dataBody.store_info.map(info => {
            return {
                chain_id : info.chain_id,
                store_id : info.store_ids.map(id => id),
            }
        })
        const params = {
            radius     : [10],
            results_per : ['within_area', 'mesh', 'store_id'],
            unit: {
                customer : ['unique']
            },
            source : 'pos',
        }
        delete dataBody.target;
        delete dataBody.segment_ids;
        delete dataBody.results_per;
        delete dataBody.metric;
        delete dataBody.mesh_id;
        //const result = getNdJsonFetch(token, history, `https://${API_URL}/customer-per-area`, {...dataBody, ...params}, 0);
        ////console.log('getHeatMapJson', {result})
    }
    catch {
        return {
            error: true,
            message: '処理が失敗しました。管理者を連絡してください。'
        }
    }
}


const getFloatValueFixedToTwoDeci = value=> parseFloat(Number(value).toFixed(2)) ;


const getTabData = async (results, isDifferentOccupation, allStoreNames,segmentNames, periodType, startDate, endDate,start_hour, end_hour,  tabs, selectedTarget ) => {
    let {
        columns : baseColumns,
        rows    : baseRows,
        dataForChart : baseDataForChart,
    } = convertRow2TimeSpan(results[0], allStoreNames, segmentNames, periodType, startDate, endDate, start_hour, end_hour,  selectedTarget);

    if(isDifferentOccupation){
        const {
            rows : extraRows,
            dataForChart: extraDataForChart,
        } = convertRow2TimeSpan(results[1], allStoreNames,segmentNames, periodType, startDate, endDate,start_hour, end_hour, selectedTarget, true, baseRows.length, );
        baseRows = baseRows.concat(extraRows);
        baseDataForChart = baseDataForChart.concat(extraDataForChart);
    }
    const columnsWithPercentage = baseColumns.map(col => {
        return {...col, showPercentage: col?.type === "number"}
    })
    const tabByTables = tabs.map((tabName, i) => {
        let rowData = convertRow2PercentByTimeSpan(baseRows, tabName, baseDataForChart)
        return {
            tabName : tabName,
            rows    :rowData.rows,
            columns : ['顧客数時間構成比','売上時間シェア', '売上時間構成比','顧客数時間シェア','顧客数(のべ)時間シェア','顧客数(のべ)時間構成比'].includes(tabName)  ? columnsWithPercentage : baseColumns,
            dataForChart:rowData.dataForChart
        };
    });
    return tabByTables
}
const getMarketAreaSummary = async (props) => {
    try{
    const { token, history, currentFormValues, filterOptions, selectedTargets } = props;
    const {
        datePeriodType = [],
        segment = [],
        start_date = moment().add(-1, 'month'),
        end_date = moment(),
        from_time = 0,
        to_time = 23
    } = currentFormValues?.current || {};
    const periodType = datePeriodType.find((option) => option.selected).value;
    let segmentNames = segment || [];
    const startDate = moment(start_date).format('YYYY-MM-DD');
    const endDate = moment(end_date).format('YYYY-MM-DD');
    const lastYearDates = getLastYearDates(startDate, endDate, periodType);

    const lastYearStartDate = lastYearDates.startDate;
    const lastYearEndDate = lastYearDates.endDate;

    const dataBody = getSubmitValues({
        filterOptions,
        currentFormValues
    });

    const selectedStoresMeshIds = currentFormValues.current?.store
        ?.filter((store) => store?.checked)
        .map((store) => store?.value.mesh_cd);
    const tradeArea = currentFormValues.current.tradeAreaSlider || 1;
   
    let mesh_id = []
    let batchedMeshIds = []
    selectedStoresMeshIds?.forEach((meshId) => mesh_id = [... new Set (mesh_id.concat(MapBuilderHelper.getKRingIndices(meshId, tradeArea * 10)))])
    
    while(mesh_id.length >0){
        batchedMeshIds.push([...mesh_id.splice(0,30000)])
    }

    let requestBody = {
        start_date: startDate,
        end_date: endDate,
        start_hour: from_time || 0,
        end_hour: to_time || 23,
        store_info: [...dataBody?.store_info],
        segment_ids: [...dataBody?.segment_ids],
        results_per: ['mesh', 'segment', periodType],
        results_per_city:false,
    };

    let datesList = enumerateDatesBetweenDates(periodType=="hourly" ? from_time : startDate,periodType=="hourly" ? to_time : endDate, periodType);
    const marketAreaSummaryParams = [
        //0. Current Year customer_count for selected mehsIds 
        {
            api:"customer-resident",
            data: {
                ...requestBody,
                target:["customer_count"],
                source: "pos"
            }
        },
        //1.  last Year customer_count for selected mehsIds
        {
            api:"customer-resident",
            data: {
                ...requestBody,
                start_date: lastYearStartDate,
                end_date: lastYearEndDate,
                target:["customer_count"],
                source: "pos"
            }
        },
        //2. Current Year population for selected mehsIds
        {
            api:"customer-resident",
            data: {
                ...requestBody,
                target:["population"],
                source: "pos"
            }
        },
        //3. last Year population for selected mehsIds
        {
            api:"customer-resident",
            data: {
                ...requestBody,
                start_date: lastYearStartDate,
                end_date: lastYearEndDate,
                target:["population"],
                source: "pos"
            }
        }
    ];

    let meshIdWithKMs = {};
    Array(tradeArea)
        .fill(0)
        .forEach((o, i) => {
            selectedStoresMeshIds
                ?.map((meshId) => MapBuilderHelper.getKRingIndices(meshId, (i + 1) * 10))
                ?.flat()
                .forEach((id) => (meshIdWithKMs?.[id] ? null : (meshIdWithKMs[id] = i + 1)));
        });
    const requests = marketAreaSummaryParams.map(async(params) => {
        return  Promise.all(batchedMeshIds.map(async (meshIds) =>{
            return getNdJsonFetch(token, history, `https://${API_URL}/${params?.api}`, { ...params?.data, mesh_id:meshIds }, 0);
        })).then(res=>res)
    });

    let results = await Promise.all(requests).then((res) => res);

    const populationAggregator = (data) =>{
        let populationCount = {};
        for( const n of data ){
            const {
                population = 0,
                mesh_cd,
                segment_id
            } = n;
            let kms = meshIdWithKMs?.[mesh_cd] ?? tradeArea+1; 
    
            populationCount[mesh_cd] = {
                ...populationCount?.[mesh_cd],
                [segment_id]:  (populationCount?.[mesh_cd]?.[segment_id] || 0) +
                parseInt(population),
                kms:kms
            }
            populationCount[kms] = {
                ...populationCount?.[kms],
                [segment_id]:  (populationCount?.[kms]?.[segment_id] || 0) +
                parseInt(population),
                kms:kms
            }
        }
        return populationCount;
    }

    function* stepGen(steps){
        let index =   0;
        while (index < steps?.length) {
          yield steps[index];
          index = index+1;
        }
      }
    const populationCountForCurrentYear =  populationAggregator( stepGen(results[2]?.flat() ?? []));
    results[2] = [];
    const populationCountForLastYear = populationAggregator( stepGen(results[3]?.flat() ?? []));
    results[3] = [];
    let sortedResults = [[...stepGen(results[0]?.flat() ?? [])], [...stepGen(results[1]?.flat() ?? [])]]?.map((result, i) =>
        result?.reduce(
            (o, n) => {
                const { agg_target_day, week_split, hour, customer_count = 0, mesh_cd, segment_id } = n;

                let kms = meshIdWithKMs?.[mesh_cd] ?? tradeArea + 1;
                const population =
                    i === 0
                        ? populationCountForCurrentYear?.[mesh_cd]?.[segment_id] ?? 0 
                        : populationCountForLastYear?.[mesh_cd]?.[segment_id] ?? 0;
                let dateKey = `${
                    hour
                        ? hour
                        : moment(
                              i == 1 ? getNextYearDate(periodType, agg_target_day) : agg_target_day
                          ).format(
                              ['monthly', 'monthly_split'].includes(periodType) ? 'YYYY-MM' : 'YYYY-MM-DD'
                          )
                }${week_split ? '<>' + week_split : ''}`;
                return {
                    byRadiumInTheRing: {
                        ...o?.byRadiumInTheRing,
                        [kms]: {
                            ...o?.byRadiumInTheRing?.[kms],
                            [segment_id]: (function () {
                                return {
                                    customer_count:
                                        (o?.byRadiumInTheRing?.[kms]?.[segment_id]?.customer_count || 0) +
                                        parseInt(customer_count),
                                    population: (o?.byRadiumInTheRing?.[kms]?.[segment_id]?.population || 0),
                                    storeVisitingRate: (function () {
                                        return getFloatValueFixedToTwoDeci(
                                            (((o?.byRadiumInTheRing?.[kms]?.[segment_id]?.customer_count ||
                                                0) +
                                                parseInt(customer_count)) /
                                                (population +
                                                    (o?.byRadiumInTheRing?.[kms]?.[segment_id]?.population ||
                                                        0))) *
                                                100
                                        );
                                    })()
                                };
                            })()
                        }
                    },
                    byTime: {
                        ...o?.byTime,
                        [dateKey]: {
                            ...o?.byTime?.[dateKey],
                            [segment_id]: {
                                customer_count:
                                    (o?.byTime?.[dateKey]?.[segment_id]?.customer_count || 0) + (kms <= tradeArea ?  Number(customer_count || 0) : 0),
                                population:
                                    (o?.byTime?.[dateKey]?.[segment_id]?.population || 0) + (kms <= tradeArea ?  Number(population || 0) : 0) ,
                                storeVisitingRate: (function () {
                                    return getFloatValueFixedToTwoDeci(
                                        (((o?.byTime?.[dateKey]?.[segment_id]?.customer_count || 0) +
                                            Number((kms <= tradeArea ? (customer_count || 0)  : 0))) /
                                            ((o?.byTime?.[dateKey]?.[segment_id]?.population || 0) +
                                            (kms <= tradeArea ?  Number(population || 0) : 0))) *
                                            100
                                    );
                                })()
                            }
                        }
                    }
                };
            },
            {
                byRadiumInTheRing: {
                    ...Array(tradeArea)
                        .fill(0)
                        .reduce(
                            (o, n, km) => ({
                                ...o,
                                [km + 1]: {
                                    ...dataBody?.segment_ids.reduce((os, s) => ({ ...os, [s]: {
                                    population :
                                        i === 0
                                            ? populationCountForCurrentYear?.[km+1]?.[s] ?? 0
                                            : populationCountForLastYear?.[km+1]?.[s] ?? 0,
                                    customer_count : 0,
                                    storeVisitingRate : 0
                                    } }), {})
                                }
                            }),
                            {}
                        )
                },
                byTime:{
                    ...[...(datesList || [])].reduce(
                        (o,dateKey) => ({
                            ...o,
                            [dateKey]: {
                                ...dataBody?.segment_ids.reduce((os, s) => ({ ...os, [s]: {
                                population :0,
                                customer_count : 0,
                                storeVisitingRate : 0
                                } }), {})
                            }
                        }),
                        {}
                    )
                }
            }
        )
    );

    sortedResults = sortedResults.map((d)=>{
        let data = JSON.parse(JSON.stringify(d) || {})?.["byRadiumInTheRing"];
        let radiuses = Object.keys(data || {}).sort((a,b)=>Number(b)-Number(a));
        let byRadium = radiuses?.reduce((o,radius,i)=>{
            let kmsInRadius = radiuses?.slice(radiuses.indexOf(radius)).reduce((oldSegments, km,i,ar)=>{
                let newSegments = Object.keys(data[km] || {}).reduce((oldData,newSegment)=>{
                    let {customer_count=0, population=0} = data?.[km]?.[newSegment] || {}
                    customer_count = (oldData?.[newSegment]?.["customer_count"] || 0) + (customer_count);
                    population =  (oldData?.[newSegment]?.["population"] || 0) + (population )
                    return {...oldData, [newSegment] : 
                        {
                            customer_count : customer_count ,
                            population : population,
                            storeVisitingRate : getFloatValueFixedToTwoDeci((customer_count/population)*100)
                        }}
                },{...oldSegments});
                return {...newSegments}
            },{})
            return {...o, [radius]:kmsInRadius}
        },{})
        return {...d, byRadium:byRadium}
    });

    const tabWiseData = {};

    if (selectedTargets?.includes('store_visit_rate')) {
        let storeVisitingRateByTime = JSON.parse(JSON.stringify(sortedResults?.[0]?.byTime || {}));
        let lastYearStoreVisitingRateByTime = JSON.parse(JSON.stringify(sortedResults?.[1]?.byTime || {}));
        let storeVisitingRateYoYbyTime =  [...new Set([...Object.keys(storeVisitingRateByTime || {})])] .sort((a, b) => a >b).reduce((o, date) => {
            const lastYearData = sortedResults?.[1]?.byTime?.[date];
            const currentYearData = sortedResults?.[0]?.byTime?.[date];
            const currentYearSegments = [... new Set([...Object.keys(storeVisitingRateByTime?.[date] || {})])] ;
            const yoy = currentYearSegments.reduce((o, segment) => {

                const yoyValue = getFloatValueFixedToTwoDeci(
                    (currentYearData?.[segment]?.storeVisitingRate || 0 )-
                        (lastYearData?.[segment]?.storeVisitingRate || 0) 
                );
                storeVisitingRateByTime[date] = {
                    ...storeVisitingRateByTime[date],
                     [segment] : currentYearData?.[segment]?.storeVisitingRate || 0} ;
                // storeVisitingRateByTime[date]['11111'] =
                //     (currentYearData?.[segment]?.storeVisitingRate || 0) +
                //     (storeVisitingRateByTime?.[date]?.['11111'] || 0);
                return {
                    ...o,
                    [segment]: yoyValue,
                    // 99999:
                    //     (yoyValue === Infinity || yoyValue === undefined || isNaN(yoyValue) ? 0 : yoyValue) +
                    //     (o?.['99999'] || 0)
                };
            }, {});
            return {
                ...o,
                [date]: {
                    ...yoy,
                    // 99999: `${getFloatValueFixedToTwoDeci(
                    //     (yoy?.['99999'] || 0) / (Object.keys(yoy || {}).length - 1)
                    // )}%`
                }
            };
        }, {});

        let storeVisitingRateByRadium = {};
        let storeVisitingRateYoYByRadium = [...(Object.keys(sortedResults?.[0]?.byRadium) || {})]
        .filter(km=>km<=tradeArea)
            .sort((a, b) => Number(a) - Number(b))
            .reduce((o, kms) => {
                let kmsKey =   kms > tradeArea ? `${kms-1}kms+` : `${kms}km`;
                const lastYearData = sortedResults?.[1]?.byRadium?.[kms] ?? 0;
                const currentYearData = sortedResults?.[0]?.byRadium?.[kms] ?? 0;
                const currentYearSegments = Object.keys(currentYearData);

                const yoy = currentYearSegments.reduce((o, segment) => {
                    let yoyValue = getFloatValueFixedToTwoDeci(
                        (currentYearData?.[segment]?.storeVisitingRate || 0 )-
                        (lastYearData?.[segment]?.storeVisitingRate || 0) 
                    );
                    storeVisitingRateByRadium[kmsKey] = {
                        ...storeVisitingRateByRadium?.[kmsKey],
                        [segment]: currentYearData?.[segment]?.storeVisitingRate || 0,
                        // 11111:
                        //     (storeVisitingRateByRadium?.[kmsKey]?.['11111'] || 0) +
                        //         currentYearData?.[segment]?.storeVisitingRate || 0
                    };
                    return {
                        ...o,
                        [segment]:yoyValue,
                        // 99999:
                        //     (yoyValue === Infinity || yoyValue === undefined || isNaN(yoyValue)
                        //         ? 0
                        //         : yoyValue) + (o?.['99999'] || 0)
                    };
                }, {});
                return {
                    ...o,
                    [kmsKey]: {
                        ...yoy,
                        // 99999: `${getFloatValueFixedToTwoDeci(
                        //     (yoy?.['99999'] || 0) / (Object.keys(yoy || {}).length - 1)
                        // )}%`
                    }
                };
            }, {});

        let storeVisitingRateByRadiumInTheRings = {};
        let storeVisitingRateYoYByRadiumInTheRings = [
            ...Object.keys(sortedResults?.[0]?.byRadiumInTheRing || {})
        ].filter(km=>km<=tradeArea)
            .sort((a, b) => Number(a) - Number(b))
            .reduce((o, kms) => {
                let kmsKey =  kms > tradeArea ? `${kms-1}kms+` : `${kms - 1}km~${kms}km`;
                const lastYearData = sortedResults?.[1]?.byRadiumInTheRing?.[kms] ?? 0;
                const currentYearData = sortedResults?.[0]?.byRadiumInTheRing?.[kms] ?? 0;
                const currentYearSegments = Object.keys(currentYearData);

                const yoy = currentYearSegments.reduce((o, segment) => {
                    let yoyValue = parseFloat(
                        Number( (currentYearData?.[segment]?.storeVisitingRate || 0 ) -
                        (lastYearData?.[segment]?.storeVisitingRate || 0) ).toFixed(2)
                    );

                    storeVisitingRateByRadiumInTheRings[kmsKey] = {
                        ...storeVisitingRateByRadiumInTheRings?.[kmsKey],
                        [segment]: currentYearData?.[segment]?.storeVisitingRate || 0,
                        // 11111:
                        //     (storeVisitingRateByRadiumInTheRings?.[kmsKey]?.['11111'] || 0) +
                        //         currentYearData?.[segment]?.storeVisitingRate || 0
                    };
                    return {
                        ...o,
                        [segment]:yoyValue,
                        // 99999:
                        //     (yoyValue === Infinity || yoyValue === undefined || isNaN(yoyValue)
                        //         ? 0
                        //         : yoyValue) + (o?.['99999'] || 0)
                    };
                }, {});
                return {
                    ...o,
                    [kmsKey]: {
                        ...yoy,
                    //     99999: `${getFloatValueFixedToTwoDeci(
                    //         (yoy?.['99999'] || 0) / (Object.keys(yoy || {}).length - 1)
                    //     )}%`
                    }
                };
            }, {});

        tabWiseData['来店率期間軸'] = storeVisitingRateByTime;
        tabWiseData['来店率YoY期間軸'] = storeVisitingRateYoYbyTime;

        tabWiseData['来店率ドーナッツ商圏軸'] = storeVisitingRateByRadiumInTheRings;
        tabWiseData['来店率YoYドーナッツ商圏軸'] = storeVisitingRateYoYByRadiumInTheRings;
        
        tabWiseData['来店率商圏サイズ軸'] = storeVisitingRateByRadium;
        tabWiseData['来店率YoY商圏サイズ軸'] = storeVisitingRateYoYByRadium;

      
    }
    if (selectedTargets?.includes('market_area_population')) {
       
        tabWiseData['商圏人口(推計)ドーナッツ商圏軸'] = [
            ...Object.keys(sortedResults?.[0]?.byRadiumInTheRing || {})
        ].filter(km=>km<=tradeArea)
            .sort((a, b) => Number(a) - Number(b))
            .reduce((o, kms) => {
                let kmsKey =  kms > tradeArea ? `${kms-1}kms+` : `${kms - 1}km~${kms}km` ;
                const segmentWiseData = [
                    ...Object.keys(sortedResults?.[0]?.byRadiumInTheRing?.[kms] || {})
                ].reduce(
                    (o, s) => ({
                        ...o,
                        [s]: sortedResults?.[0]?.byRadiumInTheRing?.[kms]?.[s]?.population || 0,
                        // 11111:
                        //     (sortedResults?.[0]?.byRadiumInTheRing?.[kms]?.[s]?.population || 0) +
                        //     (o?.['11111'] || 0)
                    }),
                    {}
                );
                return { ...o, [kmsKey]: segmentWiseData };
            }, {});

            tabWiseData['商圏人口(推計)商圏サイズ軸'] = [...Object.keys(sortedResults?.[0]?.byRadium || {})].filter(km=>km<=tradeArea)
            .sort((a, b) => Number(a) - Number(b))
            .reduce((o, kms) => {
                let kmsKey =   kms > tradeArea ? `${kms-1}kms+`: `${kms}km`;
                const segmentWiseData = [...Object.keys(sortedResults?.[0]?.byRadium?.[kms] || {})].reduce(
                    (o, s) => ({
                        ...o,
                        [s]: sortedResults?.[0]?.byRadium?.[kms]?.[s]?.population || 0,
                        // 11111:
                        //     (sortedResults?.[0]?.byRadium?.[kms]?.[s]?.population || 0) + (o?.['11111'] || 0)
                    }),
                    {}
                );
                return { ...o, [kmsKey]: segmentWiseData };
            }, {});
    }

    if (selectedTargets?.includes('customer_coverage')) {
        let totaCustomerCountData = JSON.parse(JSON.stringify(sortedResults?.[0]?.byRadium || {}));
        let maxKms = max(Object.keys(totaCustomerCountData || {}).map(k=>Number(k)))
       
        let segmentWiseSumData =  totaCustomerCountData?.[maxKms] || {}
        const customerCoverage = [...Object.keys(JSON.parse(JSON.stringify(sortedResults?.[0]?.byRadiumInTheRing || {})))]
            .sort((a, b) => Number(a) - Number(b))
            .reduce((o, kms) => {
                let kmsKey =  kms > tradeArea ? `${kms-1}kms~` : `${kms - 1}km~${kms}km` ;
                const segmentWiseData = [...Object.keys(JSON.parse(JSON.stringify(sortedResults?.[0]?.byRadiumInTheRing || {}))?.[kms] || {})].reduce((o, s) => {

                    let customerCoverage = parseFloat(
                        Number(
                            ((sortedResults?.[0]?.byRadiumInTheRing?.[kms]?.[s]?.customer_count || 0) /
                                segmentWiseSumData?.[s]?.customer_count) *
                                100
                        ).toFixed(2)
                    );
                    return {
                        ...o,
                        [s]:customerCoverage,
                        // 99999:
                        //     (customerCoverage === Infinity ||
                        //     customerCoverage === undefined ||
                        //     isNaN(customerCoverage)
                        //         ? 0
                        //         : customerCoverage) + (o?.['99999'] || 0)
                    };
                }, {});
                return {
                    ...o,
                    [kmsKey]: {
                        ...segmentWiseData,
                        // 99999: `${getFloatValueFixedToTwoDeci(
                        //     (segmentWiseData?.['99999'] || 0) /
                        //         (Object.keys(segmentWiseData || {}).length - 1)
                        // )}%`
                    }
                };
            }, {});
        tabWiseData['顧客居住分布'] = customerCoverage;
    }

    const generateNewColumnKey = (columnKey)=>{
        let checkForSplit = columnKey?.includes('<>') && columnKey.split('<>');
        return periodType === 'hourly'
                    ? `${columnKey < 10
                        ? `0${columnKey} `
                        : `${columnKey}`}h`
                    : Boolean(checkForSplit)
                    ? `${checkForSplit[0]}(${checkForSplit[1] === 'weekday' ? '平日' : '休日'})`
                    : columnKey;
    }
    const createRowsAndColumns = (data = {}, tabName = 'tabName') => {
        let isTimePeriod = ['来店率期間軸','来店率YoY期間軸'].includes(tabName);
        let tableWithPercentage = ['来店率期間軸', '来店率ドーナッツ商圏軸', '来店率商圏サイズ軸' , '顧客居住分布'];
        let tableWithPlusSign = [ 
            "来店率YoY期間軸", 
            "来店率YoYドーナッツ商圏軸",
            "来店率YoY商圏サイズ軸"
        ]
        let columnKeys = Object.keys(data || {})
        const rowKeys = [
            ...new Set(columnKeys.map((columnKey) => Object.keys(data[columnKey] || {})).flat())
        ].sort((a, b) => Number(a) - Number(b));

        const rowData = rowKeys.map((rowKey, i) => {

            const segmentName =
                '99999' == rowKey ? 'Average' : 
                '11111' == rowKey ? 'Sum' : 
                getFormattedSegmentName({ segmentNames, val: rowKey });
            const columnData = columnKeys.reduce(
                (o, column) => ({ ...o, [isTimePeriod ? generateNewColumnKey(column): column ]: data[column][rowKey] || 0 }),
                { id: i, セグメント: segmentName }
            );
            return columnData;
        });
        (periodType === "hourly" && isTimePeriod ) && (columnKeys = columnKeys.sort((a,b)=>a-b))
        return {
            tabName: tabName,
            rows: rowData,
            columns: [
                {
                    field: 'セグメント',
                    headerName: 'セグメント',
                    minWidth: 350,
                    type: 'string',
                    showPercentage :false,
                },
                ...columnKeys.map((column) => ({
                    field: isTimePeriod ? generateNewColumnKey(column): column ,
                    headerName: isTimePeriod ? generateNewColumnKey(column): column ,
                    width: 180,
                    type: 'number',
                    showPercentage : tableWithPercentage?.includes(tabName),
                    showPlusSign : tableWithPlusSign?.includes(tabName),
                })) 
            ],
        };
    };
    const finalData =  Object.keys(tabWiseData || {})?.map((tabName) =>
            createRowsAndColumns(tabWiseData[tabName], tabName)
        ) || {};

    return {
        originalDataFrame: finalData
    };
}
catch {
    console.log("error in marketAreaSummay");
    return {
        error: true,
        message: '処理が失敗しました。管理者を連絡してください。'
    }
}
};

const getMarketAreaShare = async (props) => {
    try {
        console.clear();
        const {
            token, 
            history, 
            currentFormValues,
            filterOptions,
            selectedTargets,
        } = props;
        const {
            datePeriodType = [],
            segment        = [],
            start_date     = moment().add(-1, 'month'),
            end_date       = moment(),
            store          = [],
            competingStores= [],
            from_time      = 0,
            to_time        = 23,
            isDifferentOccupation=false,
        } = currentFormValues?.current || {};
        const periodType   = datePeriodType.find(option => option.selected).value;
        const dateFormat   = ['monthly', 'monthly_split'].includes(periodType) ? 'YYYY-MM' : 'YYYY-MM-DD';
        const segmentNames = segment || [];
        const startDate    = moment(start_date).format('YYYY-MM-DD');
        const endDate      = moment(end_date).format('YYYY-MM-DD');
        const allStoreNames= getAllStoreNames(store, competingStores, isDifferentOccupation);
        const dataBody     = getSubmitValues({
            filterOptions, 
            currentFormValues,
        });
    
        const marketAreaShareParams = []
        if(isDifferentOccupation){
            marketAreaShareParams.push({
                start_date : startDate,
                end_date   : endDate,
                start_hour : from_time,
                end_hour   : to_time,
                source     : "pos",
                metric     : ["count", "sum"],
                unit       : ["customer", "price", "receipt"],
                results_per: ["chain_id", periodType,"segment"],
            },{
                start_date : startDate,
                end_date   : endDate,
                start_hour : from_time,
                end_hour   : to_time,
                store_category_id: dataBody.differentOccupation,
                source     : "pasha",
                metric     : ["count", "sum"],
                unit       : ["customer", "price", "receipt"],
                results_per: ["store_category", periodType,"segment"],
            },{
                //  records count for own stores fetched from pos
                api : "purchases",
                body : {
                    start_date : startDate,
                    end_date   : endDate,
                    start_hour : from_time,
                    end_hour   : to_time,
                    store_info : [...dataBody.store_info],
                    source     : "pos",
                    metric     : ["count"],
                    unit       : ["receipt"],
                },
                name:"recordCountBody"
            },{
                //  records count for competingStores fetched from pasha
                api : "purchases",
                body : {
                    start_date : startDate,
                    end_date   : endDate,
                    start_hour : from_time,
                    end_hour   : to_time,
                    store_category_id: dataBody.differentOccupation,
                    source     : "pasha",
                    metric     : ["count"],
                    unit       : ["receipt"],
                },
                name:"recordCountBody"
            })

        }else{
            marketAreaShareParams.push({
                // own stores data fetch from pos
                start_date : startDate,
                end_date   : endDate,
                start_hour : from_time,
                end_hour   : to_time,
                store_info : [...dataBody.store_info],
                source     : "pos",
                metric     : ["count", "sum"],
                unit       : ["customer", "price", "receipt"],
                results_per: ["chain_id", periodType, "segment"],
            },
            {
                // competingStores data from pasha
                start_date : startDate,
                end_date   : endDate,
                start_hour : from_time,
                end_hour   : to_time,
                store_info : [...dataBody.competitor_store],
                source     : "pasha",
                metric     : ["count", "sum"],
                unit       : ["customer", "price", "receipt"],
                results_per: ["chain_id", periodType, "segment"],
            },{
                //  records count for own stores fetched from pos
                api : "purchases",
                body : {
                    start_date : startDate,
                    end_date   : endDate,
                    start_hour : from_time,
                    end_hour   : to_time,
                    store_info : [...dataBody.store_info],
                    source     : "pos",
                    metric     : ["count"],
                    unit       : ["receipt"],
                },
                name:"recordCountBody"
            },{
                //  records count for competingStores fetched from pasha
                api : "purchases",
                body : {
                    start_date : startDate,
                    end_date   : endDate,
                    start_hour : from_time,
                    end_hour   : to_time,
                    store_info : [...dataBody.competitor_store],
                    source     : "pasha",
                    metric     : ["count"],
                    unit       : ["receipt"],
                },
                name:"recordCountBody"
            });
           
        }


        
        delete dataBody.categories;
        delete dataBody.competingStores;
        delete dataBody.competitor_store;
        delete dataBody.competitor_store_mesh_id;
        delete dataBody.differentOccupation;
        delete dataBody.mesh_id;
        delete dataBody.target;
        delete dataBody.view_category_code;

       
        const requests = marketAreaShareParams.map(param => {
            return getNdJsonFetch(token, history, `https://${API_URL}/purchases`,  param?.name == "recordCountBody" ? {...param?.body}: {...dataBody, ...param}, 0);
        });
    
        const results = await Promise.all(requests)
            .then(res => res);

        if(!isDifferentOccupation){
            results[0] = [...(results?.[0] || []),...(results?.[1] || [])]
        }
        const recordsCount = [...(results?.[marketAreaShareParams.length-1] || []), ...(results?.[marketAreaShareParams.length-2] || [])]?.reduce((o,rc)=>{return o + (parseInt(rc?.number_of_total_customers) ?? 0)},0) ?? 0  
        if(!results[0]){
            return {
                originalDataFrame : null,
            }
        }

        let tabByTables = []
        const targetTabMapping = {
            'total_customers':['顧客数', '顧客数時間シェア', '顧客数時間構成比'],
            'sales': ['売上','売上時間シェア','売上時間構成比'],
            'total_no_of_customers': ['顧客数(のべ)', '顧客数(のべ)時間シェア', '顧客数(のべ)時間構成比']
        }
        for(let target of selectedTargets){
            const tabs = targetTabMapping[target];
            await getTabData(results, isDifferentOccupation, allStoreNames,segmentNames, periodType, startDate, endDate, from_time, to_time,  tabs, target )
            .then (res => {
                tabByTables = [...tabByTables, ...res]
            })
        }
        return {
            originalDataFrame : tabByTables,
            recordsCount :  recordsCount ?? 0
        }
    }
    catch {
        console.log("error in marketAreaShare result")
        return {
            error: true,
            message: '処理が失敗しました。管理者を連絡してください。'
        }
    }
    
}
const getChainName = (props) => {
    const {
        allStoreNames,
        chain_id=null,
    } = props;
    if(!chain_id) return chain_id;
    return allStoreNames?.find(store => store.chain_id === Number(chain_id))?.chain_name || chain_id;
}
const getStoreName = (props) => {
    const {
        allStoreNames,
        store_id=null,
    } = props;
    if(!store_id) return store_id;
    return allStoreNames.find(d => d.store_id === Number(store_id))?.store_name || store_id;
}
const getStoreFullName = (props) => {
    const {
        allStoreNames,
        store_id=null,
    } = props;
    if(!store_id) return store_id;
    return allStoreNames.find(d => d.store_id === Number(store_id))?.store_full_name || store_id;
}
const convertStoreNames = (store) => {
    const storeData = [];
    store.forEach(s => {
        const {
            value,
            pasha_store_id,
            competitor_store_id,
            competitor_store_chain_id,
            chain_id,
            area_store_name,
            competitor_store_company_name,
            competitor_store_name,
            chain_name,
            store_full_name,
        } = s.value;
        const chain_id_value        = competitor_store_chain_id || chain_id;
        const chain_name_value      = competitor_store_company_name || chain_name;
        const store_id              = pasha_store_id || competitor_store_id;
        const store_name            = area_store_name || competitor_store_name;
        const store_full_name_value = competitor_store_company_name && `${competitor_store_company_name} ${competitor_store_name}` || store_full_name;
        const isNullArray    = ['', ' ', null, undefined];
        if(!isNullArray.includes(store_name) && !isNullArray.includes(chain_name_value)){
            storeData.push({
                chain_id : chain_id_value,
                chain_name : chain_name_value,
                store_id : store_id,
                store_name : store_name,
                store_full_name : store_full_name_value,
                isCompetitor : Boolean(competitor_store_name),
            });
        }
    });
    return storeData;
}
const getAllStoreNames = (store = [], competingStores = [], isDifferentOccupation = false) => {
    return convertStoreNames(store).concat(
        isDifferentOccupation
            ? convertStoreNames(competingStores)
            : convertStoreNames(
                  competingStores.reduce((o, n) => {
                      return [
                          ...o,
                          ...n?.child.map((c) => {
                              return { value: c };
                          })
                      ];
                  }, [])
              )
    );
};
const convertRow2PercentByTimeSpan = (rows, tabName, baseDataForChart) => {
    const ignoreKeys = ['id', 'chainName', "segmentName", "segmentId"]
    if (tabName === '顧客数時間構成比' || tabName === '売上時間構成比' || tabName === '顧客数(のべ)時間構成比') {
        let sumedData = rows.map(row => {
            const hourlyKeys = Object.keys(row)
                .filter(key => !ignoreKeys.includes(key));
            const total = hourlyKeys
                .map(key => row[key] || 0)
                .reduce((sum, current) => sum + current, 0);
            const newRows = {};
            Object.keys(row).forEach(key => {
                const val = row[key];
                newRows[key] = hourlyKeys.includes(key) ? total ? Number((val / total * 100).toFixed(2)) : 0 : val;
            })
            return newRows;
        })
        let chartSummedData = baseDataForChart.map(row => {
            const hourlyKeys = Object.keys(row)
                .filter(key => !ignoreKeys.includes(key));
            const total = hourlyKeys
                .map(key => row[key] || 0)
                .reduce((sum, current) => sum + current, 0);
            const newRows = {};
            Object.keys(row).forEach(key => {
                const val = row[key];
                newRows[key] = hourlyKeys.includes(key) ? total ? Number((val / total * 100).toFixed(2)) : 0 : val;
            })
            return newRows;
        })
        return { rows: sumedData, dataForChart: chartSummedData }
    }
    if (tabName === '顧客数時間シェア' || tabName === '売上時間シェア' || tabName === '顧客数(のべ)時間シェア') {
        let dateList = Object.keys(rows?.[0]).filter(c => !ignoreKeys.includes(c))
        let dateWiseTotal = dateList.reduce((o, n) => {
            return { ...o, [n]: 0 }
        }, {});

        rows.forEach(r => {
            dateList.forEach(d => {
                dateWiseTotal[d] += r[d];
            });
        });
        let sumedData = rows.map((row) => {
            let {
            } = row
            return {
                ...row, ...dateList.reduce((oldDate, c) => {
                    return { ...oldDate, [c]: dateWiseTotal[c] ? Number((row[c] / dateWiseTotal[c] * 100).toFixed(2)) : 0 }
                }, {})
            }
        })

        let chartSummedData = baseDataForChart.map((row) => {
            let {
            } = row
            return {
                ...row, ...dateList.reduce((oldDate, c) => {
                    return { ...oldDate, [c]: dateWiseTotal[c] ? Number((row[c] / dateWiseTotal[c] * 100).toFixed(2)) : 0 }
                }, {})
            }
        });
        return { rows: sumedData, dataForChart: chartSummedData }
    }
    return { rows: rows, dataForChart: baseDataForChart }
}
const convertRow2TimeSpan = (
    rows,
    allStoreNames,
    segmentNames,
    periodType,
    startDate,
    endDate,
    start_hour,
    end_hour,
    selectedTarget,
    isDifferentOccupation = false,
    initIdx = 0
) => {
    let dateKeysFromApiResponse = [
        ...new Set(
            rows.map((c) => {
                let { agg_target_day, week_split = false, hour = false } = c;
                return `${
                    hour
                        ? c.hour < 10
                            ? `0${hour}`
                            : `${hour}`
                        : moment(agg_target_day).format(
                              ['monthly', 'monthly_split'].includes(periodType) ? 'YYYY-MM' : 'YYYY-MM-DD'
                          )
                }${week_split ? '<>' + week_split : ''}`;
            })
        )
    ].sort((a, b) => (a > b ? 1 : -1));
    let segmentsListFromApiResponse = [...new Set(rows.map((c) => c.customer_segment_id))].sort((a, b) =>
        a > b ? 1 : -1
    );
    let chainIdsListFromApiResponse = [
        ...new Set(rows.map((row) => row?.store_category_id || row?.chain_id))
    ].sort((a, b) => (a > b ? 1 : -1));
    let dateKeysWithZeroValues = dateKeysFromApiResponse.reduce(
        (old, dateKey) => ({ ...old, [dateKey]: 0 }),
        {}
    );
    let dataWithChainAndSegment = chainIdsListFromApiResponse.reduce((old, chainId) => {
        let chainName = isDifferentOccupation
            ? chainId == 1
                ? 'コンビニエンスストア'
                : 'ドラッグストア'
            : getChainName({ allStoreNames, chain_id: chainId });
        let chainWithSegments = segmentsListFromApiResponse.map((segmentId) => ({
            chainId: chainId,
            chainName: chainName,
            segmentId: segmentId,
            segmentName: getFormattedSegmentName({ segmentNames, val: segmentId }),
            ...dateKeysWithZeroValues
        }));
        return [...old, ...chainWithSegments];
    }, []);

    let dataForChart = chainIdsListFromApiResponse.map((chainId) => {
        let chainName = isDifferentOccupation
            ? chainId == 1
                ? 'コンビニエンスストア'
                : 'ドラッグストア'
            : getChainName({ allStoreNames, chain_id: chainId });
        return { chainId: chainId, chainName: chainName, ...dateKeysWithZeroValues };
    });
//     {"agg_target_day":"2023-01-26","chain_id":1068,"customer_segment_id":1,"total_sales":386332,"number_of_total_customers":304,"number_of_unique_customers":294}
// {"agg_target_day":"2023-01-26","chain_id":1068,"customer_segment_id
    rows.forEach((row) => {
        let {
            agg_target_day,
            week_split = false,
            hour = false,
            chain_id = false,
            number_of_unique_customers_scaled,
            total_sales_scaled,
            number_of_total_customers_scaled,
            customer_segment_id,
            store_category_id = false,
            total_sales,
            number_of_total_customers,
            number_of_unique_customers
        } = row;
        let dateKey = `${
            hour ? hour < 10
                    ? `0${hour}`
                    : `${hour}`
                : moment(agg_target_day).format(
                      ['monthly', 'monthly_split'].includes(periodType) ? 'YYYY-MM' : 'YYYY-MM-DD'
                  )
        }${week_split ? '<>' + week_split : ''}`;
        let chainWithSegment = dataWithChainAndSegment.find(
            (tableRow) =>
                tableRow.chainId == (store_category_id || chain_id) &&
                tableRow.segmentId == customer_segment_id
        );
        let chainDataForChart = dataForChart.find(
            (chain) => chain.chainId === (store_category_id || chain_id)
        );

        if (chainWithSegment) {
            if (dateKey !== undefined) {
                if (selectedTarget == 'sales') {
                    chainWithSegment[dateKey] += total_sales_scaled || total_sales || 0;
                } else if (selectedTarget == 'total_customers') {
                    chainWithSegment[dateKey] += number_of_unique_customers_scaled || number_of_unique_customers || 0 ;
                } else if (selectedTarget == 'total_no_of_customers') {
                    chainWithSegment[dateKey] += number_of_total_customers_scaled ||  number_of_total_customers || 0;
                }
            }
        }
        if (chainDataForChart) {
            if (dateKey !== undefined) {
                if (selectedTarget == 'sales') {
                    chainDataForChart[dateKey] += total_sales_scaled || total_sales || 0;
                } else if (selectedTarget == 'total_customers') {
                    chainDataForChart[dateKey] += number_of_unique_customers_scaled  || number_of_unique_customers || 0 ;
                } else if (selectedTarget == 'total_no_of_customers') {
                    chainDataForChart[dateKey] += number_of_total_customers_scaled ||  number_of_total_customers || 0;
                }
            }
        }
    });

    let finalRows = dataWithChainAndSegment.map((c, i) => {
        let d = { ...c };
        delete d.chainId;
        return { id: i + initIdx, ...d };
    });
    let finalColumns = [
        {
            field: 'chainName',
            headerName: '企業名',
            width: 200,
            type: 'string'
        },
        {
            field: 'segmentName',
            headerName: 'セグメント',
            minWidth: 350,
            type: 'string'
        }
    ].concat(
        dateKeysFromApiResponse.map((e) => {
            let checkForSplit = e?.includes('<>') && e.split('<>');
            let headerName =
                periodType === 'hourly'
                    ? `${e}h`
                    : Boolean(checkForSplit)
                    ? `${checkForSplit[0]}(${checkForSplit[1] === 'weekday' ? '平日' : '休日'})`
                    : e;
            return {
                field: e,
                headerName: headerName,
                width: 200,
                type: 'number'
            };
        })
    );

    let finalDataForChart = dataForChart.map((c, i) => {
        let d = { ...c };
        delete d.chainId;
        return { id: i + initIdx, ...d };
    });

    return {
        columns: finalColumns,
        rows: finalRows,
        dataForChart: finalDataForChart
    };
};
const createPlotLine = (value, max, prefix='', dashStyle='Solid') => {
    const textOutlineStyle = Array(10).fill('0 0 2px #f1f3f4').join(',');
    return {
        label  : {
            align : 'right',
            text : `${prefix}${(value).toFixed(2)} %`,
            style : {
                textShadow : textOutlineStyle,
                fontWeight : 'bold',
                color : 'rgba(231, 76, 60,1.0)',
            },
            x : 0,
            y : max * 0.8 < value ? 16 : -6,
        },
        value : value,
        color : 'rgba(231, 76, 60,1.0)',
        zIndex : 5,
        dashStyle,
        //useHTML : true,

    }
}
const sumArray = (arr, key) => {
    return arr.map(d => key ? d[key] : d).reduce((sum, elem) => sum += elem, 0);
}
const createBoxPlotOptions = (rows, flatCategories,categoryLevel) => {
    const colors = [{
        fill : 'rgba(26, 188, 156, .5)',
        border : 'rgba(26, 188, 156,1.0)',
    }, {
        fill : 'rgba(230, 126, 34, .5)',
        border : 'rgba(230, 126, 34,1.0)',
    }]
    const seriesOptions = [];
    const categories = [];
    rows.forEach((row, index) => {
        // //console.log("Rows in createbox-plot-options: ", row)
        seriesOptions[index] = {};
        row.forEach(param => {
            //"min", "25%tile", "median", "75%tile", "max"
            const {
                category_level4=null,
                category_level3=null,
                category_level2=null,
                category_level1=null,
                max,
                median,
                min,
                ptile_25,
                ptile_75,
                chain_id,
            } = param;
            const levelKey = [category_level4, category_level3, category_level2, category_level1].filter(level => level !== null)[0];
            //const storeName = index === 0 ? storeNames.find(store => store.value.chain_id === chain_id)?.label || null : flatCompetingStore.find(store => store.chain_id === chain_id)?.label || null;
            const storeName = index === 0 ? '自店' : '競合店'; //index === 0 ? "own-store" : "competitor"
            //const levelKey = category_level4;
            if(seriesOptions[index][storeName] === undefined){
                seriesOptions[index][storeName] = {};
            }
            if(seriesOptions[index][storeName][levelKey] === undefined){
                seriesOptions[index][storeName][levelKey] = null;
                if(!categories.includes(levelKey)){
                    categories.push(levelKey);
                }
            }
            seriesOptions[index][storeName][levelKey] = [min, ptile_25, median, ptile_75, max];
        });
    })
    
    // ---- DATA SCHEMA OF SERIES OPTIONS----------
    // seriesOptions = [
    //      {"自店" : {CatLevelKey:[min, ptile_25, median, ptile_75, max],...} },
    //      {"競合店" : {CatLevelKey:[min, ptile_25, median, ptile_75, max],...} }
    //      ]

    const series = [];
    seriesOptions.forEach((data, i) => {
        const boxColor = colors[i];
        Object.keys(data).forEach(seriesName => {
            // seriesname is either own-store or competitor 
            series.push({
                name : seriesName,
                color : boxColor.border,
                fillColor : boxColor.fill,
                data : categories.map(levelKey => {
                    return data[seriesName][levelKey] || null;
                }),
            });
        });
    })
   
    return {
        categoriesByNumber : [...categories],
        categories : categories.map(key => flatCategories?.find(c => (c.value == key && c.groupKey === `category_lv${categoryLevel}_cd`))?.groupName || key),
        series,
    };

}

const margeRows = (props) => {
    const {
        margeArray=[],
        rowSettings=[],
        segmentNames, 
        dateFormat, 
        periodType,
        hourColumns=[]
    } = props;

    let columnKeys = [];
    const margeData = (margeArray)
    .map((rows, i) => {
        const {
            indicatorName,
            valueKey,
            showPercentage,
            showPlusSign
        } = rowSettings[i];
        const newRows = convertRows({
            segmentNames, 
            dateFormat, 
            rows, 
            indicatorName, 
            valueKey,
            periodType,
            hourColumns,
            showPercentage,
            showPlusSign
        });
        if(i === 0) columnKeys = newRows.heads;
        return newRows.rows;
    })
    .reduce((a, b) => {
        return a.concat(b);
    });
    return {
        margeData,
        columnKeys,
    }
}
const convertRows = (props) => {
    const {
        segmentNames, 
        dateFormat, 
        rows, 
        indicatorName, 
        valueKey,
        periodType,
        hourColumns=[],
        showPercentage=false,
        showPlusSign=false
    } = props;
    const heads = ['指標', 'セグメント'];
    const dateRange  = (periodType==="monthly_split" || periodType==="weekly_split") ?   
    [...new Set(rows.map(r => `${r.agg_target_day}<>${r.week_split}`))].sort().map(d => {
        let d_list =  d.split("<>");
        return `${moment(d_list[0]).format(dateFormat)}(${d_list[1] === "weekday" ? "平日" : "休日" })`
    } ) : (periodType === "hourly" ? hourColumns :
    [...new Set(rows.map(r => r.agg_target_day))].map(d => moment(d).format(dateFormat)).sort() );
    const newRows = []; 
    let rowDates = dateRange.reduce((o,n)=>{return {...o,[n]:0}},{})
    rows.forEach(row => {
        const {
            customer_segment_id,
            agg_target_day,
            week_split,
            hour
        } = row;
        const dateValue = periodType === "hourly" ? hour :
            periodType==="monthly_split" || periodType==="weekly_split" ?
            `${moment(agg_target_day).format(dateFormat)}(${week_split === "weekday" ? "平日" : "休日" })` :
            moment(agg_target_day).format(dateFormat)
        const segmentName = getFormattedSegmentName({
            segmentNames, 
            val : customer_segment_id,
        });
        const value = row[valueKey] || 0;
        const matchRow = newRows.find(r => r.segmentName === segmentName);
        if(matchRow){
            matchRow[dateValue] = value;
        }else{
            newRows.push({
                indicator : indicatorName,
                segmentName,
                ...rowDates,
                [dateValue] : value,
                customer_segment_id : customer_segment_id,
                showPercentage : showPercentage,
                showPlusSign:showPlusSign
            });
        }
    });
    let sortedNewRows = newRows.sort((a,b) => a.customer_segment_id - b.customer_segment_id).map(d=> {
        let n = {...d}
        delete n.customer_segment_id
        return n
    })

    return {
        heads : heads.concat(dateRange),
        rows : sortedNewRows.map(row => {
            return Object.values(row)
        }),
    }
}
const getSegmentName = (props) => {
    const {
        segmentNames,
        val,
    } = props;
    return segmentNames.find(d => d.value === Number(val))?.label || val;
}
const getFormattedSegmentName = (props) => {
    const {
        segmentNames,
        val,
    } = props;
    const obj = segmentNames.find(d => d.value === Number(val));
    return obj && Number(val) < 1000 ? `${obj.label}(${val})` : obj ? obj.label : val;
}

const getFlatCategoriesList = (lists) => {
    // //console.log("Categories list in getFlatCategoriesList", lists)
    return lists.filter(list => list.visible).map(list => {
        const {
            groupName,
            groupKey='_lv4_',
            label=null,
            value,
            child=null,
        } = list;
        // //console.log("getFlatCategoriesList : ", groupName, groupKey, label, value, child)
        const lv = groupKey.split('_')[1].replace('lv', '') || null;
        return [{
            label  : groupName || label,
            lv     : lv ? Number(lv) : null,
            value, 
        }].concat(child ? getFlatCategoriesList(child) : []);
    }).flat();
}
const getCategoryName = (props) => {
    const {
        categories,
        val,
        category_level_key=null
    } = props;
    return categories.find(c => c.value === val && (category_level_key && `category_level${c.lv}` === category_level_key))?.label || val;
}

const getFlatArray = (nodes) => {
    if(nodes.length === 0) return [];
    return nodes.map(node => {
        const {
            child,
        } = node;
        if(child){
            return (getFlatArray(child));
        }
        return node;
    }).flat();
}

const getFlatCategoriesListForBoxPlot = (cats)=>{
    const flatCategoriesList = []
    const fun = (child) =>{ 
        child.forEach((c)=>{ 
            let {
                groupName,
                value,
                groupKey=null,
                label,
                child,
                checked
            } = c;
            let childLength = child ? child?.length : 0;
            let checkedChildCount = 0
            if(child){
                child.forEach(cd=> {
                    if(cd.checked) {
                    checkedChildCount += 1 ;
                    }
                })
            }
            flatCategoriesList.push({groupName:groupName || label ,value:value,groupKey:groupKey || "category_lv4_cd",checked: checked || (child && checkedChildCount === childLength )})
            if (child){
                fun(child)
            }
        })
    }
    fun(cats);
    return flatCategoriesList;
}
const storeInfo2Array = (store_info) => {
    const ids = [];
    store_info.forEach(info => {
        const {
            chain_id,
            store_ids,
        } = info;
        store_ids.forEach(store_id => {
            ids.push([store_id, chain_id]);
        });
    });
    return ids;
}
const getHasRange = () => {
    const pathname = window.location.pathname;
    const hasRange = Boolean(isUseRangePath.find(path => pathname.indexOf(path) > -1));
    return hasRange;
}
const getPrimaryChainName = (dataBody, allStoreNames) => {
    const {
        store_info = [],
    } = dataBody;
    if(store_info.length){
        const {
            chain_id=null,
        } = store_info[0];
        return getChainName({
            allStoreNames,
            chain_id,
        });
    }
}
const getSubmitValues = (props) => {
    
    const {
        filterOptions,
        currentFormValues : _currentFormValues,
        getSelectedStoreFromHeatMap=false
    } = props;
    const currentFormValues = {..._currentFormValues};
    const isDifferentOccupation = currentFormValues.current?.isDifferentOccupation || false;
    const hasRange = getHasRange();
    ////console.log({currentFormValues})
    const filterValues = {};

    filterOptions.map(key => {
        filterValues[key] = currentFormValues?.current?.[key]
            ?.flat()
            ?.filter(o => (key==="store" && getSelectedStoreFromHeatMap )? o.visible && o.selected  :  o.visible && o.checked)
            ?.map(o => o.value)
            || [];
    });

    const postData = {
        ...filterValues,
        start_date    : moment(currentFormValues.current.start_date).format('YYYY-MM-DD'),
        end_date      : moment(currentFormValues.current.end_date).format('YYYY-MM-DD'),
        //results_per   : [currentFormValues.current.datePeriodType.find(option => option.selected).value, currentFormValues.current.isIndicator ? 'metric' : 'segment'],
        results_per   : [currentFormValues.current.datePeriodType.find(option => option.selected).value, 'segment'],
        source        : 'pos' // <- What is this parameter???
        // ↓ Where do you specify this parameter?
        //viewType ? 'tab' : 'single'
    };
    const _store = postData?.store || [];
    const storeById = {};
    const mapMeshIds = currentFormValues?.current?.mapMeshIds || [];
    const meshRadius = currentFormValues?.current?.meshRadius;
    ////console.log({mapMeshIds});
    const meshIds   = mapMeshIds.length ? mapMeshIds : [];
    
    _store.forEach(s => {
        const {
            //group_id : chain_id,
            chain_id,
            // Will be unified to `store_id` in the near future
            pasha_store_id : store_id,
            mesh_cd : mesh_id,
        } = s;
        
        if(storeById[chain_id] === undefined){
            storeById[chain_id] = [];
        }
        storeById[chain_id].push(Number(store_id));
        if(mapMeshIds.length === 0 && mesh_id){
            let ids = [mesh_id];
            // Execute if the getKRingIndices function is implemented
            if(MapBuilderHelper.getKRingIndices){
                ids = MapBuilderHelper.getKRingIndices(mesh_id, meshRadius);
            }
            ids.forEach(id => meshIds.push(id));
        }
    })


    
    postData.store_info  = Object.keys(storeById).map(key => {
        return {
            chain_id  : Number(key),
            store_ids : storeById[key],
        }
    });
    if(meshIds.length){
        postData.mesh_id = meshIds;
    }
    
    if(postData.competingStores){
        const _store = postData.competingStores;
        if(isDifferentOccupation){
            postData.differentOccupation = [...postData.competingStores];
            postData.competitor_store    = [];
        }else{
            const storeById = {};
            const meshIds = [];
            let range = currentFormValues?.current?.range || 10
            currentFormValues?.current?.competingStores.reduce((o,n)=> {return [...o,...n?.child]},[]).filter(c=> c.checked && (c.competitor_store_distance/1000)<=range).forEach(s => {
                const {
                    //group_id : chain_id,
                    competitor_store_chain_id : chain_id,
                    // Will be unified to `store_id` in the near future
                    competitor_store_id : store_id,
                    competitor_store_mesh_cd : mesh_id,
                } = s;
                if(storeById[chain_id] === undefined){
                    storeById[chain_id] = [];
                }
                storeById[chain_id].push(Number(store_id));
                meshIds.push(mesh_id);
            })
    
            postData.competitor_store  = Object.keys(storeById).map(key => {
                return {
                    chain_id  : Number(key),
                    store_ids : storeById[key],
                }
            });
            if(meshIds.length){
                postData.competitor_store_mesh_id = meshIds;
            }
        }
    }
    if(currentFormValues.current.categories){
        const categoryLists = getFlatArray(currentFormValues.current.categories);
        const categoryInfo  = {};
        const categoryLevelMax = currentFormValues.current.categoryLevel;
        categoryLists.filter(list => list.checked).forEach(list => {
            list.groupPath.forEach((val, i) => {
                //if(i < categoryLevelMax){
                    const levelKey = `level${i+1}`;
                    if(categoryInfo[levelKey] === undefined){
                        categoryInfo[levelKey] = [];
                    }
                    if(!categoryInfo[levelKey].includes(parseInt(val))){
                        categoryInfo[levelKey].push(parseInt(val));
                    }
                //}

            })
        });
        postData.categories = categoryInfo;
    }
    if(currentFormValues.current.aggregationTarget){
        const flatTargets = getFlatArray(currentFormValues.current.aggregationTarget);
        ////console.log({flatTargets});
        const checkPotentialUser  = ['tradeAreaResidents', 'passengers'];
        let matchPotentialCount = 0;
        postData.aggregationTarget = flatTargets.filter(d => d.checked).map(d => {
            const {
                value,
            } = d;
            if(checkPotentialUser.includes(value)){
                matchPotentialCount++;
            }
            return value;
        });
        const isPotentialUser = checkPotentialUser.length === matchPotentialCount;
        if(isPotentialUser){
            postData.aggregationTarget = postData.aggregationTarget.filter(value => !checkPotentialUser.includes(value));
            postData.aggregationTarget.push('potentialCustomer');
        }

    }
    if(currentFormValues.current.from_time !== undefined && currentFormValues.current.to_time !== undefined){
        postData.start_time = currentFormValues.current.from_time;
        postData.end_time = currentFormValues.current.to_time;
    }
    if(currentFormValues.current.categoryLevel){
        const categoryLevel = currentFormValues.current.categoryLevel;
        postData.view_category_code = `category_level${categoryLevel}`;
        if(postData.categories){
            postData.filter_category_code = `category_level${(categoryLevel)}`;
            postData.filter_category_code_list = postData.categories[`level${(categoryLevel)}`];
        }
    }
    if(hasRange){
        postData.range = currentFormValues?.current?.range || 10;
    }
    /*
    const area_store_id_and_area_store_company_id_list = [];
    if(currentFormValues?.current?.store){
        currentFormValues.current.store.map(d => {
            const {
                area_store_id,
                area_store_company_id,
            } = d.value;
            return [area_store_id, area_store_company_id];
        });
    }
    if(currentFormValues?.current?.competingStores){
        currentFormValues.current.competingStores.map(d => {
            const {
                area_store_id,
                area_store_company_id,
            } = d;
            return [area_store_id, area_store_company_id];
        });
    }
    postData.area_store_id_and_area_store_company_id_list = area_store_id_and_area_store_company_id_list;
    */

    postData.segment_ids = postData.segment;
    // 下記のパラメータはどこから生えてきたのか？？
    postData.metric      = ['sum'];//postData.target;
    postData.unit        = ['price'];

    //delete postData.target;
    delete postData.segment;
    delete postData.store;

    return postData;
}
const checkResponseError = (results) => {
    const errorObject = results.find(result => result.status === 'error') || false;
    return errorObject ? {
        tabOptions : null,
        originalDataFrame : null,
        error : errorObject,
    } : false;
}

const enumerateDatesBetweenDates = function (
    startDate,
    endDate,
    periodType = "daily"
  ) {
    try{
    let dates = new Set();
    startDate =
      periodType != "hourly" ? moment(startDate) : parseInt(startDate);
    endDate = periodType != "hourly" ? moment(endDate) : parseInt(endDate);
    if (periodType == "monthly") {
      let daysDiff = moment(endDate).diff(startDate, "days") || 0;
      for (let count = 0; count <= daysDiff; count++) {
        dates.add (   
          startDate.clone().startOf("month").format("YYYY-MM"));
          startDate.clone().add(1, "days");
        startDate.add(1, "days");
      }
    } else if (periodType == "monthly_split") {
      let daysDiff = moment(endDate).diff(startDate, "days") || 0;
      for (let count = 0; count <= daysDiff; count++) {
        dates.add(startDate.clone().startOf("month").format("YYYY-MM") + "<>weekday");
        dates.add(startDate.clone().startOf("month").format("YYYY-MM") + "<>weekend");
        startDate.clone().add(1, "days");
        startDate.add(1, "days");
      }
    } else if (periodType == "weekly") {
      let daysDiff = moment(endDate).diff(startDate, "days") || 0;  
      for (let count = 0; count <= daysDiff; count++) {
        dates.add(startDate.clone().startOf("isoweek").format("YYYY-MM-DD"));
          startDate.clone().add(1, "days");
        startDate.add(1, "days");
      }
    } else if (periodType == "weekly_split") {
      let daysDiff = moment(endDate).diff(startDate, "days") || 0;
      for (let count = 0; count <= daysDiff; count++) {
        dates.add(startDate.clone().startOf("isoweek").format("YYYY-MM-DD") + "<>weekday");
        dates.add(startDate.clone().startOf("isoweek").format("YYYY-MM-DD") + "<>weekend");
        startDate.clone().add(1, "days");
        startDate.add(1, "days");
      }
    } else if (periodType == "hourly") {
      for (let hour = startDate; hour <= endDate; hour++) {
        dates.add( `${hour}`);
      }
    } else {
      let daysDiff = moment(endDate).diff(startDate, "days") || 0;
      for (let count = 0; count <= daysDiff; count++) {
        dates.add(startDate.format("YYYY-MM-DD"));
        startDate.add(1, "days");
      }
    }
    dates = [...dates];
    return dates;
    }catch(e){
        console.error(e);
        return []
    }
  };

export {
    getSalesResults,
    getAssortmentSummary,
    getAssortmentCompetitor,
    getAssortmentSingleItemComparison,
    getAssortmentBasket,
    getPricingCompetitor,
    getPricingSummary,
    getPricingSingleItemComparison,
    getPricingBoxPlot,
    getSubmitValues,
    getMarketAreaSummary,
    getMarketAreaProfile,
    getMarketAreaPeopleFlow,
    getHeatMapJson,
    getMarketAreaShare,
    getMarketAreaHeatmap,
    isUseRangePath,
}